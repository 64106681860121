import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
// import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { ContextProvider } from "./context/context";
// import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
// import { LedgerConnector } from "wagmi/connectors/ledger";
import ReactLoading from "react-loading";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [publicProvider()]
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }),
    // new InjectedConnector({
    //   chains,
    //   options: {
    //     name: "Injected",
    //     shimDisconnect: true,
    //   },
    // }),
    // new LedgerConnector({
    //   chains: [mainnet],
    //   // projectId: "4f65c6123db34a024db1571fad3f8de0",
    //   enableDebugLogs: true,
    // }),
    // new WalletConnectConnector({
    //   chains,
    //   options: {
    //     projectId: "4f65c6123db34a024db1571fad3f8de0",
    //   },
    // }),
  ],
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <WagmiConfig config={config}>
        <Suspense
          fallback={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReactLoading
                color={"white"}
                width={30}
                height={30}
                type="spin"
              />{" "}
            </div>
          }
        >
          <App />
        </Suspense>
      </WagmiConfig>
    </ContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
