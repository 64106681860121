import { useState } from "react";
// import getHelpSvgIcon from "../../assets/getHelpSvg.svg";
// import { Button } from "../components/styledComponent/form/Buttons";
import Modal from "react-modal";
// import GetHelp from "../components/GetHelp";
import "./helpCentre.scss";
import closeIcon from "../../assets/close.svg";
import GetHelp from "./GetHelp";

const HelpCentre = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenOS, setModalIsOpenOS] = useState(false);
  const [mainLink, setMainLink] = useState(
    "https://d16wxdh43v8ajq.cloudfront.net/Skype_Video10.mp4"
  );

  const links = {
    apple: "https://d16wxdh43v8ajq.cloudfront.net/Skype_Video11.mp4",
    andriod: "https://videos.ultainfinity.com/android-devices.mp4",
    desktop: "https://d16wxdh43v8ajq.cloudfront.net/Skype_Video10.mp4",
    setting: "https://videos.ultainfinity.com/Onestep-biometrics.mp4",
  };

  const handleShowVideo = (type) => {
    setMainLink(links[type]);
    setModalIsOpen(true);
  };
  return (
    <div className="helpCentre">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="How to Register Biometrics (Apple devices)"
      >
        <div className="videoModalCon">
          <div
            onClick={() => setModalIsOpen(false)}
            className="videoModalCon__close"
          >
            <img src={closeIcon} alt="" />
          </div>
          <video style={{ width: "90vw", maxWidth: "800px" }} controls>
            <source src={mainLink} type="video/mp4" />
          </video>
        </div>
      </Modal>

      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpenOS}
        onRequestClose={() => setModalIsOpenOS(false)}
        contentLabel="reactivate payment"
      >
        <GetHelp closeModal={() => setModalIsOpenOS(false)} />
      </Modal>
      {/* <img className="helpCentre__img" src={getHelpSvgIcon} alt="" /> */}

      <div className="helpCentre__main">
        <h1 className="helpCentre__main__title">Help Centre</h1>

        <div className="helpCentre__main__list">
          <div className="helpCentre__main__list__item">Recovery Centre</div>
          <div
            className="helpCentre__main__list__item"
            onClick={() => setModalIsOpenOS(true)}
          >
            Get help with OneStep Verification
          </div>
          <div
            onClick={() => handleShowVideo("desktop")}
            className="helpCentre__main__list__item"
          >
            How to Register Biometrics (Desktop)
          </div>
          <div
            className="helpCentre__main__list__item"
            onClick={() => handleShowVideo("andriod")}
          >
            How to Register Biometrics (Andriod devices)
          </div>
          <div
            onClick={() => handleShowVideo("apple")}
            className="helpCentre__main__list__item"
          >
            How to Register Biometrics (Apple devices)
          </div>

          <div
            onClick={() => handleShowVideo("setting")}
            className="helpCentre__main__list__item"
          >
            Setup Biometrics in Settings page
          </div>
        </div>

        {/* <Button width="100%" $fill radius="10px">
          Continue
        </Button> */}
      </div>
    </div>
  );
};

export default HelpCentre;
