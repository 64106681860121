import styles from "./whoweare.module.scss";

const whoweare = () => {
  return (
    <div className={styles.whowearepage}>
      <div className={styles.row}>
        <div className={styles.leftText}>
          <h1>Who we are</h1>
          <p>
            ULIT exists to serve the purpose and meaning as described in the
            parlance of money-making, bringing alive an unlimited repeated
            stream of money-making tools for the billions of people in the
            world. In other words, it amplifies earnings, magnifies returns and
            makes it even more possible for each person to gain access to
            Ultainfinity’s wealth factory.
          </p>
        </div>
        <div className={styles.rightText}>
          <h1>What we do</h1>
          <p>
            ULIT products are phenomenal, their use cases are copious, and does
            WONDERS, including offering the ULIT Coin through Ultainfinity
            platforms such as the Ultainfinity Liquidity Pool, Ultainfinity
            Instant Creditline, ROLAR SuperSwap, Ultainfinity PreMarket Exchange
            and as well, the $750 Billion+ giveaway in Phase II of the
            Ultainfinity Airdrops & Bounties Program.
          </p>
        </div>
      </div>
    </div>
  );
};

export default whoweare;
