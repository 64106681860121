import styles from "./footer.module.scss";
import Logo from "../../assets/logo.png";
import whatsAppIcon from "../../assets/whatsapp.svg";
import telegramIcon from "../../assets/telegram.svg";
import twitterIcon from "../../assets/twitter.svg";
import instagramIcon from "../../assets/instagram.svg";
import tiktokIcon from "../../assets/tiktok.svg";
import facebookIcon from "../../assets/facebook.svg";
import lineIcon from "../../assets/line.svg";
import snapchatIcon from "../../assets/snapchat.svg";
import messangerIcon from "../../assets/messanger.svg";
import redditIcon from "../../assets/reddit.svg";
import signalIcon from "../../assets/signal.svg";
import pinterestIcon from "../../assets/pinterest.svg";
import discordIcon from "../../assets/discord.svg";
import quoraIcon from "../../assets/Quora.svg";
import tumblrIcon from "../../assets/tumblr.svg";
import viberIcon from "../../assets/viber.svg";
import linkedinIcon from "../../assets/linkedin.svg";
// import flickrIcon from "../../assets/flickr.svg";
import torumIcon from "../../assets/torum.svg";
import youtubeIcon from "../../assets/youtube.svg";
import mediumIcon from "../../assets/Medium.svg";
import { Link } from "react-router-dom";

const Footer = ({ quickBuy }) => {
  const LinksIcon = [
    {
      link: "https://www.facebook.com/profile.php?id=100093620387182&mibextid=LQQJ4d",
      icon: facebookIcon,
      iconName: "Facebook",
    },
    {
      link: "https://twitter.com/coinulitgroup?s=21&t=GT-Q7C1FWZf22fNwesrBUg",
      icon: twitterIcon,
      iconName: "Twitter",
    },
    {
      link: "https://instagram.com/ulit_coin_group?igshid=OGQ5ZDc2ODk2ZA==",
      icon: instagramIcon,
      iconName: "Instagram",
    },
    {
      link: "https://www.linkedin.com/company/ultainfinity-global-group/",
      icon: linkedinIcon,
      iconName: "Linkedin",
    },
    {
      link: "https://www.tiktok.com/@ulitcoin_?_op=1&_r=1&_t=8dH2Jpy5pDm",
      icon: tiktokIcon,
      iconName: "Tiktok",
    },
    {
      link: "https://www.youtube.com/@ULITCOINGROUP",
      icon: youtubeIcon,
      iconName: "Youtube",
    },
    {
      link: "https://www.facebook.com/synthetic.slapatoken",
      icon: messangerIcon,
      iconName: "Messanger",
    },
    {
      link: "https://wa.me/4407500483970",
      icon: whatsAppIcon,
      iconName: "WhatsApp",
    },
    {
      link: "https://t.me/ulitcoin_group",
      icon: telegramIcon,
      iconName: "Telegram",
    },
    {
      link: "https://www.reddit.com/r/ULIT_COIN_/?utm_source=share&utm_medium=ios_app&utm_name=ioscss&utm_content=1&utm_term=1",
      icon: redditIcon,
      iconName: "Reddit",
    },
    {
      link: "https://invite.viber.com/?g2=AQBudjpI1wFQLE8nhFQjaOy7oxAXYjOUu8PJwrdE5gRxfiTPB3IbSmAaWxloZTTj",
      icon: viberIcon,
      iconName: "Viber",
    },
    { link: "", icon: signalIcon, iconName: "Signal" },
    {
      link: "https://www.snapchat.com/add/ulit_coin",
      icon: snapchatIcon,
      iconName: "Snapchat",
    },
    {
      link: "https://developers.line.biz/console/",
      icon: lineIcon,
      iconName: "Line",
    },
    {
      link: "https://de.quora.com/profile/ULIT-COIN-GROUP?ch=17&oid=2231595667&share=fced3306&srid=hbbujy&target_type=user",
      icon: quoraIcon,
      iconName: "Quora",
    },
    {
      link: "https://ultainfinity.medium.com",
      icon: mediumIcon,
      iconName: "Medium",
    },
    {
      link: "https://www.tumblr.com/ulitcoingroup/720504265874997248/ulit-coin-the-brc20-token-on-bitcoin-blockchain",
      icon: tumblrIcon,
      iconName: "Tumblr",
    },
    {
      link: "https://www.torum.com/post/6496c7c19db092711e7640bd",
      icon: torumIcon,
      iconName: "Torum",
    },
    {
      link: "https://discord.gg/8XRz4HPRbQ",
      icon: discordIcon,
      iconName: "Discord",
    },
    {
      link: "https://pin.it/13UPXsn",
      icon: pinterestIcon,
      iconName: "Pinterest",
    },
  ];

  return (
    <>
      <div className={styles.parent}>
        <div className={styles.upperfooterParent}>
          <div className={styles.communityDiv}>
            <div className={styles.subscribe}>Follow ULIT</div>
            <div className={styles.desc}>
              Stay updated on ULIT news and events
            </div>
          </div>

          <div className={styles.socialParent}>
            {LinksIcon.map((link, i) => {
              return (
                <div
                  key={i}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {link.link ? (
                    <div className={styles.iconsDiv}>
                      <a href={link.link} target={"_blank"} rel={"noreferrer"}>
                        <img src={link.icon} alt="" />
                        <div>{link.iconName}</div>
                      </a>
                    </div>
                  ) : (
                    <div className={styles.iconsDiv}>
                      <img src={link.icon} alt="" />
                      <div>{link.iconName}</div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* <div className={styles.socialParent}>
          {LinksIconTwo.map((link, i) => {
            return (
              <div key={i}>
                {link.link ? (
                  <a href={link.link} target={"_blank"} rel={"noreferrer"}>
                    <div className={styles.iconsDiv}>
                      <div>{link.icon}</div>
                    </div>
                  </a>
                ) : (
                  <div className={styles.iconsDiv}>
                     <div>{link.icon}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div> */}
          <div className={styles.footer__quickBuy}>
            <p>
              Quickly purchase ULIT from Ultainfinity OTC now by just a click of
              a button. Enjoy the process as you amass wealth with Ultainfinity.{" "}
            </p>
            <Link to="/quick-buy">
              <button>Quick Buy</button>
            </Link>
          </div>
        </div>

        <div className={styles.LowerfooterParent}>
          <div className={styles.parentRow}>
            <div className={styles.rowOne}>
              <div className={styles.logoParent}>
                <div>
                  {" "}
                  <img
                    src={Logo}
                    style={{ width: 161 }}
                    className={styles.Icon}
                    alt="horse"
                  />
                </div>
              </div>
            </div>

            <div className={styles.rowTwo}>
              {/* <div className={styles.section}>
                <div className={styles.footerdesc}>General</div>
                <div className={styles.top}>
                  <div className={styles.contentTitle}><a className={styles.contentTitle} href="/#whoweare">About Us</a></div>
                  <div className={styles.contentTitle}><a className={styles.contentTitle} href="/#blog">Blog</a></div>
                  <div className={styles.contentTitle}>Careers</div>
                </div>
              </div> */}

              <div className={styles.section}>
                <div className={styles.footerdesc}>Learn More</div>
                <div className={styles.top}>
                  {/* <a
                    className={styles.contentTitle}
                    target={"_blank"}
                    rel={"noreferrer"}
                    href="https://medium.com/@ulit/ulit-coin-a-brc20-erc20-bep20-token-3c1f25edc36b"
                  >
                    ULIT and ULIT Coin
                  </a> */}
                  <div className={styles.contentTitle}>
                    <a
                      className={styles.contentTitle}
                      target={"_blank"}
                      rel={"noreferrer"}
                      href="https://ultainfinityairdropandbounty.com"
                    >
                      Ultainfinity Airdrops & Bounties
                    </a>
                  </div>
                  <div className={styles.contentTitle}>
                    <a
                      className={styles.contentTitle}
                      target={"_blank"}
                      rel={"noreferrer"}
                      href="https://ultainfinityglobalgroup.com"
                    >
                      Ultainfinity Global Group
                    </a>
                  </div>
                  <div className={styles.contentTitle}>
                    <a
                      className={styles.contentTitle}
                      target={"_blank"}
                      rel={"noreferrer"}
                      href="https://ultainfinitywealthlaunchpad.com"
                    >
                      Ultainfinity Wealth Launchpad
                    </a>
                  </div>
                  <div className={styles.contentTitle}>
                    <Link className={styles.contentTitle} to="/u-call-lending">
                      U-CALL Loan Assets
                    </Link>
                  </div>
                  <div className={styles.contentTitle}>
                    <Link className={styles.contentTitle} to="/whitepaper">
                      <button>ULIT Whitepapers & Roadmap</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className={styles.sectionTwo}>
                <div className={styles.footerdesc}>Resources</div>
                <div className={styles.top}>
                  {/* <a
                    target={"_blank"}
                    rel={"noreferrer"}
                    className={styles.contentTitle}
                    href="https://medium.com/@airdropsandbounties/the-relentless-price-rise-of-ulit-coin-a-brc-20-token-on-the-bitcoin-blockchain-network-the-fe8ac1a54530"
                  >
                    ULIT and ULIT Coin
                  </a> */}
                  <div className={styles.contentTitle}>
                    <a
                      className={styles.contentTitle}
                      target={"_blank"}
                      rel={"noreferrer"}
                      href="https://ultainfinitywealthlaunchpad.com"
                    >
                      Ultainfinity WEALTH Launchpad
                    </a>
                  </div>
                  <div className={styles.contentTitle}>
                    <a
                      className={styles.contentTitle}
                      target={"_blank"}
                      rel={"noreferrer"}
                      href="https://ultainfinityairdropandbounty.com"
                    >
                      Ultainfinity Airdrops & Bounties
                    </a>
                  </div>
                  <a
                    rel={"noreferrer"}
                    target={"_blank"}
                    href="https://medium.com/@airdropsandbounties/ulit-coin-buying-selling-withdrawing-and-profiting-from-ulit-coins-f4a3fe9c3850"
                    className={styles.contentTitle}
                  >
                    ULIT COIN - Buying, Selling, Withdrawing & Profiting from
                    ULIT COINS.
                  </a>
                  <a
                    target={"_blank"}
                    rel={"noreferrer"}
                    href="https://medium.com/@airdropsandbounties/how-to-buy-ulit-coin-brc20-token-on-the-bitcoin-blockchain-7ca7f30d63aa"
                    className={styles.contentTitle}
                  >
                    How to Buy ULIT Coin, BRC20 Token on the Bitcoin Blockchain
                  </a>
                  {/* <a
                    target={"_blank"}
                    rel={"noreferrer"}
                    href="https://medium.com/@ulit"
                    className={styles.contentTitle}
                  >
                    ULIT/VMPX/XEN Announcement
                  </a> */}
                </div>
              </div>

              <div className={styles.sectionThree}>
                <div className={styles.footerdesc}>About</div>
                <div className={styles.top}>
                  <a className={styles.contentTitle} href="/#whoweare">
                    Who We Are
                  </a>
                  <a className={styles.contentTitle} href="/#whoweare">
                    What We Do
                  </a>
                  {/* <div onClick={gotoUtta}>Who we are</div>
                  <div className={styles.contentTitle} onClick={gotoDivit}>
                    What we do
                  </div> */}
                  <a className={styles.contentTitle} href="/#learnmore">
                    U-Call Lending
                  </a>
                  <Link to="/careers" className={styles.contentTitle}>
                    Careers
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
