import styles from "./learnmore.module.scss";

const Learnmore = () => {
  return (
    <div className={styles.learnmorepage}>
      <div className={styles.parentlearnmore}>
        <h2>U-CALL Lending By ULIT And One-Click Top-Up Loans</h2>
        <h3>U-CALL LENDING</h3>
        <p>
          U-CALL Lending by ULIT is an industry-defining game-changer. ULIT,
          ULIT COIN, ULITCOIN.COM and U-CALL Lending derive deep support from
          the global super ecosystem of ULTAINFINITY
        </p>
        <p>
          ULTAINFINITY GLOBAL GROUP has gone all-in with ULIT COIN as Collateral
          for limitless lending at 75% LTV for loans of $500 to $1M. The Group
          and partners shall supply global liquidity for ULIT COIN and other
          leading BRC20 tokens such as ORDI and some Memecoins such as DOGE,
          SHIBA INU, PEPE etc.
        </p>
        <p>
          U-CALL Lending is a simple application process comprising very few
          clicks, no credit checks, no daily repayments, no margin call, no
          expiry of the loan period, no chasing of a borrower for the repayment
          of a loan, and no personal bankruptcy and up to $1 million per loan.
        </p>
        <p>
          U-CALL Lending has no limits to the number of loan applications, and
          each loan applicant can utilise up to 52+ cryptocurrencies as the
          Collateral to back their loan. There is no limit to the total number
          of loans or the total amount borrowed by each borrower. U-CALL Lending
          Program is unlike any before it or any that's obtainable anywhere else
          in the World.{" "}
        </p>
        <p>
          U-CALL Lending is ample in liquidity, easy to arrange and
          straightforward in use cases, and can be used to invest in more ULIT
          COINS and to buy and trade 20 leading cryptocurrencies, be used as
          cash and be used to buy Ultainfinity tokens. ULIT has created an
          effective money-making platform for the rampant, concurrent,
          profitable and beneficial use of all Crypto-Hodlrs, all wealth-seeking
          investors and traders.
        </p>

        <h2>ONE-CLICK TOP-UP U-CALL LOANS.</h2>
        <p>
          Borrowers that used ULIT COIN as Collateral for their loans can obtain
          top-up loans for any amount up to $1 Million, and at a click from the
          comfort of their ULIT Dashboard and can do so without starting any new
          loan application or be faced with any constraints. The process is
          automatic, easy and instantaneous. The top-up loans can be used simply
          for cash, for further buying of ULIT COINS, for buying of Ultainfinity
          Tokens, and trading of top 20 leading cryptocurrencies such as BTC,
          ETH, BNB, ADA, DOGE, SHIBA INU, etc.{" "}
        </p>
        <p>
          The One-Click Top-Up Loan process is unencumbered and instantaneous
          because the price and value of ULIT COIN used as the Collateral for
          the original loan is directly related to the displayed available
          top-up loan amount that you, as a current borrower, can easily click
          to claim instantly.{" "}
        </p>
        <p>
          The increases in price, value and market cap of ULIT COIN would be
          relentless.
        </p>
      </div>
    </div>
  );
};

export default Learnmore;
