import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../suresystem/css/suresystem.module.scss';
import  StepFiveIcon from "../../../assets/surestepfiveicon.svg";

const SureStepFiveApproved = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])
    let navigate=useNavigate()
    const gotoSureStepFiveApproved=()=>{
      navigate('/dashboard/get-loan/loans/step-five-approved')
    }
  

    return ( 
        <div className={styles.main}>
         <div className={styles.sure}>SURE SYSTEM</div>
        <div className={styles.parent}>
            <div className={styles.box}>
               <img src={StepFiveIcon} className={styles.stepIcon} alt="horse" />
               <div className={styles.sureTitle}>Awaiting Approval & Code Release by the Super Admin</div>   
               <div className={styles.Surelist}>Your request has been sent and is currently under review and checks. Once verified, your authentication code will be released.</div>
               <div className={styles.Surelist}>It may take up to 3 days to get your code. This is due to request checks and processing by the Super Admin. Kindly wait patiently.</div>
              
               <div className={styles.receiptBtnDiv}>
                 <button className={styles.btn} onClick={gotoSureStepFiveApproved}>Next</button>
               </div>
            </div>
        </div>
        </div>
     );
}
 
export default SureStepFiveApproved;