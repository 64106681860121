import ulit from "../../assets/ulit.png";
import wallet1 from "../../assets/wallet1.png";
import QuickBuyD from "../../component/quickBuyD/QuickBuyD";
import "./createOrder.scss";

const CreateOrder = () => {
  const data = {
    network: "BNB Smart Chain Mainnet",
    address: "0XC610fdksfksldfjos984hrejFa6d",
  };

  return (
    <div className="createOrder">
      <div className="dashboard__header">
        <h2>Create New Order</h2>
        <div className="dashboard__header__main">
          <p>
            {" "}
            <img
              style={{ width: 30, marginRight: 10 }}
              src={wallet1}
              alt=""
            />{" "}
            Metamask
          </p>
          <p>{data.network}</p>
          <p>
            <img src={ulit} alt="" /> {data.address.substring(0, 10)}....
          </p>
        </div>
      </div>

      <QuickBuyD dashboard={true} />
    </div>
  );
};

export default CreateOrder;
