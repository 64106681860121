export default function authHeader() {
    // const user = JSON.parse(localStorage.getItem("userUlta") || "{}");
    const user = true;
    if (user) {
      return {
        "api-token": "MFVaTXAwM1lYWmNxbDhwYUJiMWJvODBibURBblRGTUh4Mkx3R2VXSw=="
      };
    } else {
    }
  }
  