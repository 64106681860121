import { useState ,useEffect} from "react";
import "./invoice.scss";
import { ButtonMain } from "../StyledComponent";
import Modal from "react-modal";
import closeIcon from "../../assets/close.svg";

export default function SettlementStatement({ setStep }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleCongrats = () => {
    setStep(3);
    // setModalIsOpen(false);
  };
    useEffect(() => {
      window.scroll(0, 0);
    }, [modalIsOpen]);
  return (
    <>
      {modalIsOpen && (
        <Modal
          className="modalMain"
          overlayClassName="modal-overlay-alt"
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel=""
        >
          <div className="getLoan__modal">
            <div
              onClick={() => setModalIsOpen(false)}
              className="getLoan__modal__close"
            >
              <img src={closeIcon} alt="" />
            </div>

            <h3 className="congrats">Congrats!</h3>
            <h4 className="success-msg">Your Loan Repayment was successful</h4>
            <p>
              We’re thrilled to have played a role in your success and we’re
              honored to have been part of your financial journey
            </p>
            <div className="cancel-flex">
              <button onClick={handleCongrats}>View Statement</button>
            </div>
          </div>
        </Modal>
      )}
      <div className="loan__invoice">
        <h2>Payment Invoice</h2>
        <div className="loan__invoice__wrap">
          <h3>Loan Repayment & Settlement Statement</h3>
          <aside className="loan__invoice__wrap__content">
            <h3>Loan details</h3>
            <span>
              <p>Currency</p>
              <h3>ULIT COIN</h3>
            </span>
            <hr />
            <span>
              <p>Repayment amount</p>
              <h3>$1,376,250.00 (10,000,000.00 ULIT)</h3>
            </span>
            <hr />
            <span>
              <p>Time</p>
              <h3>20:34</h3>
            </span>
            <hr />
            <span>
              <p>Paid Invoice confirmation for</p>
              <h3>Joe Brown</h3>
            </span>
            <hr />
            <span>
              <p>Invoice number</p>
              <h3>IN23405</h3>
            </span>
          </aside>
          <aside className="loan__invoice__wrap__content">
            <h3>Loan details</h3>
            <span>
              <p>Currency</p>
              <h3>ULIT COIN</h3>
            </span>
            <hr />
            <span>
              <p>Loan amount</p>
              <h3>$1,376,250.00 (10,000,000.00 ULIT)</h3>
            </span>
            <hr />
            <span>
              <p>DCI (for 75 days)</p>
              <h3>$606,815.62 (6,068,156.20 ULIT)</h3>
            </span>
            <hr />
            <span>
              <p>Gross Asset Value</p>
              <h3>$1,500,000.00 (15,000,000.00 ULIT) </h3>
            </span>
            <hr />
            <span>
              <p>95% CEMA</p>
              <h3>$75,000.00 (750,000.00 ULIT COIN)</h3>
            </span>
            <span>
              <p>MALI</p>
              <h3>$75,000.00 (750,000.00 ULIT COIN)</h3>
            </span>
            <span>
              <p>Total Settlement</p>
              <h3>$1,250,000.00 (12,500,000.00)</h3>
            </span>
          </aside>
          <section className="loan__invoice__id">
            <span>
              <p>Date</p>
              <h3>23-03-2023</h3>
            </span>
            <hr />
            <span>
              <p> Account Number</p>
              <h3>239084809</h3>
            </span>
            <hr />
            <span>
              <p>Reference Number</p>
              <h3>239084809</h3>
            </span>
            <hr />
            <span>
              <p>Transaction ID</p>
              <h3>4455UIC</h3>
            </span>
          </section>
          <ButtonMain
            onClick={() => setModalIsOpen(true)}
            $fill
            radius="6px"
            width="50%"
            height="60px"
            style={{ maxWidth: 800 }}
          >
            Proceed
          </ButtonMain>
        </div>
      </div>
    </>
  );
}
