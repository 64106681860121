import { useState } from "react";
import "./getLoan.scss";
import { ErrorMessage } from "../../component/career/Career";
import { Link } from "react-router-dom";
import backIcon from "../../assets/back.svg";
import Modal from "react-modal";
import closeIcon from "../../assets/close.svg";

export const Input = ({
  inputError,
  name,
  holder,
  value,
  onChange,
  type,
  label,
}) => {
  return (
    <div className="getLoan__input">
      <label htmlFor={name}>{label}</label>
      <input
        type={type ? type : "text"}
        id={name}
        onChange={onChange}
        value={value}
        placeholder={holder}
      />
      <br />
      {inputError && <ErrorMessage inputError={inputError} />}
    </div>
  );
};

export const Select = ({
  inputError,
  name,
  holder,
  value,
  onChange,
  children,
  label,
}) => {
  return (
    <div className="getLoan__select">
      <label htmlFor={name}>{label}</label>

      <select value={value} id={name} onChange={onChange}>
        {/* <option value="">{holder}</option> */}
        {children}
      </select>
      <br />
      {inputError && <ErrorMessage inputError={inputError} />}
    </div>
  );
};
const GetLoan = () => {
  const [step, setStep] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [ulitAmount] = useState(2000.984723);
  const [neededUlit] = useState(1300);
  const [agree, setAgree] = useState(false);

  const data = [
    {
      title: "Buy More ULIT Coins ",
      disc: "Buy more ULIT COINS. Partake in the virtuous wealth creation from owning more ULIT COINS and using your additional ULIT COINS to get more loan. ",
    },
    {
      title: "Buy More Ultainfinity Cryptocurrencies ",
      disc: "Buy ULTAINFINITY TOKENS. Partake in the virtuous wealth creation from owning more ULTAINFINITY TOKENS and using your additional ULTAINFINITY TOKENS to get more loan.",
    },
    {
      title: "Use as Cash Now",
      disc: "Privately use your U-CALL Loaned Money as your cash",
    },
    {
      title: "Buy & Sell Top 20 Leading Cryptocurrencies ",
      disc: "Buy & Sell TOP 20 CRYPTOCURRENCIES. Partake in the virtuous wealth creation from owning more TOP 20 CRYPTOCURRENCIES and use your additional TOP 20 CRYPTOCURRENCIES to get more loan.",
    },
  ];

  return (
    <div className="getLoan">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel=""
      >
        <div className="getLoan__modal">
          <div
            onClick={() => setModalIsOpen(false)}
            className="getLoan__modal__close"
          >
            <img src={closeIcon} alt="" />
          </div>
          <svg
            width="97"
            height="97"
            viewBox="0 0 97 97"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="48.5" cy="48.5" r="48.5" fill="#58BD7D" />
            <path
              d="M42.4402 57.4894L33.4515 48.5007L30.3906 51.54L42.4402 63.5896L68.3068 37.7229L65.2675 34.6836L42.4402 57.4894Z"
              fill="#F0F1FF"
            />
          </svg>

          <h3>Application Successful</h3>
          <p>
            Your application is currently under review and checks. Once
            approved, you will get a message in this dashboard or on your
            OneStep messenger .
          </p>
          <p>
            This may take few hours and up to 3 days. Kindly wait patiently.{" "}
          </p>

          <button>Bact to Dashboard</button>
        </div>
      </Modal>
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen2}
        onRequestClose={() => setModalIsOpen2(false)}
        contentLabel=""
      >
        <div className="getLoan__modalTerms">
          <div
            onClick={() => setModalIsOpen2(false)}
            className="getLoan__modalTerms__close"
          >
            <img src={closeIcon} alt="" />
          </div>

          <h3>Important terms of U-CALL</h3>

          <div className="getLoan__modalTerms__main">
            <ol>
              <li>
                <p>IMPORTANT NOTE</p>

                <ol>
                  <li>
                    Prices of any digital assets including cryptocurrencies are
                    subject to higher volatility and unpredictability{" "}
                  </li>
                  <li>
                    U-CALL will automatically liquidate your collateral assets
                    if the prices of the assets fall to a level close to or
                    below the Agreed Loan To Value, following our own review of
                    your portfolio{" "}
                  </li>
                  <li>
                    You agree to all the terms and conditions of U-CALL and it’s
                    partners including revisions and additions, with or without
                    notice, in relation to collateralised lending, loans, and
                    other relevant U-CALL options that apply to your lending
                    facility. lending facility.
                  </li>
                  <li>
                    The repayment of all loans shall be with TXLA Token. It is
                    important that you are aware and therefore manage your
                    wealth portfolio to include TXLA Token so it may be used to
                    profitably settle your loan. TXLA Token value may go up or
                    down in between when you borrowed and when you repay or
                    settle.
                  </li>
                  <li>
                    I agree that ULIT, its agents, partners and representatives
                    can manage, trade, assign, deploy, transfer, inscribe, buy,
                    sell, credit, debit, withdraw, deposit, invest and represent
                    my interests in the use, disbursement, trading, litigation,
                    claims, set-offs, settlements, retrieving and any necessary
                    act or matters related to the use, transactions and
                    management of the borrowed sums
                  </li>
                  <li>
                    ULIT reserves the right to award or provide or deposit or
                    substitute a different version of FULC token to that which
                    was minted or requested or paid for by a user or investor or
                    borrower or purchaser. As an example, an Ethereum or Bitcoin
                    or Litecoin or any other blockchain network version of FULC
                    token may be awarded or provided or shall substitute a
                    requested or minted or purchased Dogecoin blockchain network
                    version of FULC Token. This same term and condition is
                    applicable to all versions of ULIT cryptocurrencies and
                    crypto assets.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </Modal>
      {step === 1 && (
        <>
          <div className="getLoan__header">
            <h2>USE YOUR ULIT COINS OR DULC COINS AS COLLATERAL TO BORROW</h2>
            <div className="getLoan__header__btn">
              <Link to="/dashboard/get-loan">
                <button>Overview</button>
              </Link>
              <Link to="/dashboard/get-loan/loans">
                <button className="alt">Loans</button>
              </Link>
            </div>
          </div>

          <div className="getLoan__disc">
            <h3>ULIT Collaterised Asset Leveraged Lending</h3>
            <p>Sponsored by Ultainfinity in partnership with DASA Lending</p>
          </div>

          <h3 className="getLoan__title">Use-Cases for Borrowed Loan</h3>
          <div className="getLoan__list">
            {data.map((item, i) => {
              return (
                <div
                  key={i}
                  className={
                    i < 2 ? "getLoan__list__item show" : "getLoan__list__item"
                  }
                >
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              );
            })}
          </div>

          <div className="getLoan__btn">
            <button onClick={() => setStep(2)}>GET STARTED</button>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div className="getLoan__form__header">
            <h3 style={{ margin: 0 }} className="getLoan__title">
              Use-Cases for Borrowed Loan
            </h3>
            <img onClick={() => setStep(1)} src={backIcon} alt="" />
          </div>

          <div className="getLoan__form">
            <div className="getLoan__form__header">
              <h4>Apply for Loan</h4>
              <button onClick={() => setStep(4)}>
                U-CALL Lending Calculator
              </button>
            </div>

            <Select
              name="Cryptocurrency"
              label="Select Cryptocurrency"
              //   value={crypto}
              holder="Select Cryptocurrency"
              //   onChange={(e) => setCrypto(e.target.value)}
            >
              <option value="ULC">ULC</option>
              <option value="DULC">DULC</option>
            </Select>

            <Input
              label="How much do want to borrow?"
              //   value={amount}
              //   onChange={(e) => setAmount(e.target.value)}
              name="amount"
              holder="Amount"
              type="number"
            />

            <Select name="ALTV" label="Select ALTV">
              <option value="25%">25%</option>
              <option value="50%">50%</option>
              <option value="75%">75%</option>
            </Select>

            {/* <Input
              label="Collateral Amount"
              value={1300}
              //   onChange={(e) => setAmount(e.target.value)}
              name="collAmount"
              holder="Amount"
              type="number"
            /> */}

            <label className="getLoan__form__label" htmlFor="Collateral Amount">
              Collateral Amount
            </label>
            <div className="getLoan__inputText">
              {neededUlit} <span>ULIT</span>{" "}
            </div>

            <div className="getLoan__form__avail">
              Available:{" "}
              <span className={ulitAmount > neededUlit ? "suc" : ""}>
                {ulitAmount} ULC
              </span>{" "}
            </div>

            {ulitAmount < neededUlit && (
              <div className="getLoan__form__pur">
                Purchase ${neededUlit - ulitAmount} ULIT Coins to bring your
                account balance to the required ALTV level for an immediate
                approval of your application.{" "}
                <Link to="/dashboard/create">Click to Purchase</Link>{" "}
              </div>
            )}

            <div className="getLoan__form__terms">
              <input type="checkbox" onChange={() => setAgree(!agree)} />
              <p>
                I have read the{" "}
                <span onClick={() => setModalIsOpen2(true)}>
                  Terms & Condition
                </span>{" "}
                and <span>Rate Acceptance</span>. I will like to proceed.
              </p>
            </div>
          </div>

          <div className="getLoan__btn">
            <button
              disabled={ulitAmount < neededUlit || !agree ? true : false}
              onClick={() => setStep(3)}
            >
              Next
            </button>
          </div>
        </>
      )}

      {step === 3 && (
        <>
          <h3 className="getLoan__title">Use-Cases for Borrowed Loan</h3>

          <div className="getLoan__form">
            <div className="getLoan__form__header">
              <h4>Apply for Loan</h4>
              <img onClick={() => setStep(step - 1)} src={backIcon} alt="" />
            </div>

            <Select
              name="days"
              label="How many days do you want to borrow for?"
            >
              <option value="30 days">30 days</option>
              <option value="Above 30 days">Above 30 days</option>
            </Select>
            <Select
              name="borrow"
              label="What do you want to do with the borrowed sum?"
            >
              <option value="Buy more ULIT cryptocurrencies">
                Buy more ULIT cryptocurrencies
              </option>
              <option value="Buy more ULTAINFINITY cryptocurrencies">
                Buy more ULTAINFINITY cryptocurrencies
              </option>
              <option value="Buy Top 20 Leading cryptocurrencies">
                Buy Top 20 Leading cryptocurrencies
              </option>
              <option value="Use it personally ">Use it personally</option>
            </Select>

            <div className="getLoan__form__disc">
              The ULIT Super Admin will check your application for approval and
              you’ll get a message in this dashboard or on your OneStep
              messenger
            </div>
          </div>

          <div className="getLoan__btn">
            <button onClick={() => setModalIsOpen(true)}>Next</button>
          </div>
        </>
      )}

      {step === 4 && (
        <div className="getLoan__calc">
          <div className="getLoan__calc__header">
            <h3>U-CALL Lending Calculator</h3>
            <img onClick={() => setStep(2)} src={backIcon} alt="" />
          </div>

          <div className="getLoan__calc__main">
            <div className="getLoan__calc__main__inputCon">
              <Select name="Cryptocurrency" label="Cryptocurrency">
                <option value="ULIT">ULIT</option>
              </Select>
              <Input
                label="Loan Amount"
                //   value={amount}
                //   onChange={(e) => setAmount(e.target.value)}
                name="Amount"
                holder="Loan Amount"
                type="number"
              />
            </div>

            <div className="getLoan__calc__main__inputCon">
              <div>
                <label
                  className="getLoan__form__label"
                  htmlFor="Collateral Amount"
                >
                  Portfolio Asset Valuation
                </label>
                <div className="getLoan__calc__main__inputText">1,000</div>
              </div>
              <div>
                <label
                  className="getLoan__form__label"
                  htmlFor="Collateral Amount"
                >
                  ULIT Asset Valuation
                </label>
                <div className="getLoan__calc__main__inputText">1,000</div>
              </div>
            </div>

            <div className="getLoan__calc__main__inputCon">
              <Select name="ALTV" label="Select ALTV">
                <option value="25%">25%</option>
                <option value="50%">50%</option>
                <option value="75%">75%</option>
              </Select>

              <div>
                <label
                  className="getLoan__form__label"
                  htmlFor="Collateral Amount"
                >
                  IPAL (Immediately Pre-Approved Loan)
                </label>
                <div className="getLoan__calc__main__inputText">525</div>
              </div>
            </div>

            <div className="getLoan__calc__main__vs">
              <div className="getLoan__calc__main__vs__inputCon">
                <div>
                  <label
                    className="getLoan__form__label"
                    htmlFor="Collateral Amount"
                  >
                    Current Portfolio Assets Value (Collateral)
                  </label>
                  <div className="getLoan__calc__main__inputText">1300</div>
                </div>

                <span>VS</span>

                <div>
                  <label
                    className="getLoan__form__label"
                    htmlFor="Collateral Amount"
                  >
                    Available Amount To Borrow
                  </label>
                  <div className="getLoan__calc__main__inputText">525</div>
                </div>
              </div>
              <div className="getLoan__calc__main__vs__inputCon">
                <div>
                  <label
                    className="getLoan__form__label"
                    htmlFor="Collateral Amount"
                  >
                    Required Portfolio Assets As Collateral
                  </label>
                  <div className="getLoan__calc__main__inputText">1,500</div>
                </div>

                <span>VS</span>

                <div>
                  <label
                    className="getLoan__form__label"
                    htmlFor="Collateral Amount"
                  >
                    Amount You Can Borrow
                  </label>
                  <div className="getLoan__calc__main__inputText">1000</div>
                </div>
              </div>
            </div>
          </div>

          <div className="getLoan__calc__next">
            <h4>Next Step</h4>
            <div className="getLoan__calc__next__main">
              <div className="getLoan__calc__next__main__item">
                Start New Loan Application <button>Start Now</button>
              </div>
              <div className="getLoan__calc__next__main__item">
                Continue Current Loan <button>Continue Now</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetLoan;
