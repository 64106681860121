import { BsArrowUp } from "react-icons/bs";
import { motion } from "framer-motion";
import styles from "./home.module.scss";
import space from "../../assets/space.png";
import QBImg from "../../assets/QB.png";
import shape from "../../assets/shape.svg";
import Whoweare from "../whoweare/whoweare";
import Learnmore from "../learnmore/learnmore";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <h1 className={styles.h1Title}>
        ULIT COIN, THE BRC20 TOKEN ON BITCOIN BLOCKCHAIN HAS ITS HOLDERS
        ENTHRALLED BY THE TRIPLE-BENEFICIAL-EFFECTS OF U-CALL - A LENDING
        PROGRAM FOR BRC20 TOKENS
      </h1>
      <div className={styles.main}>
        <div className={styles.parent}>
          <div className={styles.content}>
            <motion.div
              className={styles.titleParent}
              initial={{ x: -250 }}
              animate={{ x: 0 }}
              transition={{
                delay: 0.2,
                type: "spring",
                stiffness: 10,
                duration: 4,
              }}
            >
              <div className={styles.title}>
                A New Age of BRC-20 The Innovation of Multi Blockchain The
                Future of Digital Currency Exalted And Championed By
                Ultainfinity
              </div>
            </motion.div>
          </div>
        </div>
        <div className={styles.titleParent2}>
          <div className={styles.row} id="overview">
            <div className={styles.textBtnDiv}>
              <div className={styles.rowText}>
                <div className={styles.btnDiv}>
                  <div className={styles.btnoverview}>Overview</div>
                </div>
                ULIT has come to make BRC20 tokens the envy of BRC20 tokens,
                steering the Wealth creation transformation that we had all
                hoped crypto would bring since 2008. ULIT does so by giving
                holders of the ULIT Coin and users of the multifarious use
                cases, unparalleled access to Ultainfinity Global Ecosystem, the
                vineyard of Wealth Creation, Wealth Distribution and Wealth
                Management, also described as the Trinity of Wealth (ToW).
              </div>
            </div>
            <img
              src={space}
              className={styles.spaceIcon}
              loading="lazy"
              alt="horse"
            />
          </div>

          <div style={{ marginBottom: 50 }}>
            <video style={{ width: "100%" }} autoPlay loop muted playsInline>
              <source
                src="https://videos.ultainfinity.com/Ulit1.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className={styles.quickBuy}>
            <img src={QBImg} alt="" />

            <div className={styles.quickBuy__right}>
              <p>
                Quickly purchase ULIT from Ultainfinity OTC now by just a click
                of a button. Enjoy the process as you amass wealth with
                Ultainfinity.{" "}
              </p>
              <Link to="/quick-buy">
                <button>Quick Buy</button>
              </Link>
            </div>
          </div>
          <div id="whoweare">
            <Whoweare />
          </div>

          <div id="learnmore">
            <Learnmore />
          </div>

          <div className={styles.cominsoon}>
            <h2>
              Check us out regularly, as this website is still being developed
              for your absolute Wealth pleasure
            </h2>
          </div>

          <a href="#top" className={styles.topBTn}>
            <BsArrowUp className={styles.arrowup} />
          </a>
        </div>
        <img src={shape} alt="sideimage" className={styles.sideImage} />
      </div>

      <Footer quickBuy={true} />
    </div>
  );
};

export default Home;
