import ReactPaginate from "react-paginate";
import ulit from "../../assets/ulit.png";
import wallet1 from "../../assets/wallet1.png";
import "./pendingOrder.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

const PendingOrder = () => {
  const [showConf, setShowConf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pendingOrder, setPendingOrder] = useState(null);

  const user = JSON.parse(sessionStorage.getItem("userUlta") || "{}");

  const data = {
    network: "BNB Smart Chain Mainnet",
    address: "0XC610fdksfksldfjos984hrejFa6d",
  };

  useEffect(() => {
    const initGetTransc = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            `/launchpad/app/dashboard/get-user-dashboard`,
          {
            headers: {
              Authorization: "Bearer " + user.token,
              "user-id": user?.user?.id,
            },
          }
        );
        setLoading(false);

        setPendingOrder(response.data.data?.Purchasehistory);
      } catch (err) {
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        setLoading(false);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    initGetTransc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="pendingOrder">
      {!showConf ? (
        <>
          <div className="dashboard__header">
            <h2>Pending Orders</h2>
            <div className="dashboard__header__main">
              <p>
                {" "}
                <img
                  style={{ width: 30, marginRight: 10 }}
                  src={wallet1}
                  alt=""
                />{" "}
                Metamask
              </p>
              <p>{data.network}</p>
              <p>
                <img src={ulit} alt="" /> {data.address.substring(0, 10)}....
              </p>
            </div>
          </div>

          <div className="pendingOrder__table">
            {loading ? (
              <div className="loadingCon">
                <ReactLoading
                  color="white"
                  width={50}
                  height={50}
                  type="spin"
                />
              </div>
            ) : pendingOrder?.length > 0 ? (
              <div className="pendingOrder__table__body">
                <div className="pendingOrder__table__row">
                  <div
                    style={{ width: "8%" }}
                    className="pendingOrder__table__row__head"
                  ></div>
                  <div
                    style={{ width: "12%" }}
                    className="pendingOrder__table__row__head"
                  >
                    Token
                  </div>
                  <div
                    style={{ width: "12%" }}
                    className="pendingOrder__table__row__head"
                  >
                    Date
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="pendingOrder__table__row__head"
                  >
                    Transaction ID
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="pendingOrder__table__row__head"
                  >
                    Payment method
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="pendingOrder__table__row__head"
                  >
                    Amount
                  </div>
                  <div
                    style={{ width: "13%" }}
                    className="pendingOrder__table__row__head"
                  >
                    Status
                  </div>
                </div>

                {pendingOrder?.map((item, i) => {
                  return (
                    <div key={i} className="pendingOrder__table__row">
                      <div
                        style={{ width: "8%" }}
                        className="pendingOrder__table__row__data"
                      >
                        {/* {itemOffset === 0 ? i + 1 : itemOffset + i + 1} */}
                        {i + 1}
                      </div>
                      <div
                        style={{ width: "12%" }}
                        className="pendingOrder__table__row__data"
                      >
                        {item?.token}
                      </div>
                      <div
                        style={{ width: "12%" }}
                        className="pendingOrder__table__row__data"
                      >
                        {item?.date}
                        {/* {item?.date?.substring(0, 10)} */}
                      </div>
                      <div
                        style={{ width: "15%" }}
                        className="pendingOrder__table__row__data"
                      >
                        {item?.id}
                      </div>
                      <div
                        style={{ width: "20%" }}
                        className="pendingOrder__table__row__data"
                      >
                        {item?.method}
                      </div>
                      <div
                        style={{ width: "20%" }}
                        className="pendingOrder__table__row__data"
                      >
                        {item?.amount}
                      </div>
                      <div
                        style={{ width: "13%" }}
                        className="pendingOrder__table__row__data"
                      >
                        <span onClick={() => setShowConf(true)}>
                          {item.status}
                        </span>
                      </div>
                    </div>
                  );
                })}

                {/* {pageCount > 1 && ( */}
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={5}
                  breakLabel="..."
                  //   renderOnZeroPageCount={null}
                  //   onPageChange={handlePageClick}
                  //   pageRangeDisplayed={itemsPerPage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
                {/* )} */}
              </div>
            ) : (
              <div className="noData">No Data</div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="pendingOrder__back">
            <button onClick={() => setShowConf(false)}>Back</button>
          </div>

          <div className="pendingOrder__main">
            <div className="pendingOrder__main__await">
              <h2>Awaiting Delivery Confirmation</h2>
              <p>
                Your deposit has been sent and is currently under review and
                checks. Once verified, you will see your delivery confirmation
                here, once your purchased token has been released to you after
                the receipt of your deposit has been confirmed by the Admin.
                This may take few hours and up to 3 days. Kindly wait patiently.
                Once released, you will see the released purchased token amount
                in your wallet.{" "}
              </p>
              <p>
                You can revisit this page for an update on your transaction by
                simply clicking the QUICK BUY Button in ULITCOIN COM Homepage
              </p>
              <p>
                The delivery of your purchase would be made and the confirmation
                will appear in this page
              </p>
              <p>
                In the event that you wish to contact ULIT, please email us at{" "}
                <br /> myorder@ulitcoin.com
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PendingOrder;
