import { useState } from "react";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Area,
} from "recharts";
import ReactLoading from "react-loading";
import "./chart.scss";

const Chart = ({ curr, title }) => {
  const [loading] = useState(false);
  // const [period] = useState("monthly");
  const [data] = useState([
    { month: "Dec", count: 30 },
    { month: "Nov", count: 40 },
    { month: "Oct", count: 35 },
    { month: "Sep", count: 55 },
    { month: "Aug", count: 10 },
    { month: "Jul", count: 60 },
    { month: "Jun", count: 70 },
    { month: "May", count: 40 },
    { month: "Apr", count: 50 },
    { month: "Mar", count: 30 },
    { month: "Feb", count: 100 },
    { month: "Jan", count: 120 },
  ]);

  // const { chartBalance } = useAppSelector((state) => state.bounties);

  // const numberToMonth = (num) => {
  //   switch (num) {
  //     case 0:
  //       return "Jan";
  //     case 1:
  //       return "Feb";
  //     case 2:
  //       return "Mar";
  //     case 3:
  //       return "Apr";
  //     case 4:
  //       return "May";
  //     case 5:
  //       return "Jun";
  //     case 6:
  //       return "Jul";
  //     case 7:
  //       return "Aug";
  //     case 8:
  //       return "Sep";
  //     case 9:
  //       return "Oct";
  //     case 10:
  //       return "Nov";
  //     case 11:
  //       return "Dec";

  //     default:
  //       return "Jan";
  //   }
  // };
  // const numberToDay = (num) => {
  //   switch (num) {
  //     case 1:
  //       return num + "st";
  //     case 2:
  //       return num + "nd";
  //     case 3:
  //       return num + "rd";

  //     default:
  //       return num + "th";
  //   }
  // };

  // useEffect(() => {
  //   if (chartBalance) {
  //     if (period === "daily") {
  //       const newData = chartBalance?.map((item: number, i: number) => {
  //         return { month: numberToDay(i + 1), count: item };
  //       });
  //       setData([...newData].reverse());
  //     }
  //     if (period === "monthly") {
  //       const newData = chartBalance?.map((item: number, i: number) => {
  //         return { month: numberToMonth(i), count: item };
  //       });

  //       setData([...newData].reverse());
  //     }
  //     if (period === "weekly") {
  //       const newData = chartBalance?.map((item: number, i: number) => {
  //         return { month: numberToMonth(i), count: item };
  //       });

  //       setData([...newData].reverse());
  //     }
  //   }
  // }, [chartBalance, period]);

  const mainData = [...data].reverse();
  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="chart__customTooltip">
  //         <p className="chart__customTooltip__label">{`${label}`}</p>
  //         <p className="chart__customTooltip__intro">
  //           earnings :{" "}
  //           <span style={{ color: "#fff" }}>${payload[0].value}</span>
  //         </p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  return (
    <div className="chart">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "40px",
          }}
        >
          <ReactLoading color={"#fff"} width={50} height={50} type="spin" />
        </div>
      ) : data ? (
        <>
          <div className="chart__header">
            <div className="chart__header__amount">
              {title ? title : "ROLAR Token"}
              <div className="chart__header__amount__right">
                + 4,675.23
                <span>+12.1%</span>
              </div>
            </div>
            <div className="chart__header__action">
              {/* <button className="chart__header__action__btn ">Today</button> */}
              {/* <button className="chart__header__action__btn">Weekly</button> */}
              <button className="chart__header__action__btn active">
                Monthly
              </button>
              {/* <button className="chart__header__action__btn">Yearly</button> */}
            </div>
          </div>

          <div className="chart__main">
            <ResponsiveContainer width="100%" aspect={4 / 1}>
              <ComposedChart width={800} height={300} data={mainData}>
                <CartesianGrid
                  stroke="rgba(55, 58, 66, 0.48)"
                  vertical={false}
                />
                <XAxis
                  dataKey="month"
                  axisLine={false}
                  tickMargin={10}
                  tickLine={false}
                  tick={{
                    fill: "#fff",
                    fontSize: "11px",
                    fontWeight: "700",
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickMargin={10}
                  domain={[() => 0, (dataMax) => dataMax]}
                  tick={{
                    fill: "#fff",
                    fontSize: "11px",
                    fontWeight: "700",
                  }}
                />
                {/* <Tooltip cursor={false} content={<CustomTooltip />} /> */}
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop stop-color="rgba(45, 118, 69)" stop-opacity="0.2" />
                    <stop
                      offset="1"
                      stop-color="rgba(217, 217, 217)"
                      stop-opacity="0"
                    />
                    <stop
                      offset="1"
                      stop-color="rgba(45, 118, 69)"
                      stop-opacity="0.01"
                    />
                  </linearGradient>
                </defs>
                <Line
                  dot={false}
                  type="monotone"
                  dataKey="count"
                  stroke="#2D7645"
                  strokeWidth={2.5}
                  strokeLinecap="round"
                  fill="url(#colorUv)"
                  fillOpacity={1}
                />
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke="none"
                  fill="url(#colorUv)"
                  fillOpacity={1}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "40px",
          }}
        >
          {/* <img src={noDataIcon} alt="" /> */}
        </div>
      )}
    </div>
  );
};

export default Chart;
