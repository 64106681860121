import stackIcon from "../../assets/stack2.svg";
import sendIcon from "../../assets/send.svg";
import graphIcon from "../../assets/graph.svg";
import searchIcon from "../../assets/search.svg";
import eth from "../../assets/eth.svg";
import btc from "../../assets/btc.svg";
import sol from "../../assets/sol.svg";
import usdt from "../../assets/usdt.svg";
import xrp from "../../assets/xrp.svg";
import ltc from "../../assets/ltc.svg";
import buy from "../../assets/buy.svg";
import sell from "../../assets/sell.svg";
import { ButtonMain } from "../../component/StyledComponent";
import "./spotTrading.scss";
import ReactPaginate from "react-paginate";
import backIcon from "../../assets/back.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import chart from "../../assets/chart.png";

const SpotTrading = () => {
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState("0.0000");
  const [amountSell, setAmountSell] = useState("0.0000");
  const [type, setType] = useState("buy");
  const [order, setOrder] = useState("open");

  const Token = () => {
    return (
      <div onClick={() => setStep(3)} className="spotTrading__list__item">
        <div className="spotTrading__list__item__token">
          <div className="spotTrading__list__item__token__left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
            >
              <rect
                width="50"
                height="50"
                rx="10"
                fill="#FF971A"
                fill-opacity="0.15"
              />
              <path
                d="M33.953 22.6482C34.3274 20.1264 32.4213 18.7708 29.8149 17.8665L30.6604 14.4504L28.596 13.9323L27.7728 17.2584C27.2301 17.1221 26.6727 16.9936 26.1188 16.8662L26.9479 13.5182L24.8847 13L24.0387 16.4149C23.5896 16.3119 23.1485 16.2101 22.7205 16.1029L22.7229 16.0921L19.8759 15.376L19.3268 17.597C19.3268 17.597 20.8584 17.9507 20.8261 17.9725C21.6621 18.1826 21.8133 18.74 21.7882 19.1819L20.825 23.0736C20.8826 23.0883 20.9573 23.1096 21.0397 23.1429C20.9708 23.1257 20.8975 23.1069 20.8215 23.0886L19.4714 28.5403C19.3693 28.7961 19.1099 29.18 18.5254 29.0342C18.5461 29.0644 17.025 28.6571 17.025 28.6571L16 31.0374L18.6866 31.712C19.1863 31.8382 19.6761 31.9703 20.1584 32.0945L19.3041 35.5498L21.3662 36.0679L22.2122 32.6493C22.7756 32.8034 23.3223 32.9455 23.8574 33.0794L23.0143 36.4819L25.0788 37L25.933 33.5513C29.4534 34.2223 32.1004 33.9518 33.2146 30.7445C34.1123 28.1623 33.1699 26.6728 31.3178 25.7016C32.6668 25.3882 33.6821 24.4943 33.953 22.6482ZM29.2362 29.3105C28.5982 31.8927 24.2818 30.4968 22.8824 30.1468L24.0161 25.5692C25.4154 25.9211 29.9029 26.6174 29.2362 29.3105ZM29.8746 22.6108C29.2926 24.9596 25.7001 23.7663 24.5347 23.4737L25.5625 19.3221C26.7279 19.6147 30.4807 20.1608 29.8746 22.6108Z"
                fill="#F7931A"
              />
            </svg>
            <div className="spotTrading__list__item__token__left__disc">
              <h4>Bitcoin</h4>
              <p>ULC</p>
            </div>
          </div>

          <div className="spotTrading__list__item__token__right">
            <h4>$4,412.65</h4>
            <p>+1.6%</p>
          </div>
        </div>

        <div className="spotTrading__list__item__main">
          <svg
            width="100%"
            height="128"
            viewBox="0 0 292 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 108.571L8.99013 116.319C9.4345 116.75 10.1596 116.678 10.5103 116.168L19.8774 102.543C20.2584 101.989 21.0669 101.962 21.4849 102.489L34.4839 118.874C34.9167 119.42 35.761 119.367 36.1222 118.771L49.2125 97.1935C49.6116 96.5357 50.5729 96.5561 50.9437 97.2302L61.6113 116.625C61.7871 116.944 62.1228 117.143 62.4875 117.143H71.8177C72.322 117.143 72.7474 116.767 72.8099 116.267L74.595 101.987C74.6384 101.64 74.8597 101.341 75.1788 101.199L86.7209 96.0332C87.1397 95.8457 87.6322 95.9673 87.9157 96.3281L94.451 104.645C94.8677 105.176 95.6792 105.15 96.0613 104.594L110.912 82.9943C110.974 82.9035 111.021 82.803 111.051 82.6968L123.571 37.8738C123.691 37.4417 124.085 37.1429 124.534 37.1429H129.88C130.334 37.1429 130.731 36.8373 130.847 36.3985L139.593 3.32573C139.849 2.35806 141.21 2.32486 141.513 3.27891L151.419 34.4972C151.702 35.3902 152.948 35.4382 153.299 34.5697L161.181 15.0802C161.515 14.2564 162.676 14.2439 163.027 15.0603L166.128 22.2763C166.466 23.0632 167.572 23.0886 167.946 22.3181L177.869 1.87175C178.236 1.1174 179.312 1.12148 179.672 1.87858L187.268 17.838C187.472 18.2653 187.947 18.4916 188.407 18.3801L198.901 15.8362C199.216 15.7598 199.549 15.8412 199.794 16.0546L208.984 24.0749C209.141 24.212 209.252 24.3948 209.3 24.5977L218.295 62.5368C218.522 63.4963 219.854 63.5822 220.203 62.6598L229.233 38.7811C229.506 38.0571 230.464 37.9053 230.948 38.5091L236.427 45.34C236.617 45.5766 236.904 45.7143 237.207 45.7143H251.863C252.222 45.7143 252.553 45.5224 252.731 45.2114L256.082 39.3615C256.535 38.5718 257.722 38.7481 257.925 39.6353L262.087 57.7948C262.191 58.2493 262.595 58.5714 263.061 58.5714H269.341C269.8 58.5714 270.2 58.8837 270.312 59.3289L277.6 88.48C277.777 89.189 278.63 89.4746 279.198 89.0153L286.531 83.0903C286.717 82.9399 286.843 82.7277 286.886 82.4922L290 65.4545"
              stroke="#40BF6A"
              stroke-linecap="round"
            />
            <path
              d="M8.99632 116.982L1 109.192V127C1 127.552 1.44772 128 2 128H291C291.552 128 292 127.552 292 127V66.0898L287.171 83.0273C287.115 83.2247 286.999 83.4 286.84 83.5294L279.457 89.523C278.889 89.9845 278.034 89.6987 277.857 88.9881L270.56 59.6647C270.449 59.219 270.049 58.9061 269.59 58.9061H263.304C262.838 58.9061 262.433 58.5834 262.329 58.1285L258.162 39.8595C257.96 38.9706 256.77 38.7945 256.319 39.5866L252.963 45.4709C252.786 45.7829 252.454 45.9755 252.095 45.9755H237.427C237.123 45.9755 236.835 45.8371 236.646 45.5994L231.162 38.7301C230.678 38.1238 229.718 38.2757 229.444 39.0018L220.406 63.019C220.058 63.9432 218.723 63.8575 218.497 62.8964L209.492 24.7358C209.445 24.5333 209.335 24.3508 209.178 24.2136L199.979 16.1468C199.734 15.932 199.399 15.85 199.083 15.9271L188.582 18.4847C188.121 18.597 187.645 18.37 187.442 17.9413L179.838 1.88922C179.478 1.12989 178.399 1.12581 178.033 1.8824L168.101 22.4457C167.728 23.2184 166.619 23.193 166.281 22.4039L163.177 15.146C162.827 14.3275 161.662 14.34 161.33 15.1658L153.44 34.7679C153.09 35.6384 151.841 35.5905 151.559 34.6957L141.642 3.29601C141.34 2.34028 139.977 2.3734 139.722 3.34265L130.966 36.6096C130.851 37.0489 130.454 37.3551 129.999 37.3551H124.648C124.199 37.3551 123.805 37.6545 123.685 38.0873L111.153 83.1707C111.123 83.2766 111.076 83.3769 111.014 83.4676L96.1498 105.191C95.7679 105.749 94.9535 105.775 94.5368 105.242L87.9964 96.8787C87.7128 96.5161 87.2184 96.3939 86.7986 96.5827L75.2459 101.778C74.9275 101.921 74.7068 102.22 74.6637 102.566L72.8759 116.936C72.8137 117.436 72.3881 117.812 71.8836 117.812H62.5453C62.1799 117.812 61.8436 117.613 61.6681 117.292L50.9901 97.7861C50.6198 97.1096 49.6555 97.0892 49.2569 97.7494L36.155 119.45C35.794 120.048 34.9469 120.101 34.5141 119.553L21.5046 103.075C21.0867 102.546 20.2753 102.574 19.8944 103.13L10.5194 116.831C10.1688 117.343 9.44099 117.416 8.99632 116.982Z"
              fill="url(#paint0_linear_629_24015)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_629_24015"
                x1="146.5"
                y1="0"
                x2="146.5"
                y2="128"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#40BF6A" stop-opacity="0.2" />
                <stop offset="1" stop-color="#40BF6A" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            justifyContent: "space-between",
          }}
          className="spotTrading__list__item__amo"
        >
          <p>
            Quantity: <span>1000000000</span>{" "}
          </p>
          <p>Current Price: $0.13040424344</p>
        </div>
      </div>
    );
  };

  const cryptos = [
    {
      name: "Ethereum",
      shrtName: "ETH",
      img: eth,
      bal: "1,677.86",
      qua: "0.8755608",
    },
    {
      name: "Bitcoin",
      shrtName: "BTC",
      img: btc,
      bal: "1,677.86",
      qua: "0.8755608",
    },
    {
      name: "Solana",
      shrtName: "SOL",
      img: sol,
      bal: "",
      qua: "0.00",
    },
    {
      name: "Litecoin",
      shrtName: "LTC",
      img: ltc,
      bal: "",
      qua: "0.00",
    },
    {
      name: "XRP",
      shrtName: "XRP",
      img: xrp,
      bal: "",
      qua: "0.00",
    },
    {
      name: "Tether",
      shrtName: "USDT",
      img: usdt,
      bal: "",
      qua: "0.00",
    },
  ];

  const tableData = [
    {
      pair: "BTC/USDT",
      price: "0.00000655",
      change: "-0.76%",
    },
    {
      pair: "ETH/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "SOL/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "BNB/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "ACM/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "ADA/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "ADX/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "AEGRO/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "AGIX/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "AGLD/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
    {
      pair: "AION/USDT",
      price: "0.00001849",
      change: "-1.65%",
    },
  ];
  const tableData2 = [
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Buy",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Buy",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Sell",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Sell",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Sell",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Sell",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Sell",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Sell",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Sell",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Buy",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Buy",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
    {
      date: "12-01-2023 18:34",
      pair: "0.00000655",
      type: "STOP LIMIT",
      side: "Buy",
      price: "2,400 USDT",
      amount: "0.897973198 ETH",
      filled: "23974292",
      action: "OPEN",
      total: "0.897973198 ETH",
    },
  ];
  return (
    <div className="spotTrading">
      {step === 1 && (
        <>
          <Link to={-1}>
            <img className="spotTrading__back" src={backIcon} alt="" />
          </Link>

          <div className="spotTrading__bal">
            <div className="spotTrading__bal__value">
              <div className="spotTrading__bal__value__header">
                <img src={stackIcon} alt="" />
                <div className="spotTrading__bal__value__header__right">
                  <span>DIVIT</span>
                  <span>EUR</span>
                  <span className="active">USD</span>
                  <span>BTC</span>
                </div>
              </div>

              <div className="spotTrading__bal__value__bottom">
                <h4>Equity Value</h4>
                <p>
                  5,000.00 <span>USD</span>
                </p>
              </div>
            </div>

            <div className="spotTrading__bal__today">
              <div className="spotTrading__bal__today__header">
                <img src={sendIcon} alt="" />
                <p>+5.48%</p>
              </div>

              <div className="spotTrading__bal__today__bottom">
                <h4>Today’s Profit/Loss</h4>
                <p>
                  +600.00 <span>USD</span>
                </p>
              </div>
            </div>

            <div className="spotTrading__bal__today">
              <div className="spotTrading__bal__today__header">
                <img src={graphIcon} alt="" />
                <p>+5.48%</p>
              </div>

              <div className="spotTrading__bal__today__bottom">
                <h4>Overall Profit/Loss</h4>
                <p>
                  +600.00 <span>USD</span>
                </p>
              </div>
            </div>
          </div>

          <div className="spotTrading__btn">
            <ButtonMain
              onClick={() => setStep(2)}
              radius="10px"
              style={{ background: "#008F7E" }}
              $fill
              width="260px"
              height="52px"
            >
              Trade
            </ButtonMain>
          </div>

          <h2 className="spotTrading__title">Current Assets</h2>

          <div className="spotTrading__list">
            <Token />
            <Token />
            <Token />
            <Token />
            <Token />
            <Token />
          </div>

          <div style={{ marginTop: 20, marginBottom: 30 }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={5}
              breakLabel="..."
              //   renderOnZeroPageCount={null}
              //   onPageChange={handlePageClick}
              //   pageRangeDisplayed={itemsPerPage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>

          <div className="spotTrading__amount">
            <h3>Balances</h3>

            <div className="spotTrading__amount__search">
              <input type="text" placeholder="Search" />
              <img src={searchIcon} alt="" />
            </div>

            <div className="spotTrading__amount__list">
              {cryptos.map((item, i) => {
                return (
                  <div key={i} className="spotTrading__amount__list__item">
                    <div className="spotTrading__amount__list__item__left">
                      <img src={item.img} alt="" />
                      <h5>
                        {item.name} <br />
                        {item.shrtName}
                      </h5>
                    </div>
                    <div className="spotTrading__amount__list__item__right">
                      <h5>{item.qua}</h5>
                      <p>{item.bal && `$${item.bal}`}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {step === 2 && (
        <div className="spotTrading__trade">
          <div className="spotTrading__trade__header">
            <div className="spotTrading__trade__header__left">
              <img src={btc} alt="" />
              Bitcoin BTC
            </div>
            <img onClick={() => setStep(step - 1)} src={backIcon} alt="" />
          </div>

          <div className="spotTrading__trade__btn">
            <span
              onClick={() => setType("buy")}
              className={
                type === "buy"
                  ? "spotTrading__trade__btn__item buy activeBuy"
                  : "spotTrading__trade__btn__item buy"
              }
            >
              BUY
            </span>
            <span
              onClick={() => setType("sell")}
              className={
                type === "sell"
                  ? "spotTrading__trade__btn__item sell activeSell"
                  : "spotTrading__trade__btn__item sell"
              }
            >
              SELL
            </span>
          </div>
          {type === "buy" ? (
            <div className="spotTrading__trade__main">
              <select name="type" id="type">
                <option value="limit">Limit</option>
              </select>
              <div className="spotTrading__trade__main__price">
                <span>Price</span>
                <div className="spotTrading__trade__main__price__right">
                  <span onClick={() => setAmount(parseInt(amount) - 1)}>-</span>
                  <input
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    type="number"
                  />
                  <span onClick={() => setAmount(parseInt(amount) + 1)}>+</span>
                </div>
              </div>

              <div className="spotTrading__trade__main__btc">
                <div className="spotTrading__trade__main__btc__left">
                  <img src={btc} alt="" />
                  BTC
                </div>

                <p>0.00</p>
              </div>

              <div className="spotTrading__trade__main__btc">
                <div className="spotTrading__trade__main__btc__left">
                  <img src={usdt} alt="" />
                  USDT
                </div>

                <p>0.00</p>
              </div>

              <div className="spotTrading__trade__main__avail">
                Available: 2,000,000.00 USDT
              </div>

              <ButtonMain
                width="100%"
                height="97px"
                radius="16px"
                $fill
                style={{ background: "#58BD7D", fontSize: 35 }}
              >
                Buy
              </ButtonMain>
            </div>
          ) : (
            <div className="spotTrading__trade__main">
              <select name="type" id="type">
                <option value="limit">Limit</option>
              </select>
              <div className="spotTrading__trade__main__price">
                <span>Price</span>
                <div className="spotTrading__trade__main__price__right">
                  <span onClick={() => setAmountSell(parseInt(amountSell) - 1)}>
                    -
                  </span>
                  <input
                    value={amountSell}
                    onChange={(e) => setAmountSell(e.target.value)}
                    type="number"
                  />
                  <span onClick={() => setAmountSell(parseInt(amountSell) + 1)}>
                    +
                  </span>
                </div>
              </div>

              <div className="spotTrading__trade__main__btc">
                <div className="spotTrading__trade__main__btc__left">
                  <img src={btc} alt="" />
                  BTC
                </div>

                <p>0.00</p>
              </div>
              <div
                style={{ textAlign: "end" }}
                className="spotTrading__trade__main__avail"
              >
                Available: 237.00 BTC
              </div>

              <div className="spotTrading__trade__main__btc">
                <div className="spotTrading__trade__main__btc__left">
                  <img src={usdt} alt="" />
                  USDT
                </div>

                <p>0.00</p>
              </div>

              <ButtonMain
                width="100%"
                height="97px"
                radius="16px"
                $fill
                style={{ background: "#FF2000", fontSize: 35 }}
              >
                Sell
              </ButtonMain>
            </div>
          )}
        </div>
      )}

      {step === 3 && (
        <div className="spotTrading__asset">
          <div className="spotTrading__trade__header">
            <div className="spotTrading__trade__header__left">
              <img src={btc} alt="" />
              Bitcoin BTC
            </div>
            <img onClick={() => setStep(1)} src={backIcon} alt="" />
          </div>

          <div className="spotTrading__bal">
            <div className="spotTrading__bal__value">
              <div className="spotTrading__bal__value__header">
                <img src={stackIcon} alt="" />
                <div className="spotTrading__bal__value__header__right">
                  <span>DIVIT</span>
                  <span>EUR</span>
                  <span className="active">USD</span>
                  <span>BTC</span>
                </div>
              </div>

              <div className="spotTrading__bal__value__bottom">
                <h4>Equity Value</h4>
                <p>
                  5,000.00 <span>USD</span>
                </p>
              </div>
            </div>

            <div className="spotTrading__bal__today">
              <div className="spotTrading__bal__today__header">
                <img src={sendIcon} alt="" />
                <p>+5.48%</p>
              </div>

              <div className="spotTrading__bal__today__bottom">
                <h4>Today’s Profit/Loss</h4>
                <p>
                  +600.00 <span>USD</span>
                </p>
              </div>
            </div>

            <div className="spotTrading__bal__today">
              <div className="spotTrading__bal__today__header">
                <img src={graphIcon} alt="" />
                <p>+5.48%</p>
              </div>

              <div className="spotTrading__bal__today__bottom">
                <h4>Overall Profit/Loss</h4>
                <p>
                  +600.00 <span>USD</span>
                </p>
              </div>
            </div>
          </div>

          <div className="spotTrading__asset__btn">
            <ButtonMain style={{ background: " #C48E0E" }} radius="10px" $fill>
              <img src={buy} alt="" /> Buy
            </ButtonMain>
            <ButtonMain style={{ background: " #EE0E0E" }} radius="10px" $fill>
              <img src={sell} alt="" /> Sell
            </ButtonMain>
          </div>

          <div className="spotTrading__asset__market">
            <div className="spotTrading__amount">
              <h3>Market</h3>

              <div className="spotTrading__amount__search">
                <input type="text" placeholder="Search" />
                <img src={searchIcon} alt="" />
              </div>

              <div className="spotTrading__asset__market__table">
                <div className="spotTrading__asset__market__table__body">
                  <div className="spotTrading__asset__market__table__row">
                    <div
                      style={{ width: "30%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Pair
                    </div>
                    <div
                      style={{ width: "40%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Price
                    </div>
                    <div
                      style={{ width: "30%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Change
                    </div>
                  </div>

                  {tableData.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="spotTrading__asset__market__table__row"
                      >
                        <div
                          style={{ width: "30%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {/* {itemOffset === 0 ? i + 1 : itemOffset + i + 1} */}
                          {item?.pair}
                        </div>
                        <div
                          style={{ width: "40%" }}
                          className="spotTrading__asset__market__table__row__data buy"
                        >
                          {item?.price}
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="spotTrading__asset__market__table__row__data buy"
                        >
                          {item?.change}
                          {/* {item?.date?.substring(0, 10)} */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <img src={chart} style={{ height: "100%", width: "100%" }} alt="" />
          </div>

          <div className="spotTrading__asset__trans">
            <h3>Transactions</h3>

            <div className="spotTrading__asset__trans__main">
              <div className="spotTrading__asset__trans__header">
                <span
                  onClick={() => setOrder("open")}
                  className={order === "open" ? "active" : ""}
                >
                  Open Orders(7)
                </span>
                <span
                  className={order === "history" ? "active" : ""}
                  onClick={() => setOrder("history")}
                >
                  Order History
                </span>
                <span
                  className={order === "trade" ? "active" : ""}
                  onClick={() => setOrder("trade")}
                >
                  Trade History
                </span>
              </div>

              <div className="spotTrading__asset__market__table">
                <div className="spotTrading__asset__market__table__body big">
                  <div
                    className="spotTrading__asset__market__table__row"
                    style={{ borderBottom: "1px solid #AEAEAE" }}
                  >
                    <div
                      style={{ width: "14%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Date
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Pair
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Type
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Side
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Price
                    </div>
                    <div
                      style={{ width: "13%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Amount
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Filled
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Action
                    </div>
                    <div
                      style={{ width: "13%" }}
                      className="spotTrading__asset__market__table__row__head"
                    >
                      Total
                    </div>
                  </div>

                  {tableData2.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="spotTrading__asset__market__table__row"
                      >
                        <div
                          style={{ width: "14%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.date}
                        </div>
                        <div
                          style={{ width: "10%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.pair}
                        </div>
                        <div
                          style={{ width: "10%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.type}
                        </div>
                        <div
                          style={{
                            width: "10%",
                            color: item?.side === "Buy" ? "#58BD7D" : "#FE3C45",
                          }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.side}
                        </div>
                        <div
                          style={{ width: "10%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.price}
                        </div>
                        <div
                          style={{ width: "13%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.amount}
                        </div>
                        <div
                          style={{ width: "10%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.filled}
                        </div>
                        <div
                          style={{ width: "10%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.action}
                        </div>
                        <div
                          style={{ width: "13%" }}
                          className="spotTrading__asset__market__table__row__data"
                        >
                          {item?.total}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpotTrading;
