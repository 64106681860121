import ReactPaginate from "react-paginate";
import ulit from "../../assets/ulit.png";
import wallet1 from "../../assets/wallet1.png";
import "./completeOrder.scss";
import { useState } from "react";
import downloadIcon from "../../assets/download.svg";

const CompleteOrder = () => {
  const [showConf, setShowConf] = useState(false);

  const data = {
    network: "BNB Smart Chain Mainnet",
    address: "0XC610fdksfksldfjos984hrejFa6d",
  };

  const tableData = [
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
    {
      token: "ULC",
      date: "2022/12/09",
      id: "U5356288LIT",
      method: "Coinbase Commerce",
      amount: "2512.046",
      status: "Completed",
    },
  ];
  return (
    <div className="completeOrder">
      {!showConf ? (
        <>
          <div className="dashboard__header">
            <h2>Completed Orders</h2>
            <div className="dashboard__header__main">
              <p>
                {" "}
                <img
                  style={{ width: 30, marginRight: 10 }}
                  src={wallet1}
                  alt=""
                />{" "}
                Metamask
              </p>
              <p>{data.network}</p>
              <p>
                <img src={ulit} alt="" /> {data.address.substring(0, 10)}....
              </p>
            </div>
          </div>

          <div className="completeOrder__table">
            <div className="completeOrder__table__body">
              <div className="completeOrder__table__row">
                <div
                  style={{ width: "8%" }}
                  className="completeOrder__table__row__head"
                ></div>
                <div
                  style={{ width: "12%" }}
                  className="completeOrder__table__row__head"
                >
                  Token
                </div>
                <div
                  style={{ width: "12%" }}
                  className="completeOrder__table__row__head"
                >
                  Date
                </div>
                <div
                  style={{ width: "15%" }}
                  className="completeOrder__table__row__head"
                >
                  Transaction ID
                </div>
                <div
                  style={{ width: "20%" }}
                  className="completeOrder__table__row__head"
                >
                  Payment method
                </div>
                <div
                  style={{ width: "20%" }}
                  className="completeOrder__table__row__head"
                >
                  Amount
                </div>
                <div
                  style={{ width: "13%" }}
                  className="completeOrder__table__row__head"
                >
                  Status
                </div>
              </div>

              {tableData.map((item, i) => {
                return (
                  <div key={i} className="completeOrder__table__row">
                    <div
                      style={{ width: "8%" }}
                      className="completeOrder__table__row__data"
                    >
                      {/* {itemOffset === 0 ? i + 1 : itemOffset + i + 1} */}
                      {i + 1}
                    </div>
                    <div
                      style={{ width: "12%" }}
                      className="completeOrder__table__row__data"
                    >
                      {item?.token}
                    </div>
                    <div
                      style={{ width: "12%" }}
                      className="completeOrder__table__row__data"
                    >
                      {item?.date}
                      {/* {item?.date?.substring(0, 10)} */}
                    </div>
                    <div
                      style={{ width: "15%" }}
                      className="completeOrder__table__row__data"
                    >
                      {item?.id}
                    </div>
                    <div
                      style={{ width: "20%" }}
                      className="completeOrder__table__row__data"
                    >
                      {item?.method}
                    </div>
                    <div
                      style={{ width: "20%" }}
                      className="completeOrder__table__row__data"
                    >
                      {item?.amount}
                    </div>
                    <div
                      style={{ width: "13%" }}
                      className="completeOrder__table__row__data"
                    >
                      <span onClick={() => setShowConf(true)}>
                        {item.status}
                      </span>
                    </div>
                  </div>
                );
              })}

              {/* {pageCount > 1 && ( */}
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={5}
                breakLabel="..."
                //   renderOnZeroPageCount={null}
                //   onPageChange={handlePageClick}
                //   pageRangeDisplayed={itemsPerPage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              {/* )} */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="completeOrder__back">
            <button onClick={() => setShowConf(false)}>Back</button>
          </div>

          <div className="completeOrder__main">
            <h2>Delivery Confirmation</h2>
            <p className="completeOrder__main__disc">
              You have received your purchased token <br />
              25312.046 ULIT COIN{" "}
            </p>

            <div className="completeOrder__main__comfirm">
              <div className="completeOrder__main__comfirm__main">
                <p>Payment details</p>
                <div className="completeOrder__main__comfirm__main__item">
                  Payment Method <span>Coinbase Commerce</span>
                </div>
                <div className="completeOrder__main__comfirm__main__item">
                  Total amount <span>$10,000 (10,000 USDT)</span>
                </div>

                <div className="completeOrder__main__comfirm__main__item">
                  Time <span>15 Jan 2022, 21:16 PM</span>
                </div>
                <div className="completeOrder__main__comfirm__main__item">
                  Paid Invoice confirmation for <span>John Doe</span>
                </div>
                <div className="completeOrder__main__comfirm__main__item">
                  Invoice number <span>001</span>
                </div>
                <p>
                  Transaction ID <span>065468748942</span>
                </p>
                <div className="completeOrder__main__comfirm__btnCon">
                  <div className="completeOrder__main__comfirm__btnCon__download">
                    <img src={downloadIcon} alt="" /> Download Receipt PDF
                  </div>
                  <button>View Wallet</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompleteOrder;
