import styled from "styled-components";

const colorTheme = {
  error: "#a92424",
  primary: "#5057E4",
  gray: "#AEAEAE",
  lightGray: "#E2E4ED",
};

export const InputUser = styled.input`
  height: 60px;
  width: ${(props) => (props.width ? props.width : "360px")};
  /* width: 100%; */
  border-radius: 8px;
  outline: none;
  color: #fff;
  padding: 0 10px;
  padding-left: 80px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background-color: transparent;

  border: ${(props) =>
    props.error
      ? `1px solid ${colorTheme.error}`
      : `1px solid rgba(157, 165, 178, 0.5)`};
  margin-bottom: 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `1px solid ${colorTheme.error}`
        : `1px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `1px solid ${colorTheme.error}`
        : `1px solid ${colorTheme.primary}`};
  }

  &::placeholder {
    color: #b1b5c3;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const InputPin = styled.input`
  height: 5rem;
  width: 30rem;
  border-radius: 13px;
  outline: none;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: white;
  letter-spacing: 7px;
  text-align: center;
  background-color: transparent;
  border: ${(props) =>
    props.error
      ? `1px solid ${colorTheme.error}`
      : `1px solid rgba(157, 165, 178, 0.5)`};
  margin-bottom: 1rem;
  padding: 0 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `1px solid ${colorTheme.error}`
        : `1px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `1px solid ${colorTheme.error}`
        : `1px solid ${colorTheme.primary}`};
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  background: ${(props) =>
    props.$disable
      ? colorTheme.lightGray
      : props?.$fill
      ? colorTheme.primary
      : "transparent"};
  color: ${(props) =>
    props.$disable
      ? colorTheme.gray
      : props.$fill
      ? "white"
      : colorTheme.primary};
  width: ${(props) =>
    props.width ? props?.width : props?.small === "true" ? "80px" : "150px"};
  height: ${(props) =>
    props.height ? props?.height : props?.small === "true" ? "25px" : "48px"};
  border: ${(props) =>
    props.$disable
      ? `1px solid ${colorTheme.lightGray}`
      : props?.$fill
      ? `1px solid ${colorTheme.primary}`
      : `1px solid ${colorTheme.primary}`};
  border-radius: ${(props) => (props.radius ? props.radius : "50px")};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 600;
  gap: 1rem;
  font-size: ${(props) => (props.small === "true" ? "12px" : "16px")};
  cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
`;
export const ButtonMain = styled.button`
  background: ${(props) =>
    props.$disable
      ? colorTheme.lightGray
      : props?.$fill
      ? "linear-gradient(315deg, #9475D4 0%, #30F 100%)"
      : "transparent"};
  color: ${(props) =>
    props.$disable
      ? colorTheme.gray
      : props.$fill
      ? "white"
      : colorTheme.primary};
  width: ${(props) =>
    props.width ? props?.width : props?.small === "true" ? "80px" : "150px"};
  height: ${(props) =>
    props.height ? props?.height : props?.small === "true" ? "25px" : "48px"};
  border: ${(props) =>
    props.$disable
      ? `1px solid ${colorTheme.lightGray}`
      : props?.$fill
      ? `none`
      : `none`};
  border-radius: ${(props) => (props.radius ? props.radius : "50px")};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 600;
  gap: 1rem;
  font-size: ${(props) => (props.small === "true" ? "12px" : "16px")};
  cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
`;
