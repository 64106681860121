import wallet1 from "../../assets/wallet1.png";
import ulit from "../../assets/ulit.png";
import WalletConnectMain from "./WalletConnectMain";
import "./connectWallet.scss";

const ConnectWallet = () => {
  const data = {
    network: "BNB Smart Chain Mainnet",
    address: "0XC610fdksfksldfjos984hrejFa6d",
  };

  return (
    <div className="connectWallet">
      <div className="dashboard__header">
        <h2>Connect Wallets</h2>
        <div className="dashboard__header__main">
          <p>
            {" "}
            <img
              style={{ width: 30, marginRight: 10 }}
              src={wallet1}
              alt=""
            />{" "}
            Metamask
          </p>
          <p>{data.network}</p>
          <p>
            <img src={ulit} alt="" /> {data.address.substring(0, 10)}....
          </p>
        </div>
      </div>

      <WalletConnectMain />
    </div>
  );
};

export default ConnectWallet;
