// import Header from "./component/header/header";
import Home from "./component/home/home";
// import Footer from "./component/footer/footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import BuyModal from "./component/buyModal/BuyModal";
// import QuickBuy from "./component/quickBuy/QuickBuy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import LandingPageLayout from "./layout/landingPageLayout/LandingPageLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import CompleteOrder from "./pages/completeOrder/CompleteOrder";
import PendingOrder from "./pages/pendingOrder/PendingOrder";
import CreateOrder from "./pages/createOrder/CreateOrder";
import GetLoan from "./pages/getLoan/GetLoan";
import Loginpage from "./pages/loginPage/LoginPage";
import ConnectWallet from "./pages/connectWallet/ConnectWallet";
import GetLoanMain from "./pages/getLoanMain/GetLoanMain";
import SpotTrading from "./pages/spotTrading/SpotTrading";
import SureStepOne from "./pages/getLoanMain/suresystem/sureStepone";
import SureStepTwo from "./pages/getLoanMain/suresystem/sureStepTwo";
import SureStepTwoApproved from "./pages/getLoanMain/suresystem/sureStepTwoApproved";
import SureStepThree from "./pages/getLoanMain/suresystem/sureStepThree";
import SureStepFour from "./pages/getLoanMain/suresystem/sureStepFour";
import SureStepFive from "./pages/getLoanMain/suresystem/sureStepFive";
import SureStepFiveApproved from "./pages/getLoanMain/suresystem/sureStepFiveApprove";
import SureStepSix from "./pages/getLoanMain/suresystem/sureStepSix";
import PaymentInvoice from "./pages/getLoanMain/payment/paymentInvoice";
import PayNow from "./pages/getLoanMain/payment/payNow";
import Invoice from "./pages/getLoanMain/payment/invoice";
import Congrats from "./pages/getLoanMain/payment/congrats";
import Awaiting from "./pages/getLoanMain/payment/awaiting";
import Confirmation from "./pages/getLoanMain/payment/confirmation";
import Dictionary from "./pages/getLoanMain/suresystem/dictionary";
// import WhitePaper from "./pages/whitePaper";
import BuyNow from "./pages/buyNow/BuyNow";
import PurchaseToken from "./pages/purchaseToken/PurchaseToken";
// import QuickSell from "./component/quickSell/QuickSell";
import QuickSellCenter from "./pages/quickSellCenter/QuickSellCenter";
import { lazy } from "react";
import TwitterMain from "./component/twitterAuth/twitterMain";
import { SocialMediaProvider } from "./shared/store/services/socialmediaContext";

const QuickBuy = lazy(() => import("./component/quickBuy/QuickBuy"));
const Career = lazy(() => import("./component/career/Career"));
const GetFulcToken = lazy(() =>
  import("./component/getFulcToken/GetFulcToken")
);
const DRC = lazy(() => import("./component/getFulcToken/DRC"));
const UlitCoinGFT = lazy(() => import("./component/getFulcToken/UlitCoinGFT"));
const UlitGFT = lazy(() => import("./component/getFulcToken/UlitGFT"));
const CatsTZone = lazy(() => import("./component/catsTZone/CatsTZone"));
const UCall = lazy(() => import("./component/uCall/UCall"));
const DashboardLayout = lazy(() =>
  import("./layout/dashboardLayout/DashboardLayout")
);

const BuyAndSell = lazy(() => import("./component/buyAndSell/BuyAndSell"));
const Section8 = lazy(() => import("./pages/whitePaper/pages/NewSection8"));
const Section7 = lazy(() => import("./pages/whitePaper/pages/NewSection7"));
const Section6 = lazy(() => import("./pages/whitePaper/pages/NewSection6"));
const Section5 = lazy(() => import("./pages/whitePaper/pages/NewSectionFive"));
const Section4 = lazy(() => import("./pages/whitePaper/pages/NewSection4"));
const Section2 = lazy(() => import("./pages/whitePaper/pages/NewSection2"));
const Section3 = lazy(() => import("./pages/whitePaper/pages/NewSection3"));
const Section1 = lazy(() => import("./pages/whitePaper/pages/NewSection1"));

const WhitePaper = lazy(() => import("./pages/whitePaper"));
const Summary = lazy(() => import("./pages/whitePaper/pages/summary"));
const LiteSummary = lazy(() => import("./pages/whitePaper/pages/lite/summary"));
const LiteSectionOne = lazy(() =>
  import("./pages/whitePaper/pages/lite/SectionOne")
);
const LiteSectionTwo = lazy(() =>
  import("./pages/whitePaper/pages/lite/SectionTwo")
);
const LiteSectionThree = lazy(() =>
  import("./pages/whitePaper/pages/lite/SectionThree")
);
const LiteSectionFour = lazy(() =>
  import("./pages/whitePaper/pages/lite/SectionFour")
);
const LiteSectionFive = lazy(() =>
  import("./pages/whitePaper/pages/lite/SectionFive")
);
const LiteSectionSix = lazy(() =>
  import("./pages/whitePaper/pages/lite/SectionSix")
);
const LiteSectionSeven = lazy(() =>
  import("./pages/whitePaper/pages/lite/SectionSeven")
);
const LiteSectionEight = lazy(() =>
  import("./pages/whitePaper/pages/lite/SectionEight")
);

window.Buffer = window.Buffer || require("buffer").Buffer;

const App = () => {
  return (
    <SocialMediaProvider>
    <div style={{ overflow: "hidden" }}>
      <Router>
        <ToastContainer />
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<LandingPageLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
            <Route index element={<Home />} />
            <Route path="careers" element={<Career />} />
            <Route path="buy" element={<BuyModal />} />
            <Route path="whitepaper" element={<WhitePaper />} />
            <Route path="whitepaper/executive" element={<Summary />} />
            <Route path="whitepaper/section-one" element={<Section1 />} />
            <Route path="whitepaper/section-two" element={<Section2 />} />
            <Route path="whitepaper/section-three" element={<Section3 />} />
            <Route path="whitepaper/section-four" element={<Section4 />} />
            <Route path="whitepaper/section-five" element={<Section5 />} />
            <Route path="whitepaper/section-six" element={<Section6 />} />
            <Route path="whitepaper/section-eight" element={<Section8 />} />
            <Route path="whitepaper/section-seven" element={<Section7 />} />
            <Route path="litepaper/summary" element={<LiteSummary />} />
            <Route path="litepaper/section-one" element={<LiteSectionOne />} />
            <Route
              path="litepaper/section-three"
              element={<LiteSectionThree />}
            />
            <Route path="litepaper/section-two" element={<LiteSectionTwo />} />
            <Route
              path="litepaper/section-four"
              element={<LiteSectionFour />}
            />
            <Route
              path="litepaper/section-five"
              element={<LiteSectionFive />}
            />
            <Route path="litepaper/section-six" element={<LiteSectionSix />} />
            <Route
              path="litepaper/section-seven"
              element={<LiteSectionSeven />}
            />
            <Route
              path="litepaper/section-eight"
              element={<LiteSectionEight />}
            />
            <Route path="get-fulc-token" element={<GetFulcToken />} />
            <Route path="get-fulc-token-drc" element={<DRC />} />
            <Route path="get-fulc-token-ulitcoin" element={<UlitCoinGFT />} />
            <Route path="get-fulc-token-qb" element={<UlitGFT />} />
            <Route path="cats-t-zone" element={<CatsTZone />} />
            <Route path="quick-buy" element={<QuickBuy />} />  
            <Route path="twitter" element={<TwitterMain />} />  
            <Route
              path="quick-sell"
              element={
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 40,
                    background: "#000",
                    color: "#fff",
                    minHeight: "100vh",
                  }}
                >
                  Coming Soon
                </div>
              }
            />
            {/* <Route path="quick-sell" element={<QuickSell />} /> */}
            <Route path="quick-buy-sell" element={<BuyAndSell />} />
            <Route path="u-call-lending" element={<UCall />} />
            <Route path="login" element={<Loginpage />} />
          </Route>

          <Route path="dashboard" element={<DashboardLayout />}>
            {/* <Route
              index
              element={
                <div style={{ textAlign: "center", fontSize: 40 }}>
                  Coming Soon
                </div>
              }
            />
            <Route
              path="*"
              element={
                <div style={{ textAlign: "center", fontSize: 40 }}>
                  Coming Soon
                </div>
              }
            /> */}
            <Route index element={<Dashboard />} />
            <Route path="completed" element={<CompleteOrder />} />
            <Route path="pending" element={<PendingOrder />} />
            <Route path="create" element={<CreateOrder />} />
            <Route path="quick-sell" element={<QuickSellCenter />} />
            <Route path="get-loan" element={<GetLoan />} />
            <Route path="get-loan/loans" element={<GetLoanMain />} />
            <Route path="get-loan/loans/step-one" element={<SureStepOne/>} />
            <Route path="get-loan/loans/step-two" element={<SureStepTwo/>} />
            <Route path="get-loan/loans/step-two-approved" element={<SureStepTwoApproved/>} />
            <Route path="get-loan/loans/step-three" element={<SureStepThree/>} />
            <Route path="get-loan/loans/step-four" element={<SureStepFour/>} />
            <Route path="get-loan/loans/step-five" element={<SureStepFive/>} />
            <Route path="get-loan/loans/step-five-approved" element={<SureStepFiveApproved/>} />
            <Route path="get-loan/loans/step-six" element={<SureStepSix/>} />
            <Route path="get-loan/loans/payment" element={<PaymentInvoice/>} />
            <Route path="get-loan/loans/pay-now" element={<PayNow/>} />
            <Route path="get-loan/loans/invoice" element={<Invoice/>} />
            <Route path="get-loan/loans/congrats" element={<Congrats/>} />
            <Route path="get-loan/loans/awaiting" element={<Awaiting/>} />
            <Route path="get-loan/loans/confirmation" element={<Confirmation/>} />
            <Route path="get-loan/loans/dictionary" element={<Dictionary/>} />
            <Route path="get-loan/spot-trading" element={<SpotTrading />} />
            <Route path="get-loan/buy-now" element={<BuyNow />} />
            <Route path="get-loan/purchase-token" element={<PurchaseToken />} />
            <Route path="connect-wallet" element={<ConnectWallet />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
    </SocialMediaProvider>
  );
};

export default App;
