import {useEffect} from "react";
import "./invoice.scss";
import { ButtonMain } from "../StyledComponent";

export default function SettleRepaymentInvoice({ setStep,step }) {
        useEffect(() => {
          window.scroll(0, 0);
        }, []);
  return (
    <div className="loan__invoice">
      <h2>Payment Invoice</h2>
      <div className="loan__invoice__wrap">
        <h3>Loan Settlement Invoice</h3>
        <aside className="loan__invoice__wrap__content">
          <h3>Loan details</h3>
          <span>
            <p>Currency</p>
            <h3>ULIT COIN</h3>
          </span>
          <hr />
          <span>
            <p>Loan amount</p>
            <h3>$1,376,250.00 (10,000,000.00 ULIT)</h3>
          </span>
          <hr />
          <span>
            <p>DCI (for 75 days)</p>
            <h3>$606,815.62 (6,068,156.20 ULIT)</h3>
          </span>
          <hr />
          <span>
            <p>Gross Asset Value</p>
            <h3>$1,500,000.00 (15,000,000.00 ULIT) </h3>
          </span>
          <hr />
          <span>
            <p>95% CEMA</p>
            <h3>$75,000.00 (750,000.00 ULIT COIN)</h3>
          </span>
          <span>
            <p>MALI</p>
            <h3>$75,000.00 (750,000.00 ULIT COIN)</h3>
          </span>
          <span>
            <p>Total Settlement</p>
            <h3>$1,250,000.00 (12,500,000.00)</h3>
          </span>
        </aside>

        <p className="loan__invoice__wrap__link">See ULIT Dictionary</p>
        <section className="loan__invoice__id">
          <span>
            <p>Wallet Address</p>
            <h3>x12gggs5258bbHTgH6W</h3>
          </span>
          <hr />
          <span>
            <p> Reference Number</p>
            <h3>09877123</h3>
          </span>
          <hr />
          <span>
            <p>Transaction ID</p>
            <h3 className="id">4455UIC</h3>
          </span>
        </section>
        <p className="loan__invoice__note">
          Note, all loan repayments and settlements are only through the process
          of converting the portfolio assets to TXLA.
        </p>
        <ButtonMain
            onClick={() => setStep(7)}
          // onClick={() => setStep(9)}
          $fill
          radius="6px"
          width="50%"
          height="60px"
          style={{ maxWidth: 800 }}
        >
          {/* {step === 8 ? " Convert To TXLA" : "Proceed"} */}
          Proceed
        </ButtonMain>
      </div>
    </div>
  );
}
