import { useEffect } from "react";
import "./convert.scss";
import { BackIcon, ExchangeIcon } from "./icon";
import { ButtonMain } from "../StyledComponent";
// import img from "../../assets/wihitePaper/icon1.svg";
// import img2 from "../../assets/wihitePaper/txla.svg";
export default function ConvertTXLA({ setStep }) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="repay__convert">
      <div onClick={() => setStep(6)} className="repay__convert__back">
        <BackIcon />
      </div>
      <section className="repay__convert__wrap">
        <aside className="repay__convert__wrap__content">
          <span>
            {/* <img src={img} alt="" /> */}
            <h2>ULC</h2>
          </span>
          <h1>500,000.00</h1>
        </aside>
        <div className="repay__convert__exchange">
          <ExchangeIcon />
        </div>
        <aside className="repay__convert__wrap__content">
          <span>
            {/* <img src={img1} alt="" /> */}
            <h2>TXLA</h2>
          </span>
          <h1>500,000.00</h1>
        </aside>
        <div className="repay__convert__wrap__rate">
          <p>Exchange Rate</p>
          <p>1 ULC = 1 TXLA</p>
        </div>
        <ButtonMain
            onClick={() => setStep(6)}
            // onClick={() => setStep(8)}
          $fill
          radius="6px"
          width="80%"
          height="60px"
          style={{ maxWidth: 800 }}
        >
          Convert
        </ButtonMain>
      </section>
    </div>
  );
}
