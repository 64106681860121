import styles from "./buyModal.module.scss";
import buy1Img from "../../assets/buy1.webp";
import buy2Img from "../../assets/buy2.webp";
import { useEffect } from "react";
import Footer from "../footer/footer";
import QBImg from "../../assets/QB.png";
import { Link } from "react-router-dom";

const BuyModal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className={styles.buyModalCon}>
        <div className={styles.footer__quickBuy}>
          <p>
            Quickly purchase ULIT from Ultainfinity OTC now by just a click of a
            button. Enjoy the process as you amass wealth with Ultainfinity.{" "}
          </p>
          <Link to="/quick-buy">
            <button>Quick Buy</button>
          </Link>
        </div>
        <div className={styles.buyModal}>
          <h3 style={{ textAlign: "center" }}>
            How to Buy ULIT Coin, BRC20 Token on the Bitcoin Blockchain
          </h3>
          <div className={styles.buyModalImg}>
            <img src={buy1Img} alt="" />
          </div>
          <p>
            Buy ULIT COIN, a BRC20 token on Bitcoin blockchain with BTC, Bitcoin
            in your wallet at
          </p>
          <p>
            UNISAT.IO MARKETPLACE -{" "}
            <a
              className={styles.buyModalLink}
              target="_blank"
              rel="noreferrer"
              href="https://unisat.io/market?tick=ULIT&tab=1"
            >
              https://unisat.io/market?tick=ULIT&tab=1
            </a>
          </p>
          <div className={styles.buyModalImg}>
            <img src={buy2Img} alt="" />
          </div>

          <h4>How to Buy ULIT Coin</h4>
          <p>
            You can purchase ULIT COINS on UNISAT.IO Marketplace. Follow the
            instructions below on How to Buy ULIT coins on UNISAT.IO
            Marketplace.
          </p>
          <ol>
            <li>
              You should install Unisat Wallet using a desktop computer to
              download the Google Chrome extension from Google Web Store. Note
              each of the currently available BRC20 wallets uses Chrome
              Extension only, and none of the BRC20 wallets is accessible using
              mobile smartphones or tablets.
            </li>
            <li>
              You require BTC Bitcoin to buy ULIT Coin, a BRC20 token on the
              Bitcoin Blockchain Network. Without BTC Bitcoin, you cannot
              purchase any BRC20 tokens.
            </li>
          </ol>

          <div className={styles.quickBuy}>
            <img src={QBImg} alt="" />

            <div className={styles.quickBuy__right}>
              <p>
                Quickly purchase ULIT from Ultainfinity OTC now by just a click
                of a button. Enjoy the process as you amass wealth with
                Ultainfinity.{" "}
              </p>
              <Link to="/quick-buy">
                <button>Quick Buy</button>
              </Link>
            </div>
          </div>

          <h4>Borrow with ULIT Coin</h4>
          <p>
            You can borrow cash or cash equivalent from $500 to $1 million
            [unlimited] for further purchases or trading of ULIT Coins or to do
            another investment trading at up to 75% LTV [Loan To Value] of ULIT
            Coin through U-CALL by ULIT in partnership with ULTAINFINITY. To do
            so, you need to buy and hold ULIT Coins.
          </p>
          <p>Why should any investor Buy, Hold and Trade ULIT COINS?</p>
          <p>
            Because 100, 000 ULIT coins would grow to over $ 1 million within
            months
          </p>

          <h4>Why?</h4>
          <ol>
            <li>
              ULITCOIN.COM, a platform for investment, trading, lending and
              more, will be launched and publicised globally.
            </li>
            <li>
              Within ULITCOIN.COM will be the ability to borrow cash or usdt
              /$usd upto 75% LTV using ULIT coin and 50+ cryptocurrencies and
              other leading BRC20 Tokens and Memecoins as Collateral such as
              ORDI, DOGE, SHIBA INU, PEPE, a first in the industry worldwide,
              and then use the borrowed loans to trade the top 20
              cryptocurrencies, invest in more ULIT Coins, trade ULTAINFINITY
              tokens such as UTTA, DIVIT Tokens and after each ULIT Coin goes up
              in value then the Borrower can tap one-click U-CALL TOP-UP Loan
              using as collateral the portfolio of ULIT Coins.
            </li>
            <li>
              ULIT Coin is a massive wealth-enabler and possesses multiple use
              cases and utilities for various payments, collateral, U-CALL loan
              repayments, exchange, store of value, Ultainfinity's instant
              creditline fee deposits, liquidity pools daily compounding returns
              of excess 3,000% APY, incentive rewards plus airdrops, etc. Read
              the various ULIT-related Medium articles using the links on
              ULITCOIN.COM.
            </li>
          </ol>

          <h4>Crypto Wallets for ULIT Coin</h4>
          <div>
            Below are Compatible Crypto Wallets to Buy and Hold ULIT Coin:{" "}
            <br />
            *UNISAT WALLET: https://unisat.io <br />
            *XVERSE WALLET: https://www.xverse.app <br />
            *HIRO WALLET: https://wallet.hiro.so <br />
            *ORDINALS WALLET: https://ordinalswallet.com <br />
          </div>

          <div style={{ marginTop: 50 }}>
            <video style={{ width: "100%" }} autoPlay loop controls playsInline>
              <source
                src="https://videos.ultainfinity.com/Ulit2.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BuyModal;
