import React, { useState, useRef } from "react";
import "./loanRepayment.scss";

const OtpInputComponent = ({ length, onChange }) => {
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = e.target.value;
    setOtp(updatedOtp);

    // Move to the next input field
    if (e.target.value !== "" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyPress = (e, index) => {
    // Move to the previous input field on Backspace
    if (e.keyCode === 8 && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <div className="loan__repay__modal__steps__form">
      {otp?.map((digit, index) => (
        <input
          key={index}
          style={{
            display: "flex",
            width: "71px",
            height: "71px",
            padding: "18px 0px 17px 0px",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "12px",
            outline: "none",
            fontWeight: "700",
            fontSize: "32px",
            border: "1px solid rgba(51, 0, 255, 0.99) ",
            color: "rgba(51, 0, 255, 0.99) ",
            background: "transparent",
          }}
          ref={(ref) => (inputRefs.current[index] = ref)}
          className="loan__repay__modal__steps__form__input"
          maxLength={1}
          type="text"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyPress(e, index)}
        />
      ))}
    </div>
  );
};

export default OtpInputComponent;
