import { Link, useNavigate } from "react-router-dom";
import "./getLoanMain.scss";
import { useEffect, useState } from 'react';
import { Modal as ReactStrapModal, ModalBody } from "reactstrap";
import styles from './suresystem/css/suresystem.module.scss'
import backIcon from "../../assets/back.svg";
import timerIcon from "../../assets/timer.svg";
import lock from "../../assets/lock.svg";
import ulitCoin from "../../assets/ulitCoin.png";
import  Danger from "../../assets/danger.svg";
import stackIcon from "../../assets/stack.svg";
import questionMark from "../../assets/questionMark.svg";
import ReactLoading from "react-loading";
import { Input } from "../getLoan/GetLoan";
import { Button, ButtonMain, InputPin } from "../../component/StyledComponent";
import LoanRepayment from "../../component/loanRepayment";
import closeIcon from "../../assets/close.svg";
import Modal from "react-modal";
import useLoanRepayment from "../../hooks/useLoanRepayment";
import SocialLinks from "../../component/socialLinks/SocialLinks";
import Invoice from "../../component/loanRepayment/invoice";
import ConvertTXLA from "../../component/loanRepayment/convert";
import SettleRepaymentInvoice from "../../component/loanRepayment/settleRepayment";
import SettlementStatement from "../../component/loanRepayment/settlementStatement";
import CashNowForm from "../../component/cashNowForm/CashNowForm";

const GetLoanMain = () => {
  const [step, setStep] = useState(1);
  const [modalStep, setModalStep] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modal, setModal] = useState(false);
  const { level, loading, handleClick, setLevel, btnDisable, setOtp } =
    useLoanRepayment(setStep, setModalIsOpen);
  const [days, setDays] = useState(30);
  const [dci, setDci] = useState(4);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0)
    if(dci < 5){
      toggle()
    }
  },[])

  const toggle = () => {
    setModal(!modal);
  } 
 
  const goToStepOne=()=>{
    navigate('/dashboard/get-loan/loans/step-one')
  }

  const goToPayment=()=>{
    navigate('/dashboard/get-loan/loans/payment')
  }

  const PriceChart = () => {
    return (
      <svg
        width="100%"
        height="139"
        viewBox="0 0 611 139"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 119.524L19.0104 128.601C19.3509 128.772 19.7593 128.735 20.0635 128.505L41.6972 112.151C42.0342 111.896 42.4949 111.881 42.8483 112.112L72.5752 131.588C72.9474 131.832 73.4359 131.801 73.7738 131.511L103.464 106.074C103.853 105.741 104.43 105.755 104.802 106.106L128.614 128.608C128.8 128.783 129.046 128.881 129.301 128.881H150.808C151.27 128.881 151.672 128.564 151.78 128.115L155.577 112.326C155.666 111.959 155.954 111.672 156.323 111.586L182.121 105.586C182.388 105.523 182.67 105.574 182.899 105.726L198.453 116.014C198.807 116.248 199.27 116.234 199.608 115.978L231.877 91.5842C231.992 91.4973 232.086 91.3867 232.154 91.2598L258.586 42.0743C258.761 41.7499 259.099 41.5476 259.467 41.5476H272.697C273.073 41.5476 273.418 41.3364 273.588 41.0011L293.128 2.5957C293.483 1.89829 294.465 1.86021 294.873 2.52807L317.941 40.3125C318.305 40.9094 319.154 40.9575 319.584 40.4056L338.559 16.0196C338.952 15.5147 339.711 15.5034 340.119 15.9965L348.914 26.6349C349.299 27.0999 350.004 27.1212 350.416 26.6802L373.657 1.79039C374.055 1.36423 374.731 1.36751 375.125 1.7975L392.612 20.8924C392.831 21.1316 393.153 21.2497 393.475 21.2092L417.436 18.1901C417.62 18.167 417.806 18.1952 417.974 18.2716L438.005 27.3571C438.225 27.4569 438.402 27.633 438.502 27.8528L458.265 71.1798C458.583 71.8762 459.533 71.9721 459.984 71.3531L481.111 42.3137C481.424 41.8841 482.018 41.7739 482.464 42.0628L495.861 50.744C496.023 50.8489 496.212 50.9048 496.405 50.9048H528.731C528.979 50.9048 529.218 50.8123 529.402 50.6454L538.404 42.4795C538.91 42.021 539.716 42.1923 539.991 42.8167L549.483 64.3439C549.643 64.7065 550.002 64.9405 550.398 64.9405H565.621C566.005 64.9405 566.356 65.1607 566.522 65.507L582.341 98.3934C582.572 98.8734 583.139 99.0882 583.63 98.8819L600.911 91.6242C601.171 91.5148 601.373 91.3004 601.467 91.0339L608 72.4545"
          stroke="#40BF6A"
          stroke-linecap="round"
        />
        <path
          d="M18.9904 127.656L1 118.576V138C1 138.552 1.44772 139 2 139H610C610.552 139 611 138.552 611 138V71.7694L600.864 90.1871C600.755 90.3844 600.583 90.5394 600.375 90.6267L583.026 97.9239C582.534 98.1306 581.967 97.9156 581.737 97.4351L565.935 64.5354C565.768 64.1888 565.418 63.9684 565.033 63.9684H549.828C549.432 63.9684 549.073 63.7342 548.913 63.3713L539.431 41.8364C539.156 41.2114 538.349 41.04 537.844 41.4992L528.854 49.6667C528.67 49.8339 528.43 49.9265 528.181 49.9265H495.891C495.697 49.9265 495.508 49.8706 495.346 49.7654L481.965 41.0817C481.519 40.7922 480.924 40.9026 480.611 41.3328L459.508 70.3817C459.058 71.0014 458.107 70.9056 457.789 70.2084L438.047 26.8645C437.947 26.6446 437.771 26.4684 437.551 26.3685L417.542 17.2794C417.373 17.2028 417.187 17.1745 417.003 17.1977L393.068 20.2178C392.746 20.2585 392.424 20.1402 392.205 19.9005L374.738 0.799218C374.344 0.368501 373.666 0.365221 373.268 0.792107L350.054 25.69C349.642 26.1317 348.936 26.1104 348.552 25.6447L339.768 15.0046C339.36 14.5108 338.6 14.5221 338.207 15.0277L319.254 39.4209C318.825 39.9736 317.975 39.9255 317.611 39.3279L294.568 1.53093C294.161 0.862331 293.178 0.900407 292.823 1.59853L273.305 40.0182C273.134 40.3539 272.79 40.5653 272.413 40.5653H259.2C258.831 40.5653 258.493 40.7679 258.318 41.0925L231.914 90.2993C231.846 90.4262 231.751 90.5368 231.637 90.6237L199.403 115.027C199.064 115.284 198.601 115.298 198.247 115.064L182.711 104.772C182.481 104.62 182.199 104.569 181.932 104.631L156.162 110.634C155.794 110.72 155.505 111.007 155.417 111.375L151.623 127.17C151.515 127.62 151.113 127.937 150.651 127.937H129.168C128.913 127.937 128.667 127.839 128.481 127.663L104.695 105.153C104.323 104.801 103.745 104.787 103.357 105.121L73.6992 130.567C73.3612 130.857 72.8719 130.889 72.4995 130.645L42.806 111.161C42.4524 110.929 41.991 110.945 41.6538 111.2L20.0446 127.56C19.7403 127.791 19.3312 127.828 18.9904 127.656Z"
          fill="url(#paint0_linear_0_1)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_0_1"
            x1="306"
            y1="0"
            x2="306"
            y2="139"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#40BF6A" stop-opacity="0.2" />
            <stop offset="1" stop-color="#40BF6A" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  const LoanItem = ({ repaid }) => {
    return (
      <div
        className={
          repaid
            ? "getLoanMain__loans__list__item repaid"
            : "getLoanMain__loans__list__item"
        }
        onClick={() => setStep(3)}
      >
        <div className="getLoanMain__loans__list__item__days">
          <img src={timerIcon} alt="" />
          {repaid ? "Repaid" : "29 days"}
        </div>

        <div className="getLoanMain__loans__list__item__disc">
          <div className="getLoanMain__loans__list__item__disc__main">
            <h4>Loan</h4>
            <p>#1</p>
          </div>
          <div className="getLoanMain__loans__list__item__disc__amount">
            <p>$3,500</p>
            <span>+1.6%</span>
          </div>
        </div>

        <div className="getLoanMain__loans__list__item__disc">
          <div className="getLoanMain__loans__list__item__disc__main">
            <h4>Transaction ID</h4>
            <p>4455UIC</p>
          </div>
          <div className="getLoanMain__loans__list__item__disc__main">
            <h4>Reference Number</h4>
            <p>09877123</p>
          </div>
        </div>

        <PriceChart />
      </div>
    );
  };

  const list = [
    {
      title: "Buy More ULIT Coins and Get Top-Up U-CALL Loans Faster",
      disc: "Buy more ULIT COINS. Partake in the virtuous wealth creation from owning more ULIT COINS and using your additional ULIT COINS to get more U-CALL. Grow faster.",
      btnName: "Buy Now",
      click: () => {
        navigate("/dashboard/get-loan/buy-now");
      },
    },
    {
      title: "Buy More Ultainfinity tokens and Get Top-Up U-CALL Loans Faster ",
      disc: "Buy ULTAINFINITY TOKENS. Partake in the virtuous wealth creation from owning more ULTAINFINITY TOKENS and using your additional ULTAINFINITY TOKENS to get more U-CALL. Grow faster.",
      btnName: "Purchase Tokens",
      click: () => {
        navigate("/dashboard/get-loan/purchase-token");
      },
    },
    {
      title: "Use as Cash Now",
      disc: "Privately use your U-CALL Loaned Money as your cash",
      btnName: "Get Cash Now",
      click: () => {
        setModalIsOpen2(true);
      },
    },
    {
      title: "Use your borrowed USDT for Spot trading",
      disc: "Use DOGE as a collateral to borrow USDT to spot trade the top 20 cryptocurrencies eg. BTC, ETH, BNB, SOL, ADA etc",
      btnName: "Start Spot Trading",
      click: () => {
        navigate("/dashboard/get-loan/spot-trading");
      },
    },
    // {
    //   title: "Get Daily Compounding Growth With Ultainfinity SPS ",
    //   disc: "Buy a package of ULTAINFINITY SPS. Experience supersonic success. Partake in the virtuous wealth creation from owning and trading ULTAINFINITY TOKENS using ULTAINFINITY SPS automated tools and strategies. Use your additional cash-loan from ULTAINFINITY SPS to get more U-CALL. You can Grow even faster with ULTAINFINITY SPS which provides Daily Compounding Growth  [Learn more about ULTAINFINITY SPS]",
    //   btnName: "Coming Soon",
    //   click: () => {},
    // },
  ];
  return (
    <div className="getLoanMain">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen2}
        onRequestClose={() => setModalIsOpen2(false)}
        contentLabel=""
      >
        <div className="getLoan__modal">
          <div
            onClick={() => setModalIsOpen2(false)}
            className="getLoan__modal__close"
          >
            <img src={closeIcon} alt="" />
          </div>
          <img src={questionMark} alt="" />
          <h3>Want To Get Cash Now?</h3>
          <p>
            You are about to get cash from your collected loan. If you’re sure
            you want to proceed, kindly click the ‘Continue’ button. If not,
            kindly cancel process
          </p>

          <button
            style={{ width: "150px" }}
            onClick={() => {
              setStep(10);
              setModalIsOpen2(false);
            }}
          >
            Continue
          </button>
        </div>
      </Modal>
      {step < 3 && (
        <div className="getLoan__header">
          <h2>USE YOUR ULIT COINS OR DULC COINS AS COLLATERAL TO BORROW</h2>
          <div className="getLoan__header__btn">
            <Link to="/dashboard/get-loan">
              <button className="alt">Overview</button>
            </Link>
            <Link to="/dashboard/get-loan/loans">
              <button>Loans</button>
            </Link>
          </div>
        </div>
      )}
      {step === 1 && (
        <>
          <div className="getLoanMain__main">
            <div className="getLoanMain__main__item" onClick={() => setStep(2)}>
              <h4>ULIT Coin ULC</h4>
              <div className="getLoanMain__main__item__period">
                <span>Today</span>
                <span>Weekly</span>
                <span className="active">Monthly</span>
                <span>Yearly</span>
              </div>
              <h5>ULIT Asset Portfolio Principal Account</h5>

              <div className="getLoanMain__main__item__amount">
                <p>100,231,428.00</p>
                <span>1,400,000 USD</span>
              </div>

              <div className="getLoanMain__main__item__pro">
                <div className="getLoanMain__main__item__pro__top">
                  Profit/Loss <span>+150.48%</span>
                </div>

                <p>
                  +633,747.00 <span>usd</span>{" "}
                </p>

                <svg
                  width="100%"
                  height="105"
                  viewBox="0 0 398 105"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 96.087H14.8767C15.2737 96.087 15.6332 95.852 15.7926 95.4883L25.7728 72.7125C26.0725 72.0285 26.9891 71.9025 27.4623 72.4802L42.0841 90.3311C42.1405 90.3999 42.1874 90.476 42.2237 90.5572L47.6959 102.818C47.9627 103.416 48.7226 103.6 49.2338 103.191L72.7126 84.4033C73.2008 84.0127 73.9237 84.1608 74.2189 84.712L84.1307 103.219C84.3694 103.665 84.9034 103.862 85.3747 103.679L98.6184 98.5271C98.9088 98.4141 99.13 98.1722 99.2166 97.8728L119.074 29.1719C119.309 28.3591 120.384 28.1872 120.861 28.8864L127.313 38.353C127.71 38.9353 128.569 38.9353 128.966 38.353L136.318 27.5672C136.504 27.2939 136.814 27.1304 137.144 27.1304H155.391C155.759 27.1304 156.098 26.9276 156.272 26.6026L169.455 1.9883C169.864 1.2237 170.986 1.30732 171.278 2.12417L179.614 25.4705C179.875 26.2018 180.834 26.3694 181.327 25.7701L191.206 13.7809C191.697 13.1847 192.65 13.347 192.916 14.0721L206.633 51.4228C206.763 51.7768 207.082 52.0276 207.456 52.0713L216.177 53.0889C216.407 53.1158 216.62 53.2215 216.781 53.3882L226.615 63.5891C227.042 64.0325 227.765 63.9878 228.135 63.4949L235.79 53.285C235.867 53.1829 235.923 53.0671 235.956 52.9438L241.616 31.807C241.788 31.1638 242.529 30.8654 243.099 31.2097L261.82 42.5185C262.238 42.7712 262.778 42.6844 263.096 42.3132L271.378 32.6476C271.808 32.1466 272.596 32.1914 272.966 32.738L286.708 53.0515C286.744 53.104 286.774 53.1598 286.799 53.218L298.019 79.4036C298.354 80.1871 299.454 80.2183 299.833 79.4551L314.201 50.5649C314.433 50.0984 314.984 49.8882 315.468 50.0817L322.513 52.9005C322.865 53.0414 323.266 52.971 323.55 52.7186L348.863 30.1609C349.318 29.7552 350.027 29.8479 350.363 30.3571L358.888 43.2902C359.182 43.7361 359.774 43.8723 360.234 43.5995L371.536 36.885C372.124 36.5357 372.881 36.8665 373.024 37.5353L379.294 66.805C379.503 67.7769 380.848 67.8827 381.205 66.9553L397 26"
                    stroke="#40BF6A"
                    stroke-linecap="round"
                  />
                  <path
                    d="M14.7962 94.3416H1V104C1 104.552 1.44772 105 2.00001 105H396C396.552 105 397 104.552 397 104V26.2571L378.983 66.3249C378.587 67.2037 377.3 67.0701 377.094 66.1289L370.813 37.4793C370.668 36.8173 369.92 36.4883 369.334 36.8286L358.088 43.358C357.633 43.6222 357.051 43.4886 356.757 43.0524L348.283 30.4895C347.947 29.9912 347.251 29.8998 346.797 30.2945L321.627 52.2146C321.346 52.4591 320.953 52.5274 320.606 52.3919L313.589 49.6481C313.11 49.4608 312.567 49.6675 312.334 50.1259L298.056 78.1824C297.673 78.9347 296.588 78.9035 296.249 78.1303L285.1 52.7008C285.074 52.642 285.042 52.5859 285.006 52.5331L271.346 32.8013C270.976 32.2663 270.201 32.222 269.772 32.7112L261.534 42.106C261.217 42.4674 260.688 42.5522 260.274 42.3078L241.653 31.315C241.085 30.9799 240.354 31.2761 240.18 31.9118L234.559 52.4234C234.525 52.5482 234.467 52.6652 234.388 52.7679L226.781 62.6828C226.412 63.1643 225.702 63.2084 225.276 62.7764L215.497 52.8637C215.337 52.7011 215.125 52.5983 214.899 52.5724L206.228 51.5836C205.855 51.541 205.537 51.2932 205.405 50.9418L191.777 14.6772C191.508 13.9619 190.569 13.8002 190.076 14.3844L180.252 26.0366C179.757 26.6239 178.812 26.4567 178.548 25.7351L170.267 3.07086C169.972 2.26373 168.864 2.17987 168.451 2.93344L155.352 26.8359C155.176 27.1562 154.84 27.3553 154.475 27.3553H136.33C136.003 27.3553 135.697 27.5151 135.51 27.7832L128.203 38.2589C127.805 38.8294 126.961 38.8294 126.563 38.2589L120.147 29.0606C119.667 28.373 118.606 28.5452 118.368 29.349L98.636 96.0628C98.5474 96.3622 98.3242 96.603 98.0324 96.7139L84.8655 101.719C84.399 101.897 83.8731 101.703 83.6332 101.265L73.7846 83.2944C73.4881 82.7535 72.7775 82.6073 72.2916 82.9872L48.9411 101.247C48.4318 101.646 47.6841 101.463 47.4155 100.875L41.9803 88.9741C41.9429 88.8924 41.8948 88.816 41.837 88.7472L27.3022 71.4062C26.828 70.8405 25.9254 70.9659 25.6234 71.6395L15.7087 93.7508C15.5475 94.1103 15.1902 94.3416 14.7962 94.3416Z"
                    fill="url(#paint0_linear_3035_10499)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3035_10499"
                      x1="199"
                      y1="1"
                      x2="199"
                      y2="105"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#40BF6A" stop-opacity="0.2" />
                      <stop offset="1" stop-color="#40BF6A" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="getLoanMain__main__item" onClick={() => setStep(2)}>
              <h4>DULC Coin</h4>
              <div className="getLoanMain__main__item__period">
                <span>Today</span>
                <span>Weekly</span>
                <span className="active">Monthly</span>
                <span>Yearly</span>
              </div>
              <h5>ULIT Asset Portfolio Principal Account</h5>

              <div className="getLoanMain__main__item__amount">
                <p>100,231,428.00</p>
                <span>1,400,000 USD</span>
              </div>

              <div className="getLoanMain__main__item__pro">
                <div className="getLoanMain__main__item__pro__top">
                  Profit/Loss <span>+150.48%</span>
                </div>

                <p>
                  +633,747.00 <span>usd</span>{" "}
                </p>

                <svg
                  width="100%"
                  height="105"
                  viewBox="0 0 398 105"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 96.087H14.8767C15.2737 96.087 15.6332 95.852 15.7926 95.4883L25.7728 72.7125C26.0725 72.0285 26.9891 71.9025 27.4623 72.4802L42.0841 90.3311C42.1405 90.3999 42.1874 90.476 42.2237 90.5572L47.6959 102.818C47.9627 103.416 48.7226 103.6 49.2338 103.191L72.7126 84.4033C73.2008 84.0127 73.9237 84.1608 74.2189 84.712L84.1307 103.219C84.3694 103.665 84.9034 103.862 85.3747 103.679L98.6184 98.5271C98.9088 98.4141 99.13 98.1722 99.2166 97.8728L119.074 29.1719C119.309 28.3591 120.384 28.1872 120.861 28.8864L127.313 38.353C127.71 38.9353 128.569 38.9353 128.966 38.353L136.318 27.5672C136.504 27.2939 136.814 27.1304 137.144 27.1304H155.391C155.759 27.1304 156.098 26.9276 156.272 26.6026L169.455 1.9883C169.864 1.2237 170.986 1.30732 171.278 2.12417L179.614 25.4705C179.875 26.2018 180.834 26.3694 181.327 25.7701L191.206 13.7809C191.697 13.1847 192.65 13.347 192.916 14.0721L206.633 51.4228C206.763 51.7768 207.082 52.0276 207.456 52.0713L216.177 53.0889C216.407 53.1158 216.62 53.2215 216.781 53.3882L226.615 63.5891C227.042 64.0325 227.765 63.9878 228.135 63.4949L235.79 53.285C235.867 53.1829 235.923 53.0671 235.956 52.9438L241.616 31.807C241.788 31.1638 242.529 30.8654 243.099 31.2097L261.82 42.5185C262.238 42.7712 262.778 42.6844 263.096 42.3132L271.378 32.6476C271.808 32.1466 272.596 32.1914 272.966 32.738L286.708 53.0515C286.744 53.104 286.774 53.1598 286.799 53.218L298.019 79.4036C298.354 80.1871 299.454 80.2183 299.833 79.4551L314.201 50.5649C314.433 50.0984 314.984 49.8882 315.468 50.0817L322.513 52.9005C322.865 53.0414 323.266 52.971 323.55 52.7186L348.863 30.1609C349.318 29.7552 350.027 29.8479 350.363 30.3571L358.888 43.2902C359.182 43.7361 359.774 43.8723 360.234 43.5995L371.536 36.885C372.124 36.5357 372.881 36.8665 373.024 37.5353L379.294 66.805C379.503 67.7769 380.848 67.8827 381.205 66.9553L397 26"
                    stroke="#40BF6A"
                    stroke-linecap="round"
                  />
                  <path
                    d="M14.7962 94.3416H1V104C1 104.552 1.44772 105 2.00001 105H396C396.552 105 397 104.552 397 104V26.2571L378.983 66.3249C378.587 67.2037 377.3 67.0701 377.094 66.1289L370.813 37.4793C370.668 36.8173 369.92 36.4883 369.334 36.8286L358.088 43.358C357.633 43.6222 357.051 43.4886 356.757 43.0524L348.283 30.4895C347.947 29.9912 347.251 29.8998 346.797 30.2945L321.627 52.2146C321.346 52.4591 320.953 52.5274 320.606 52.3919L313.589 49.6481C313.11 49.4608 312.567 49.6675 312.334 50.1259L298.056 78.1824C297.673 78.9347 296.588 78.9035 296.249 78.1303L285.1 52.7008C285.074 52.642 285.042 52.5859 285.006 52.5331L271.346 32.8013C270.976 32.2663 270.201 32.222 269.772 32.7112L261.534 42.106C261.217 42.4674 260.688 42.5522 260.274 42.3078L241.653 31.315C241.085 30.9799 240.354 31.2761 240.18 31.9118L234.559 52.4234C234.525 52.5482 234.467 52.6652 234.388 52.7679L226.781 62.6828C226.412 63.1643 225.702 63.2084 225.276 62.7764L215.497 52.8637C215.337 52.7011 215.125 52.5983 214.899 52.5724L206.228 51.5836C205.855 51.541 205.537 51.2932 205.405 50.9418L191.777 14.6772C191.508 13.9619 190.569 13.8002 190.076 14.3844L180.252 26.0366C179.757 26.6239 178.812 26.4567 178.548 25.7351L170.267 3.07086C169.972 2.26373 168.864 2.17987 168.451 2.93344L155.352 26.8359C155.176 27.1562 154.84 27.3553 154.475 27.3553H136.33C136.003 27.3553 135.697 27.5151 135.51 27.7832L128.203 38.2589C127.805 38.8294 126.961 38.8294 126.563 38.2589L120.147 29.0606C119.667 28.373 118.606 28.5452 118.368 29.349L98.636 96.0628C98.5474 96.3622 98.3242 96.603 98.0324 96.7139L84.8655 101.719C84.399 101.897 83.8731 101.703 83.6332 101.265L73.7846 83.2944C73.4881 82.7535 72.7775 82.6073 72.2916 82.9872L48.9411 101.247C48.4318 101.646 47.6841 101.463 47.4155 100.875L41.9803 88.9741C41.9429 88.8924 41.8948 88.816 41.837 88.7472L27.3022 71.4062C26.828 70.8405 25.9254 70.9659 25.6234 71.6395L15.7087 93.7508C15.5475 94.1103 15.1902 94.3416 14.7962 94.3416Z"
                    fill="url(#paint0_linear_3035_10499)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3035_10499"
                      x1="199"
                      y1="1"
                      x2="199"
                      y2="105"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#40BF6A" stop-opacity="0.2" />
                      <stop offset="1" stop-color="#40BF6A" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <div className="getLoanMain__loans">
          <img onClick={() => setStep(step - 1)} src={backIcon} alt="" />
          <div className="getLoanMain__loans__list">
            <LoanItem />
            <LoanItem />
            <LoanItem />
            <LoanItem />
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="getLoanMain__loan">
          <img onClick={() => setStep(step - 1)} src={backIcon} alt="" />

          <div className="getLoanMain__loan__main">
            <div className="getLoanMain__loan__main__left">
              <div className="getLoanMain__loan__main__left__top">
                <div className="getLoanMain__loan__main__left__top__left">
                  <div className="getLoanMain__loan__main__left__top__left__header">
                    <img src={stackIcon} alt="" />
                    <div className="getLoanMain__loan__main__left__top__left__header__right">
                      <span>DIVIT</span>
                      <span>ETH</span>
                      <span className="active">USDT</span>
                      <span>USDC</span>
                    </div>
                  </div>

                  <h4>Mirror Collateralised Assets Portfolio</h4>
                  <p>
                    375,000.00 <span>USDT</span>
                  </p>

                  <img
                    className="getLoanMain__loan__main__left__top__left__img"
                    src={lock}
                    alt=""
                  />
                </div>
                {/* <div className="getLoanMain__loan__main__left__top__right">
                  <img src={lock} alt="" />
                </div> */}
              </div>
              <h3 className="getLoanMain__loan__title">
                Note: Your loan is made avaliable to you in USDT as 1:1 USD
              </h3>
              <div className="getLoanMain__loan__main__left__bottom">
                <div>
                  <p>ULC Market Price</p>
                  <span>0.1435603</span>
                </div>
                <div>
                  <p>DCI %</p>
                  <span>2%</span>
                </div>
                <div>
                  <p>DCI Current Amount</p>
                  <span>574,700</span>
                </div>
                <div>
                  <p>No of Days</p>
                  <span>30 days</span>
                </div>
              </div>

              <ButtonMain
                $fill
                height="66px"
                width="100%"
                radius="12px"
                onClick={() => setStep(4)}
                style={{
                  background:
                    "linear-gradient(132deg, #5158F6 0%, #822DF5 52.60%, #F3A761 100%)",
                }}
              >
                Go to Daily Interest Rate Calculator
              </ButtonMain>
            </div>
            <div className="getLoanMain__loan__main__right">
              <div className="getLoanMain__loan__main__right__loan">
                <div className="getLoanMain__loan__main__right__loan__top">
                  <div className="getLoanMain__loan__main__right__loan__top__left">
                    <h4>Loan Account</h4>
                    <p>
                      375,000.00 <span>USDT</span>
                    </p>
                  </div>

                  <span className="getLoanMain__loan__main__right__loan__top__per">
                    +12.96%
                  </span>
                </div>
                <div className="getLoanMain__loan__main__right__loan__bottom">
                  <div>
                    <h5>Reference Number</h5>
                    <p>09877123</p>
                  </div>
                  <img src={ulitCoin} alt="" />
                </div>
              </div>
              <div className="getLoanMain__loan__main__right__collateral">
                <h4>Locked Collateral & Unavailable Amount</h4>
                <p>
                  1,000,000.00 <span>USDT</span>
                </p>
                <span className="getLoanMain__loan__main__right__collateral__per">
                  +12.96%
                </span>
              </div>
            </div>
          </div>

          <div className="getLoanMain__loan__list">
            {list?.map((item, i) => {
              return (
                <div
                  key={i}
                  className={
                    i === 0
                      ? "getLoanMain__loan__list__item show"
                      : "getLoanMain__loan__list__item"
                  }
                >
                  <div className="getLoanMain__loan__list__item__left">
                    <h5>{item.title}</h5>
                    <p>{item.disc}</p>
                  </div>

                  <ButtonMain
                    $fill
                    radius="12px"
                    width="270px"
                    style={{
                      background:
                        item.btnName === "Start Spot Trading"
                          ? "#DF2040"
                          : "linear-gradient(315deg, #9475D4 0%, #30F 100%)",
                    }}
                    //   $disable={item.btnName === "Coming Soon"}
                    onClick={item.click}
                  >
                    {item.btnName}
                  </ButtonMain>
                </div>
              );
            })}
          </div>

          <div className="getLoanMain__loan__warning">
            Risk Warning: Digital asset prices can be volatile. The value of
            your investment can go down or up and you may not get back the
            amount invested. Past performance is not a reliable predictor of
            future performance. Before trading, you should make an independent
            assessment of the appropriateness of the transaction in light of
            your own objectives and circumstances, including the risk and
            potential benefits. Consult your own advisors, where appropriate.
            This information should not be construed as a financial or
            investment advice.
          </div>

          <div className="getLoanMain__loan__time">
            <h3>DAYS TO FIRST 30 DAYS</h3>
            <div className="getLoanMain__loan__time__main">
              <div className="getLoanMain__loan__time__main__item">
                <div className="getLoanMain__loan__time__main__item__num">
                  29
                </div>
                <p>DAYS</p>
              </div>
              <div className="getLoanMain__loan__time__main__item">
                <div className="getLoanMain__loan__time__main__item__num">
                  59
                </div>
                <p>HOURS</p>
              </div>
              <div className="getLoanMain__loan__time__main__item">
                <div className="getLoanMain__loan__time__main__item__num">
                  30
                </div>
                <p>MINS.</p>
              </div>
            </div>
          </div>

          <div className="getLoanMain__loan__btn">
            <ButtonMain
              onClick={() => setModalIsOpen(true)}
              $fill
              radius="10px"
              width="100%"
              height="60px"
              style={{ maxWidth: 800 }}
            >
              Settle & Repay Loan
            </ButtonMain>
          </div>

          <div className="getLoanMain__loan__get" style={{ marginBottom: 20 }}>
            <h3>Get More Loans Today by choosing to Top Up</h3>
            <ButtonMain $fill radius="10px" width="300px" onClick={toggle}>
              Top Up Loan
            </ButtonMain>
          </div>

          <div className="getLoanMain__loan__note">
            <h3>Important Note</h3>
            <ol>
              <li>
                FOR EASY TOP-UP LOANS UPTO $1M, UNLIMITED TIMES, BUY AND USE
                ULIT COIN AS YOUR COLLATERAL FOR UNLIMITED LENDING. YOU CAN ALSO
                EXCHANGE/TRADE YOUR CRYPTO-ASSET [TOP 20 CRYPTOCURRENCIES] FOR
                ULIT COIN ON ULITCOIN.COM
              </li>
              <li>
                We accept OVER 52+ CRYPTOCURRENCIES FOR U-CALL BUT IN ORDER TO
                GET TOP-UP LOANS YOU MUST BUY AND COLLATERALISE ULIT COIN
              </li>
            </ol>
          </div>

          <div className="getLoanMain__loan__get">
            <h3>Get More Loans Today by choosing a U-CALL Case</h3>
            <ButtonMain $fill radius="10px" width="300px">
              Get Now
            </ButtonMain>
          </div>
        </div>
      )}
      {/* {step === 4 && (
        <>
          <img onClick={() => setStep(step - 1)} src={backIcon} alt="" />
          <SpotTrading />
        </>
      )} */}

      {step === 4 && (
        <div className="getLoan__calc">
          <div className="getLoan__calc__header">
            <h3>Daily Interest Rate Calculator</h3>
            <img onClick={() => setStep(3)} src={backIcon} alt="" />
          </div>

          <div className="getLoan__calc__main">
            <div className="getLoan__calc__main__inputCon">
              <div>
                <label
                  className="getLoan__form__label"
                  htmlFor="Collateral Amount"
                >
                  Loan Amount Given
                </label>
                <div className="getLoan__calc__main__inputText">
                  50,000 <span>$</span>
                </div>
              </div>
              <div>
                <label
                  className="getLoan__form__label"
                  htmlFor="Collateral Amount"
                >
                  Interest Rate Percentage
                </label>
                <div className="getLoan__calc__main__inputText">2%</div>
              </div>
            </div>

            <div className="getLoan__calc__main__inputCon">
              <Input
                label="No of Days from Loan Account Creation"
                value={days}
                onChange={(e) => setDays(e.target.value)}
                name="Days"
                holder="Days"
                type="number"
              />
              <div>
                <label
                  className="getLoan__form__label"
                  htmlFor="Collateral Amount"
                >
                  Previous Day DCI Amount
                </label>
                <div className="getLoan__calc__main__inputText">
                  70,000 <span>$</span>
                </div>
              </div>
            </div>

            <div>
              <label
                className="getLoan__form__label"
                htmlFor="Collateral Amount"
              >
                Present Day DCI Amount
              </label>
              <div className="getLoan__calc__main__inputText">
                71,500 <span>$</span>
              </div>
            </div>

            <div className="getLoan__calc__main__vs">
              <div className="getLoan__calc__main__vs__inputCon">
                <div>
                  <label
                    className="getLoan__form__label"
                    htmlFor="Collateral Amount"
                  >
                    Previous Day DCI Amount
                  </label>
                  <div className="getLoan__calc__main__inputText">70,000</div>
                </div>

                <span>VS</span>

                <div>
                  <label
                    className="getLoan__form__label"
                    htmlFor="Collateral Amount"
                  >
                    Present Day DCI Amount
                  </label>
                  <div className="getLoan__calc__main__inputText">71,500</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {step < 3 && (
        <div className="getLoanMain__btn">
          <button>GET LOAN</button>
        </div>
      )}
      {modalStep === 3 && (
        <Modal
          className="modalMain"
          overlayClassName="modal-overlay-alt"
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel=""
        >
          <div className="getLoan__modal">
            <div
              onClick={() => setModalIsOpen(false)}
              className="getLoan__modal__close"
            >
              <img src={closeIcon} alt="" />
            </div>
            {level === 1 && (
              <div className="login__otp">
                {/* <h2 className="login__otp__title">Login to Dashboard</h2> */}
                <p className="login__otp__disc">
                  Complete the Onestep verification to proceed. it is important
                  for account verification
                </p>

                <div className="login__otp__choice">
                  Use OneStep ID to login
                </div>
                <div className="login__otp__messanger">
                  <p>Kindly select a messenger</p>
                  <div className="login__otp__socialLinksCon">
                    <div onClick={() => setLevel(2)}>
                      <SocialLinks />
                    </div>
                    <Link
                      className="login__otp__socialLinksCon__recovery"
                      to="#"
                    >
                      <span>Recovery Centre</span>{" "}
                    </Link>

                    <div className="getHelp__main">
                      <p className="getHelp__disc">
                        Having trouble using OneStep Verification?{" "}
                      </p>
                      <span
                        // onClick={() => setCurrAuth("help")}
                        className="getHelp__btn"
                      >
                        Help Centre
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {level === 2 && (
              <div className="login__verify">
                <h2 className="login__verify__title">OTP Verification</h2>
                <p className="login__verify__subTitle">
                  Complete the Onestep verification to proceed. it is important
                  for account verification
                </p>

                <p className="login__verify__disc">
                  Enter the OTP verification code received in your messenger. 2
                  Factor Authentications are essential identification and
                  verification measures, that OneStep ID by Ultainfinity uses to
                  protect you and your account 24/7.
                </p>

                <InputPin
                  onChange={(e) => setOtp(e.target.value)}
                  type="text"
                />
                <div className="login__btnCon">
                  {level !== 1 && (
                    <Button
                      disabled={btnDisable}
                      $disable={btnDisable}
                      onClick={handleClick}
                      width="80%"
                      $fill
                    >
                      {loading ? (
                        <ReactLoading
                          color="white"
                          width={30}
                          height={30}
                          type="spin"
                        />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
      {modalStep === 2 && (
        <Modal
          className="modalMain"
          overlayClassName="modal-overlay-alt"
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel=""
        >
          <div className="getLoan__modal">
            <div
              onClick={() => setModalIsOpen(false)}
              className="getLoan__modal__close"
            >
              <img src={closeIcon} alt="" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
            >
              <path
                d="M50.0026 8.33594C27.0276 8.33594 8.33594 27.0276 8.33594 50.0026C8.33594 72.9776 27.0276 91.6693 50.0026 91.6693C72.9776 91.6693 91.6693 72.9776 91.6693 50.0026C91.6693 45.3109 90.8525 40.8118 89.415 36.5993L82.6686 43.3457C83.1061 45.4957 83.3359 47.7234 83.3359 50.0026C83.3359 68.3818 68.3818 83.3359 50.0026 83.3359C31.6234 83.3359 16.6693 68.3818 16.6693 50.0026C16.6693 31.6234 31.6234 16.6693 50.0026 16.6693C56.8068 16.6693 63.1356 18.7271 68.4189 22.2438L74.3841 16.2786C67.5174 11.3036 59.1068 8.33594 50.0026 8.33594ZM88.7233 13.7233L45.8359 56.6107L32.1152 42.89L26.2233 48.7819L45.8359 68.3945L94.6152 19.6152L88.7233 13.7233Z"
                fill="#00C076"
              />
            </svg>

            <h3>Verify It’s You</h3>
            <p>
              Proceed to verify that this is you. We will send you a 5 digit
              code via Messenger your choose.
            </p>
            <p>Verify With Biometrics Instead</p>
            <div className="cancel-flex">
              <button className="cancel" onClick={() => setModalIsOpen(false)}>
                Cancel Application
              </button>
              <button onClick={() => setModalStep(3)}>Continue</button>
            </div>
          </div>
        </Modal>
      )}
      {modalStep === 1 && (
        <Modal
          className="modalMain"
          overlayClassName="modal-overlay-alt"
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel=""
        >
          <div className="getLoan__modal">
            <div
              onClick={() => setModalIsOpen(false)}
              className="getLoan__modal__close"
            >
              <img src={closeIcon} alt="" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
            >
              <path
                d="M50.0026 8.33594C41.7617 8.33594 33.7059 10.7796 26.8539 15.358C20.0018 19.9364 14.6613 26.4439 11.5076 34.0575C8.35399 41.671 7.52885 50.0488 9.13657 58.1314C10.7443 66.2139 14.7127 73.6382 20.5398 79.4654C26.367 85.2926 33.7913 89.2609 41.8739 90.8687C49.9564 92.4764 58.3342 91.6512 65.9478 88.4976C73.5614 85.3439 80.0688 80.0034 84.6472 73.1514C89.2256 66.2993 91.6693 58.2435 91.6693 50.0026C91.6693 44.5309 90.5915 39.1127 88.4976 34.0575C86.4037 29.0022 83.3345 24.4089 79.4654 20.5398C75.5963 16.6707 71.003 13.6016 65.9478 11.5076C60.8925 9.41368 55.4744 8.33594 50.0026 8.33594ZM50.0026 83.3359C43.4099 83.3359 36.9653 81.381 31.4836 77.7183C26.002 74.0555 21.7296 68.8496 19.2066 62.7587C16.6837 56.6678 16.0236 49.9656 17.3098 43.4996C18.596 37.0336 21.7706 31.0941 26.4324 26.4324C31.0941 21.7706 37.0336 18.5959 43.4996 17.3098C49.9656 16.0236 56.6679 16.6837 62.7587 19.2066C68.8496 21.7295 74.0556 26.002 77.7183 31.4836C81.381 36.9652 83.336 43.4099 83.336 50.0026C83.336 58.8432 79.8241 67.3216 73.5728 73.5728C67.3216 79.824 58.8432 83.3359 50.0026 83.3359Z"
                fill="#58BD7D"
              />
              <path
                d="M50.0052 25.0003C46.1375 25.0003 42.4281 26.5367 39.6932 29.2716C36.9583 32.0065 35.4219 35.7159 35.4219 39.5836C35.4219 40.6887 35.8609 41.7485 36.6423 42.5299C37.4237 43.3113 38.4835 43.7503 39.5885 43.7503C40.6936 43.7503 41.7534 43.3113 42.5348 42.5299C43.3162 41.7485 43.7552 40.6887 43.7552 39.5836C43.7552 38.3475 44.1218 37.1391 44.8085 36.1113C45.4953 35.0835 46.4714 34.2824 47.6134 33.8094C48.7555 33.3363 50.0121 33.2125 51.2245 33.4537C52.4369 33.6949 53.5505 34.2901 54.4246 35.1642C55.2987 36.0383 55.894 37.1519 56.1351 38.3643C56.3763 39.5767 56.2525 40.8333 55.7795 41.9754C55.3064 43.1174 54.5053 44.0935 53.4775 44.7803C52.4497 45.4671 51.2413 45.8336 50.0052 45.8336C48.9001 45.8336 47.8403 46.2726 47.0589 47.054C46.2775 47.8354 45.8385 48.8952 45.8385 50.0003V58.3336C45.8385 59.4387 46.2775 60.4985 47.0589 61.2799C47.8403 62.0613 48.9001 62.5003 50.0052 62.5003C51.1103 62.5003 52.1701 62.0613 52.9515 61.2799C53.7329 60.4985 54.1719 59.4387 54.1719 58.3336V53.5003C57.539 52.517 60.4372 50.3497 62.3322 47.3979C64.2273 44.4462 64.9916 40.9089 64.4842 37.4381C63.9767 33.9672 62.2318 30.7968 59.571 28.5111C56.9102 26.2254 53.5129 24.9785 50.0052 25.0003Z"
                fill="#58BD7D"
              />
              <path
                d="M50.0026 75.0052C52.3038 75.0052 54.1693 73.1397 54.1693 70.8385C54.1693 68.5374 52.3038 66.6719 50.0026 66.6719C47.7014 66.6719 45.8359 68.5374 45.8359 70.8385C45.8359 73.1397 47.7014 75.0052 50.0026 75.0052Z"
                fill="#58BD7D"
              />
            </svg>

            <h3>Ready To Repay & Settle Loan?</h3>
            <p>
              If YES, Select “Continue” to proceed with process. You will see
              your loan statement/invoice for repayment.
            </p>

            <div className="cancel-flex">
              <button className="cancel" onClick={() => setModalIsOpen(false)}>
                Cancel Process
              </button>
              <button onClick={() => setModalStep(2)}>Continue</button>
            </div>
          </div>
        </Modal>
      )}
      {/* <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel=""
      >
        <div className="getLoan__modal">
          <div
            onClick={() => setModalIsOpen(false)}
            className="getLoan__modal__close"
          >
            <img src={closeIcon} alt="" />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="102"
            viewBox="0 0 100 102"
            fill="none"
          >
            <path
              d="M50.0026 8.8125C41.7617 8.8125 33.7059 11.2892 26.8539 15.9295C20.0018 20.5698 14.6613 27.1651 11.5076 34.8816C8.35399 42.5981 7.52885 51.0891 9.13657 59.2808C10.7443 67.4726 14.7127 74.9972 20.5398 80.9032C26.367 86.8091 33.7913 90.8311 41.8739 92.4605C49.9564 94.09 58.3342 93.2537 65.9478 90.0574C73.5614 86.8612 80.0688 81.4485 84.6472 74.5038C89.2256 67.5592 91.6693 59.3945 91.6693 51.0422C91.6693 45.4965 90.5915 40.0052 88.4976 34.8816C86.4037 29.7581 83.3345 25.1027 79.4654 21.1813C75.5963 17.2599 71.003 14.1493 65.9478 12.027C60.8925 9.9048 55.4744 8.8125 50.0026 8.8125ZM50.0026 84.826C43.4099 84.826 36.9653 82.8446 31.4836 79.1324C26.002 75.4202 21.7296 70.1439 19.2066 63.9707C16.6837 57.7975 16.0236 51.0048 17.3098 44.4513C18.596 37.8979 21.7706 31.8782 26.4324 27.1535C31.0941 22.4287 37.0336 19.2111 43.4996 17.9076C49.9656 16.604 56.6679 17.2731 62.7587 19.8301C68.8496 22.3871 74.0556 26.7173 77.7183 32.273C81.381 37.8287 83.336 44.3604 83.336 51.0422C83.336 60.0023 79.8241 68.5953 73.5728 74.931C67.3216 81.2667 58.8432 84.826 50.0026 84.826Z"
              fill="#58BD7D"
            />
            <path
              d="M50.0052 25.7034C46.1375 25.7034 42.4281 27.2606 39.6932 30.0325C36.9583 32.8044 35.4219 36.5638 35.4219 40.4838C35.4219 41.6038 35.8609 42.6779 36.6423 43.4699C37.4237 44.2619 38.4835 44.7068 39.5885 44.7068C40.6936 44.7068 41.7534 44.2619 42.5348 43.4699C43.3162 42.6779 43.7552 41.6038 43.7552 40.4838C43.7552 39.231 44.1218 38.0063 44.8085 36.9646C45.4953 35.9229 46.4714 35.111 47.6134 34.6315C48.7555 34.1521 50.0121 34.0267 51.2245 34.2711C52.4369 34.5155 53.5505 35.1188 54.4246 36.0047C55.2987 36.8906 55.894 38.0193 56.1351 39.248C56.3763 40.4768 56.2525 41.7504 55.7795 42.9079C55.3064 44.0654 54.5053 45.0547 53.4775 45.7507C52.4497 46.4468 51.2413 46.8183 50.0052 46.8183C48.9001 46.8183 47.8403 47.2632 47.0589 48.0552C46.2775 48.8471 45.8385 49.9212 45.8385 51.0412V59.4872C45.8385 60.6072 46.2775 61.6813 47.0589 62.4733C47.8403 63.2653 48.9001 63.7102 50.0052 63.7102C51.1103 63.7102 52.1701 63.2653 52.9515 62.4733C53.7329 61.6813 54.1719 60.6072 54.1719 59.4872V54.5885C57.539 53.5919 60.4372 51.3954 62.3322 48.4037C64.2273 45.4121 64.9916 41.827 64.4842 38.3093C63.9767 34.7915 62.2318 31.5782 59.571 29.2616C56.9102 26.9451 53.5129 25.6813 50.0052 25.7034Z"
              fill="#58BD7D"
            />
            <path
              d="M50.0026 76.3834C52.3038 76.3834 54.1693 74.4928 54.1693 72.1605C54.1693 69.8282 52.3038 67.9375 50.0026 67.9375C47.7014 67.9375 45.8359 69.8282 45.8359 72.1605C45.8359 74.4928 47.7014 76.3834 50.0026 76.3834Z"
              fill="#58BD7D"
            />
          </svg>

          <h3>Liquidate Running Assets?</h3>
          <p>
            If YES, Select “Liquidate Automatically” to proceed with process.
            Note, all active investments will be liquidated automatically.
          </p>

          <div className="cancel-flex">
            <button className="cancel" onClick={() => setModalIsOpen(false)}>
              Cancel Process
            </button>
            <button
              onClick={() => {
                setStep(5);
                setModalIsOpen(false);
              }}
            >
              Liquidate Automatically
            </button>
          </div>
        </div>
      </Modal> */}
      {step === 5 && <LoanRepayment setStep={setStep} />}
      {step === 6 && <Invoice step={step} setStep={setStep} />}
      {step === 7 && <ConvertTXLA setStep={setStep} />}
      {step === 8 && <SettleRepaymentInvoice step={step} setStep={setStep} />}
      {step === 9 && <SettlementStatement setStep={setStep} />}
      {step === 10 && <CashNowForm setStep={setStep} />}

      <ReactStrapModal isOpen={modal} toggle={toggle} size="lg" className={styles.modalParent}>
          <ModalBody className={styles.modalContent}>
              <div className={styles.successIconDiv}><img src={Danger} className={styles.successIcon} alt="horse" /></div>
              <div className={styles.modalTitle}>Assets Liquidated</div>  
              <div className={styles.modalDesc}>Your loan value has been breached by 5%, thus your assets have been liquidated and you will need to pay for shortfall due to this. Proceed using sure system.</div>
              <div className={styles.centerDiv}><button onClick={goToStepOne} className={styles.modalBtn}>Proceed to SURE System</button></div>   
          </ModalBody>
      </ReactStrapModal>
    </div>
  );
};

export default GetLoanMain;
