import styles from './css/payment.module.scss'
import  Solana from "../../../assets/solanaicon.svg";
import  BackIcon from "../../../assets/arrowcircleleft.svg";
import { useNavigate } from 'react-router-dom';
const Invoice = () => {
  const navigate=useNavigate()
  const goToCongrats=()=>{
    navigate('/dashboard/get-loan/loans/congrats')
  }

  const goBack = () =>{
    navigate('/dashboard/get-loan/loans/pay-now')
  }
    return ( 
        <div>
            <div className={styles.main}>
                 <div onClick={goBack} className={styles.sure}><img src={BackIcon} className={styles.stepIcon} alt="horse" /></div>
                    <div className={styles.parent}>
                     <div className={styles.box}> 
                       <div className={styles.modalTitle}>INVOICE</div>
                       <div className={styles.modalDesc}>You about to purchase 10,000 USDT</div>
                       <div className={styles.boxBorder}>
                        <div className={styles.payment}>Payment details</div>

                     
                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Payment Method</div>
                          <div className={styles.paymentMethod}>Coinbase Commerce <span className={styles.darkAmount}>(2000 DIVIT)</span></div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Total amount</div>
                          <div className={styles.paymentMethod}>$10,000 (0.75564906 BTC)</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Time</div>
                          <div className={styles.paymentMethod}>15 Jan 2022, 21:16 PM</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Paid Invoice confirmation for</div>
                          <div className={styles.paymentMethod}>John Doe</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Invoice number</div>
                          <div className={styles.paymentMethod}>#2156</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                       
                        <div className={styles.invoiceRowBtn}>
                          <div className={styles.paymentMethodTwo}>Transaction ID</div>
                           <button className={styles.invoiceBtn}>065468748942</button>
                        </div>
                       </div>

                       <div className={styles.bb}>
                        <button onClick={goToCongrats} className={styles.invoiceoutlineBtn}> 
                           <div  style={{color:'white'}}>Proceed to Payment</div>
                           <img src={Solana} className={styles.appleIcon} alt="horse" />
                        </button>
                        </div>
              
                     </div>
                     </div>
                    </div>         
        </div>
     );
}
 
export default Invoice;