import { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import exportIcon from "../../assets/export.svg";
import influencerImg from "../../assets/influencer.png";
import globalPImg from "../../assets/globalP.png";
import styles from "./career.module.scss";
import closeIcon from "../../assets/close-circle.svg";
import uploadIcon from "../../assets/upload.svg";

export const ErrorMessage = ({ inputError }) => {
  return (
    <div className={styles.career__apply__form__inputCon__inputError}>
      {inputError}
    </div>
  );
};

const Career = () => {
  const [modalBuy, setModalBuy] = useState(false);
  const [modalLM, setModalLM] = useState(false);
  const [type, setType] = useState("globalPartners");
  const [formData, setFormData] = useState({});
  const [apply, setApply] = useState(false);
  const toggleBuy = () => {
    setModalBuy(!modalBuy);
    // setShowMenu(!showMenu);
  };

  const toggleLM = () => {
    setModalLM(!modalLM);
    // setShowMenu(!showMenu);
  };
  const [filesName, setFilesName] = useState("");

  const resumeInput = useRef(null);

  const handleClick = (reference) => {
    reference.current.click();
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChange = async (event, name) => {
    const fileUploaded = event.target.files[0];

    setFilesName(fileUploaded.name);
    getBase64(fileUploaded, async (result) => {
      setFormData((curr) => {
        return { ...curr, [name]: result };
      });
    });
  };

  console.log("formData", formData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Input = ({ inputError, name, label, value, onChange, type }) => {
    return (
      <div className={styles.career__apply__form__inputCon}>
        <label htmlFor={name}>{label}</label>
        <br />
        <input
          value={value}
          type={type ? type : "text"}
          id={name}
          onChange={onChange}
        />
        <br />
        {inputError && <ErrorMessage inputError={inputError} />}
      </div>
    );
  };

  return (
    <div className={styles.career}>
      <Modal isOpen={modalBuy} toggle={toggleBuy} size="lg">
        <ModalBody className={styles.career__modal}>
          <div>
            <div className={styles.career__modal__close} onClick={toggleBuy}>
              <img src={closeIcon} alt="" />
            </div>
            <img
              style={{ width: "100%" }}
              src={type === "globalPartners" ? globalPImg : influencerImg}
              alt=""
              loading="lazy"
            />
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalLM} toggle={toggleLM} size="lg">
        <ModalBody className={styles.career__modal}>
          <div className={styles.career__modal__disc}>
            <div className={styles.career__modal__close} onClick={toggleLM}>
              <img src={closeIcon} alt="" />
            </div>
            <div>
              <h3>
                WE OFFER A GLOBAL PARTNERSHIP WITH AN OPPORTUNITY TO EARN OVER
                $1M FROM MULTIPLE INCOME STREAMS POWRED BY OUR GLOBAL
                ORGANISATION
              </h3>
              <h4>An overview of what we offer:</h4>
              <p>
                Our global partners are onboarded in a dedicated and
                purpose-built platform as Partners, to promote our products
                worldwide, have a permanent and unique promo code to share and
                earn from , have a multipurpose, expansive and working
                Dashboard, build their own database and earn from their database
                as each purchase our products and borrow from us, have multiple
                streams of incomes that easily run into six to seven figures
                annually, have a promotion opportunity to become Global
                Ambassador with a minimum guaranteed $1 million pay package,
                plus more.
              </p>
              <p>We therefore offer a Global growth partnership</p>

              <h4>THINGS TO NOTE.</h4>
              <p>
                You don’t need a legal entity to be a promoter but you do need
                to know about cryptocurrencies. You also need a one-off
                partnership investment of minimum $500 [For which you would get
                a x3 to x6 immediate return paid into your private
                cryptocurrency wallet and without any restrictions whatsoever]
                therefore , you need to download and possess an online
                cryptocurrency wallet such as the freely available Coinbase
                Walle and Metamask.
              </p>
              <p>
                You will be given our marketing and promotional materials and
                products and access to lots more support, including daily
                guidance, general support, special deals, lending etc in order
                to grow emphatically and quickly
              </p>
              <p>
                We simply want committed, hardworking and serious global
                partners that want serious money and a worldwide opportunity to
                grow.
              </p>
              <h4>NEXT STEP</h4>

              <p>
                Contact us with a simple correspondence confirming you are ready
                to join us.
              </p>
              <p>Are you ready to onboard?</p>
              <p>CONTACT US.</p>
              <p>partners@ultainfinity.com</p>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div className={styles.career__header}>
        <h2>Careers</h2>
      </div>

      <div className={styles.career__mainCon}>
        {!apply ? (
          <div className={styles.career__main}>
            <h3>Open Positions</h3>

            <div className={styles.career__main__list}>
              <div className={styles.career__main__list__item}>
                <div className={styles.career__main__list__item__left}>
                  <h4>Global Partners</h4>
                  <p>
                    Posted Just Now - View{" "}
                    <span
                      onClick={() => {
                        toggleBuy();
                        setType("globalPartners");
                      }}
                    >
                      Job Description.
                    </span>
                  </p>
                </div>
                <div className={styles.career__main__list__item__left__btn}>
                  <span onClick={toggleLM}>Learn more</span>
                  <button onClick={() => setApply(true)}>
                    Apply <img src={exportIcon} alt="" />{" "}
                  </button>
                </div>
              </div>
              <div className={styles.career__main__list__item}>
                <div className={styles.career__main__list__item__left}>
                  <h4>Influncers</h4>
                  <p>
                    Posted Just Now - View{" "}
                    <span
                      onClick={() => {
                        toggleBuy();
                        setType("influencer");
                      }}
                    >
                      Job Description.
                    </span>
                  </p>
                </div>
                <button onClick={() => setApply(true)}>
                  Apply <img src={exportIcon} alt="" />{" "}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.career__apply}>
            <h3>Apply for this job</h3>
            <div className={styles.career__apply__form}>
              <Input name="firstName" label="First Name:" />
              <Input name="lastName" label="Last Name:" />
              <Input name="email" label="Email" />
              <Input name="phone" label="Phone No." />
              <Input name="location" label="Location (Country)" />
              <div className={styles.career__apply__form__upload}>
                <p>Resume/CV</p>
                <div
                  onClick={() => handleClick(resumeInput)}
                  className={styles.career__apply__form__upload__main}
                >
                  <input
                    type="file"
                    accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                    ref={resumeInput}
                    onChange={(e) => handleChange(e, "passportImg")}
                    style={{ display: "none" }}
                  />
                  <img src={uploadIcon} alt="" /> Click to Upload
                </div>
                <div className={styles.career__apply__form__upload__name}>
                  {filesName}
                </div>
              </div>
              {/* <ErrorMessage inputError={inputError?.resume} /> */}

              <hr />

              <Input name="Degree" label="Degree" />
              <Input name="Discipline" label="Discipline" />

              <hr />

              <h4>Social Media Links (for influencers) </h4>
              <Input name="Twitter" label="Twitter" />
              <Input name="Instagram" label="Instagram" />
              <Input name="LinkedIn" label="LinkedIn" />

              <div className={styles.career__apply__form__btn}>
                <button>Apply</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Career;
