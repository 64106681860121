
import React, { createContext, useContext, useState } from "react";

const SocialMediaContext = createContext({
  selectedSocialMedia: "",
  updateSelectedSocialMedia: () => {},
});

const useSocialMediaContext = () => useContext(SocialMediaContext);

const SocialMediaProvider = ({ children }) => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState("");

  const updateSelectedSocialMedia = (socialMedia) => {
    setSelectedSocialMedia(socialMedia);
    localStorage.setItem("selectedSocialMedia", socialMedia);
  };

  const contextValue = {
    selectedSocialMedia,
    updateSelectedSocialMedia,
  };

  return (
    <SocialMediaContext.Provider value={contextValue}>
      {children}
    </SocialMediaContext.Provider>
  );
};

export { SocialMediaContext, useSocialMediaContext, SocialMediaProvider };
