import { useEffect, useState } from "react";
// import wallet from "../../assets/wallet.png";
import styles from "./loginpage.module.scss";
import ulit from "../../assets/ulit.png";
import backIcon from "../../assets/back.svg";
import Login from "../../component/auth/Login";
import Signup from "../../component/auth/Signup";
import HelpCentre from "../../component/helpCenter/HelpCentre";
import WalletConnect from "../../component/walletConnect/WalletConnect";
// import { useNavigate } from "react-router-dom";

const Loginpage = ({ dashboard }) => {
  const user = JSON.parse(sessionStorage.getItem("userUlta") || "{}");

  const [step, setStep] = useState(1);
  // const [address, setAddress] = useState("");
  const [currAuth, setCurrAuth] = useState("login");
  const [disconnect, setDisconnect] = useState(false);
  const [auth, setAuth] = useState(user.token ? true : false);
  const [data, setData] = useState({
    address: "",
    network: "",
    wallet: "",
    walletId: "",
    img: "",
  });
  // const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("data", data);

  const handleLogout = () => {
    sessionStorage.removeItem("userUlta");
    setAuth(false);
    setStep(1);
  };
  const handleDisconnect = () => {
    setData({
      address: "",
      network: "",
      wallet: "",
      walletId: "",
      img: "",
    });
    setStep(1);
    setDisconnect(true);
  };

  // useEffect(() => {
  //   if(user.token) {
  // navigate("/dashboard");
  //   }
  // }, [])

  return (
    <div className={styles.quickBuy}>
      <div className={styles.quickBuy__left}></div>
      <div
        style={{ background: auth ? "inherit" : "#161322" }}
        className={styles.quickBuy__right}
      >
        {step >= 1 && auth && (
          <div className={styles.quickBuy__right__logoutBtn}>
            <div className={styles.quickBuy__right__logoutBtn__back}>
              {step >= 3 && (
                <img onClick={() => setStep(step - 1)} src={backIcon} alt="" />
              )}
            </div>

            <div className={styles.quickBuy__right__logoutBtn__main}>
              {step >= 2 && (
                <button onClick={handleDisconnect}>Disconnect Wallet</button>
              )}
              {!dashboard && <span onClick={handleLogout}>Logout</span>}
            </div>
          </div>
        )}
        {step >= 2 && auth && !dashboard && (
          <div className={styles.quickBuy__right__net}>
            <p>{data.network}</p>
            <p>
              <img src={ulit} alt="" /> {data.address.substring(0, 10)}....
            </p>
          </div>
        )}

        {!auth ? (
          <>
            <div className={styles.quickBuy__right__authBtn}>
              <button onClick={() => setCurrAuth("signup")}>Sign Up</button>
              <button onClick={() => setCurrAuth("login")}>Login</button>
            </div>
            {currAuth === "login" ? (
              <Login setAuth={setAuth} setCurrAuth={setCurrAuth} />
            ) : currAuth === "signup" ? (
              <Signup setAuth={setAuth} setCurrAuth={setCurrAuth} />
            ) : (
              <HelpCentre />
            )}
          </>
        ) : (
          <>
            {step === 1 && (
              <WalletConnect
                disconnect={disconnect}
                setStep={setStep}
                data={data}
                setData={setData}
                login={true}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Loginpage;
