import { Outlet } from "react-router-dom";
import Header from "../../component/header/header";

const LandingPageLayout = () => {
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
};

export default LandingPageLayout;
