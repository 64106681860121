import styles from "./header.module.scss";
import Logo from "../../assets/logo.png";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
// import { PrimaryButton } from "../../shared/utils/button";
import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
// import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
// import LauchIcon from "../../assets/launchicon.svg";
// import AirdropIcon from "../../assets/airdropsicon.svg";
// import PremarketIcon from "../../assets/premarketicon.svg";
// import WalletIcon from "../../assets/walleticon.svg";
// import WealthyIcon from "../../assets/wealthyicon.svg";
// import CreditLineIcon from "../../assets/creditlineicon.svg";
import BuyModal from "../buyModal/BuyModal";

const Header = () => {
  const [showMenu, setShowMenu] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalBuy, setModalBuy] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setShowMenu(!showMenu);
  };

  const toggleBuy = () => {
    setModalBuy(!modalBuy);
    // setShowMenu(!showMenu);
  };
  let navigate = useNavigate();
  // const gotoLaunchPad = () => {
  //   navigate("/wealthlaunchpad");
  // };
  // const gotoAirdrops = () => {
  //   navigate("/airdropsandbounties");
  // };
  // const gotoCreditline = () => {
  //   navigate("/instantcreditline");
  // };
  // const gotoPremarket = () => {
  //   navigate("/premarketexchange");
  // };
  // const gotoWallet = () => {
  //   navigate("/ultainfinitywallet");
  // };
  // const gotoWealthy = () => {
  //   navigate("/wealthy");
  // };
  const gotoHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className={styles.header} id="top">
        <Modal isOpen={modalBuy} toggle={toggleBuy} size="lg">
          <ModalBody className={styles.buyModalCon}>
            <BuyModal toggleBuy={toggleBuy} />
          </ModalBody>
        </Modal>
        <div className={styles.logoParent} onClick={gotoHome}>
          <div>
            {" "}
            <img
              src={Logo}
              style={{ width: 161 }}
              className={styles.Icon}
              alt="horse"
            />
          </div>
        </div>

        {/* <div className={styles.dropdown}>
          <div className={styles.title}>Learn More</div>
          <div className={styles.dropdownContent}>
            <div className={styles.dropDownRow}>
              <div className={styles.logoDrodownDiv}>
                <img src={LauchIcon} className={styles.dropIcon} alt="horse" />
              </div>
              <div className={styles.logoTitleDiv}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ultainfinitywealthlaunchpad.com"
                >
                  <div className={styles.dropDowntitle}>
                    Ultainfinity WEALTH Launchpad
                  </div>
                </a>
              </div>
            </div>

            <div className={styles.dropDownRow}>
              <div className={styles.logoDrodownDiv}>
                <img
                  src={AirdropIcon}
                  className={styles.dropIcon}
                  alt="horse"
                />
              </div>
              <div className={styles.logoTitleDiv}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ultainfinityairdropandbounty.com"
                >
                  <div className={styles.dropDowntitle}>
                    Ultainfinity Airdrops & Bounties
                  </div>
                </a>
              </div>
            </div>
            <div className={styles.dropDownRow}>
              <div className={styles.logoDrodownDiv}>
                <img
                  src={PremarketIcon}
                  className={styles.dropIconTwo}
                  alt="horse"
                />
              </div>
              <div className={styles.logoTitleDiv}>
                <div className={styles.dropDowntitle}>
                  Ultainfinity PreMarket Exchange
                </div>
              </div>
            </div>
            <div className={styles.dropDownRow}>
              <div className={styles.logoDrodownDiv}>
                <img src={WalletIcon} className={styles.dropIcon} alt="horse" />
              </div>
              <div className={styles.logoTitleDiv}>
                <div className={styles.dropDowntitle}>Ultainfinity Wallet</div>
              </div>
            </div>
            <div className={styles.dropDownRow}>
              <div className={styles.logoDrodownDiv}>
                <img
                  src={CreditLineIcon}
                  className={styles.dropIcon}
                  alt="horse"
                />
              </div>
              <div className={styles.logoTitleDiv}>
                <div className={styles.dropDowntitle}>
                  Ultainfinity Instant Creditline
                </div>
              </div>
            </div>
            <div className={styles.dropDownRow}>
              <div className={styles.logoDrodownDiv}>
                <img
                  src={WealthyIcon}
                  className={styles.dropIconTwo}
                  alt="horse"
                />
              </div>
              <div className={styles.logoTitleDiv}>
                <div className={styles.dropDowntitle}>Ultainfinity Wealthy</div>
              </div>
            </div>
          </div>
        </div> */}
        <Link className={styles.title} to="/#learnmore">
          U-Call Lending
        </Link>
        <Link className={styles.title} to="/cats-t-zone" smooth>
          CATS T-Zone
        </Link>
        <Link className={styles.title} to="/#learnmore">
          ULIT Academy
        </Link>
        <Link className={styles.title} to="/get-fulc-token">
          Get FULC Tokens
        </Link>
        <Link
          className={`${styles.title} ${styles.titleAlt}`}
          to="/quick-buy-sell"
        >
          Quick Sell
        </Link>

        <div className={styles.btnDiv}>
          {" "}
          <Link to="/quick-buy">
            <button className={styles.btnDiv__btn}> Quick Buy</button>
          </Link>
          {/* <Link to="/login">
            <button className={styles.btnDiv__btnAlt}>ULIT Dashboard</button>
          </Link> */}
        </div>
        <div onClick={toggle} className={styles.menuDiv}>
          <AiOutlineMenu className={styles.menu} />
        </div>
        <Modal isOpen={modal} toggle={toggle} fullscreen>
          <ModalBody className={styles.modalParent}>
            <div onClick={toggle} className={styles.modalClose}>
              {" "}
              <AiOutlineClose className={styles.menu} />
            </div>
            <div className={styles.modalMenu}>
              {/* <motion.div
                className={styles.modalMenufirst}
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.2, type: "spring", duration: 7 }}
              > */}
              <div className={styles.modalMenufirst}>
                <Link
                  onClick={toggle}
                  className={styles.titleModal}
                  to="/quick-buy-sell"
                >
                  Quick Sell
                </Link>
                {/* <div className={styles.dropdown}>
                  <Link
                    onClick={toggle}
                    className={styles.titleModal}
                    to="/#learn"
                  >
                    Learn More
                  </Link>
                  <div className={styles.dropdownContent}>
                    <div className={styles.dropDownRow}>
                      <div className={styles.logoDrodownDiv}>
                        <img
                          src={LauchIcon}
                          className={styles.dropIcon}
                          alt="horse"
                        />
                      </div>
                      <div className={styles.logoTitleDiv}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://ultainfinitywealthlaunchpad.com"
                        >
                          <div className={styles.dropDowntitle}>
                            Ultainfinity WEALTH Launchpad
                          </div>
                        </a>
                      </div>
                    </div>

                    <div className={styles.dropDownRow}>
                      <div className={styles.logoDrodownDiv}>
                        <img
                          src={AirdropIcon}
                          className={styles.dropIcon}
                          alt="horse"
                        />
                      </div>
                      <div className={styles.logoTitleDiv}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://ultainfinityairdropandbounty.com"
                        >
                          <div className={styles.dropDowntitle}>
                            Ultainfinity Airdrops & Bounties
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className={styles.dropDownRow}>
                      <div className={styles.logoDrodownDiv}>
                        <img
                          src={PremarketIcon}
                          className={styles.dropIconTwo}
                          alt="horse"
                        />
                      </div>
                      <div className={styles.logoTitleDiv}>
                        <div className={styles.dropDowntitle}>
                          Ultainfinity PreMarket Exchange
                        </div>
                      </div>
                    </div>
                    <div className={styles.dropDownRow}>
                      <div className={styles.logoDrodownDiv}>
                        <img
                          src={WalletIcon}
                          className={styles.dropIcon}
                          alt="horse"
                        />
                      </div>
                      <div className={styles.logoTitleDiv}>
                        <div className={styles.dropDowntitle}>
                          Ultainfinity Wallet
                        </div>
                      </div>
                    </div>
                    <div className={styles.dropDownRow}>
                      <div className={styles.logoDrodownDiv}>
                        <img
                          src={CreditLineIcon}
                          className={styles.dropIcon}
                          alt="horse"
                        />
                      </div>
                      <div className={styles.logoTitleDiv}>
                        <div className={styles.dropDowntitle}>
                          Ultainfinity Instant Creditline
                        </div>
                      </div>
                    </div>
                    <div className={styles.dropDownRow}>
                      <div className={styles.logoDrodownDiv}>
                        <img
                          src={WealthyIcon}
                          className={styles.dropIconTwo}
                          alt="horse"
                        />
                      </div>
                      <div className={styles.logoTitleDiv}>
                        <div className={styles.dropDowntitle}>
                          Ultainfinity Wealthy
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* </motion.div> */}
              </div>
              {/* <motion.div
                className={styles.modalMenufirst}
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.2, type: "spring", duration: 7 }}
              > */}
              <div className={styles.modalMenufirst}>
                <Link
                  onClick={toggle}
                  className={styles.titleModal}
                  to="/#learnmore"
                  smooth
                >
                  U-Call Lending
                </Link>
                <Link
                  onClick={toggle}
                  className={styles.titleModal}
                  to="/cats-t-zone"
                  smooth
                >
                  CATS T-Zone
                </Link>
                <Link
                  onClick={toggle}
                  className={styles.titleModal}
                  to="/#learnmore"
                  smooth
                >
                  ULIT Academy
                </Link>
                <Link
                  onClick={toggle}
                  className={styles.titleModal}
                  to="/get-fulc-token"
                  smooth
                >
                  Get FULC Tokens
                </Link>
                <div className={styles.titleModalbutton}>
                  {/* <Link onClick={toggle} to="/buy">
                    <button className={styles.btnDiv__btn}>
                      Buy ULIT Coin
                    </button>
                  </Link> */}
                  <Link onClick={toggle} to="/quick-buy">
                    <button className={styles.btnDiv__btn}>Quick Buy</button>
                  </Link>
                </div>
                {/* <div className={styles.titleModalbutton}>
                  <Link onClick={toggle} to="/login">
                    <button className={styles.btnDiv__btnAlt}>
                      ULIT Dashboard
                    </button>
                  </Link>
                </div> */}
              </div>
              {/* </motion.div> */}
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Header;
