import React, { useEffect } from 'react'
import "./invoice.scss";
import { ButtonMain } from '../StyledComponent';


export default function Invoice({ setStep,step }) {
    useEffect(() => {
window.scroll(0,0)
    },[])
  return (
    <div className="loan__invoice">
      <h2>Payment Invoice</h2>
      <div className="loan__invoice__wrap">
        <h3>Loan Repayment Invoice</h3>
        <aside className="loan__invoice__wrap__content">
          <h3>Loan details</h3>
          <span>
            <p>Currency</p>
            <h3>ULIT COIN</h3>
          </span>
          <hr />
          <span>
            <p>Repayment amount</p>
            <h3>$1,376,250.00 (10,000,000.00 ULIT)</h3>
          </span>
          <hr />
          <span>
            <p>Time</p>
            <h3>20:34</h3>
          </span>
          <hr />
          <span>
            <p>Paid Invoice confirmation for</p>
            <h3>Joe Brown</h3>
          </span>
          <hr />
          <span>
            <p>Invoice number</p>
            <h3>IN23405</h3>
          </span>
        </aside>
        <p className="loan__invoice__wrap__link">See ULIT Dictionary</p>
        <section className="loan__invoice__id">
          <span>
            <p>Wallet Address</p>
            <h3>x12gggs5258bbHTgH6W</h3>
          </span>
          <hr />
          <span>
            <p> Reference Number</p>
            <h3>09877123</h3>
          </span>
          <hr />
          <span>
            <p>Transaction ID</p>
            <h3 className='id'>4455UIC</h3>
          </span>
        </section>
        <p className="loan__invoice__note">
          Note, all loan repayments and settlements are only through the process
          of converting the portfolio assets to TXLA.
        </p>
        <ButtonMain
        //   onClick={() => setStep(7)}
          onClick={() => setStep(8)}
          $fill
          radius="6px"
          width="50%"
          height="60px"
          style={{ maxWidth: 800 }}
        >
          {/* Convert To TXLA */}
          Proceed
        </ButtonMain>
      </div>
    </div>
  );
}

