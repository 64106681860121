import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../suresystem/css/suresystem.module.scss';
import  StepFiveIcon from "../../../assets/surestepfiveicon.svg";

const SureStepFiveApproved = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])
    let navigate=useNavigate()
    const gotoSureStepSix=()=>{
      navigate('/dashboard/get-loan/loans/step-six')
    }
  

    return ( 
        <div className={styles.main}>
         <div className={styles.sure}>SURE SYSTEM</div>
        <div className={styles.parent}>
            <div className={styles.box}>
               <img src={StepFiveIcon} className={styles.stepIcon} alt="horse" />
               <div className={styles.sureTitle}>Request Approved! Proceed to Input Your Code</div>   
               <div className={styles.Surelist}>Your request has been has been approved and your authentication code has been released.</div>
               <div className={styles.Surelist}>Proceed to input the code.</div>
               <div className={styles.receiptBtnDiv}>
                 <button className={styles.btn} onClick={gotoSureStepSix}>Next</button>
               </div>
            </div>
        </div>
        </div>
     );
}
 
export default SureStepFiveApproved;