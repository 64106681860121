import axios from "axios";
import authHeader from "../store/services/authHeader";

export async function  GET_SERVICE (endpoint) {
  const url = process.env.REACT_APP_API_URL + endpoint;  
  const headers =  authHeader();
  try {
      return await axios.get(url,{headers});
  } catch (error) {
      return error.response;
  }
}  

export async function  POST_SERVICE (endpoint,body) {
    const url = process.env.REACT_APP_API_URL + endpoint;  
    const headers =  authHeader();
    try {
        return await axios.post(url,body,{headers});
    } catch (error) {
        return error.response;
    }
}  