import { useNavigate } from 'react-router-dom';
import styles from './css/payment.module.scss'
import  BackIcon from "../../../assets/arrowcircleleft.svg";
const Awaiting = () => {
    let navigate=useNavigate()
    const gotoDashboard=()=>{
        // navigate('/dashboard')
        navigate('/dashboard/get-loan/loans/confirmation')
    }
    const goBack = () =>{
        navigate('/dashboard/get-loan/loans/congrats')
      }
    return ( 
        <div className={styles.main}>
         <div onClick={goBack} className={styles.sure}><img src={BackIcon} className={styles.stepIcon} alt="horse" /></div>
         <div className={styles.parent}>
           <div className={styles.box}>
        
            <div className={styles.modalTitle}>Awaiting Delivery Confirmation</div>  
            <div style={{paddingTop:'1.5em'}} className={styles.paymentMethod}>Your deposit has been sent and is currently under review and checks. Once verified, you will see your delivery confirmation here, once your purchased token has been released to you after the receipt of your deposit has been confirmed by the Admin. This may take few hours and up to 3 days. Kindly wait patiently. Once released, you will see the released purchased token amount in your wallet.</div>
            <div style={{paddingTop:'1.5em'}} className={styles.paymentMethod}>You can revisit this page for an update on your transaction by simply clicking the Pay Shortfall Settlement Button in loan dashboard</div>   
            <div style={{paddingTop:'1.5em'}} className={styles.paymentMethod}>The delivery of your purchase would be made and the confirmation will appear in this page</div> 
            <div style={{paddingTop:'1.5em'}} className={styles.paymentMethod}>In the event that you wish to contact ULIT, contact OTC Desk.</div>
            <div className={styles.bb}><button className={styles.proceedBtn} onClick={gotoDashboard}>Go Back To Dashboard</button></div>
        </div>
        </div>
      </div>
     );
}
 
export default Awaiting;