import styles from '../payment/css/payment.module.scss';
import  Coinbase from "../../../assets/coinbaseicon.svg";
import  Solana from "../../../assets/solanaicon.svg";
import  BackIcon from "../../../assets/arrowcircleleft.svg";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from "react-loading";
import { useEffect, useState } from 'react';
import {MdArrowDropDown} from "react-icons/md";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
const PayNow = () => {
    const [selectedPay,setSelectedPay]=useState();
    const [loading, setLoading] = useState(false);
    const [amount,setAmount]=useState('');
    const [token]=useState([
      {id:0, name:'ULIT COIN'},
      {id:1, name:'DIVIT TOKEN'},
      {id:2, name:'SLAPA LP TOKEN'},
      {id:3, name:'SLAPA TOKEN'},
      {id:4, name:'SYNTHETIC SLAPA TOKEN'},
      {id:5, name:'UTTA TOKEN'},  
      {id:6, name:'ROLAR TOKEN'},  
      {id:7, name:'UGEX TOKEN'},    
      {id:8, name:'DULC COIN'},    
      {id:9, name:'FULC COIN'}, 
      {id:10, name:'300M TOKEN'},     
      {id:8, name:'MegaT'},  
      {id:8, name:'TXLA'},      
   ])
    const currencyType = [
      { id: 1, value: "eth", name: "Ethereum" },
      { id: 2, value: "usdc", name: "USDC" },
      { id: 3, value: "btc", name: "Bitcoin" },
      { id: 4, value: "usdt", name: "USDT" },
      { id: 5, value: "doge", name: "Dogecoin" },
      { id: 6, value: "ltc", name: "Litecoin" },
      { id: 7, value: "dai", name: "Dai" },
      { id: 8, value: "bch", name: "Bitcoin Cash" },
      { id: 9, value: "ape", name: "ApeCoin" },
      { id: 10, value: "shib", name: "Shiba Inu" },
    ];
    const [paymentType]=useState([
        {id:1, icon:<img src={Coinbase} className={styles.payicon} alt="horse" />, name:'Pay with Coinbase Commerce'},  
        {id:2,icon:<img src={Solana} className={styles.payicon} alt="horse" />, name:'Pay with Solana Pay'},  
    ])
    const [selectToken,setSelectToken]=useState('Select cryptocurrencies')
    const [currency,setCurrency]=useState('Select payment Currency')
    const [menu,setMenu]=useState(false);
    const [menuTwo,setMenuTwo]=useState(false);
    const navigate=useNavigate()
    useEffect(() => {
       window.scrollTo(0,0)
    },[])

    const changeValue=async(e)=>{
        setSelectToken(e.name)
        console.log(e.name)
    }   
    const changeValueCurrency=async(e)=>{
        setCurrency(e.value)
    }   
    const selectPaymentType=async(e)=>{
        setSelectedPay(e.id)
    }   

    const gotoInvoice = () =>{
      navigate('/dashboard/get-loan/loans/invoice')
    }

    const goBack = () =>{
      navigate('/dashboard/get-loan/loans/payment')
    }

    return ( 
        <div className={styles.main}>
        <div onClick={goBack} className={styles.sure}><img src={BackIcon} className={styles.stepIcon} alt="horse" /></div>
        <div className={styles.parent}>
           <div className={styles.box}> 
               <div>QB Paynow</div>

               <div className={styles.rowInput}>
                    <Dropdown  isOpen={menu} toggle={() =>setMenu(!menu)} style={{cursor:'pointer'}} >
                        <DropdownToggle tag="a" className={styles.dropdownToggle} >
                          <div className={styles.selected}>{selectToken}</div>
                          <div className={styles.dropDownrow}>
                            <div style={{color:'#777E90',}}><MdArrowDropDown style={{fontSize:'2em'}} /></div>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className={styles.dropBox}>
                          {token.map(tokenName=>
                            <DropdownItem className={styles.value}  onClick={()=>changeValue(tokenName)}>{tokenName.name} </DropdownItem>
                          )}
                        </DropdownMenu>
                    </Dropdown> 
                </div>

               <div className={styles.rowInput}>
                    <Dropdown  isOpen={menuTwo} toggle={() =>setMenuTwo(!menuTwo)} style={{cursor:'pointer'}} >
                        <DropdownToggle tag="a" className={styles.dropdownToggle} >
                          <div className={styles.selected}>{currency}</div>
                          <div className={styles.dropDownrow}>
                            <div style={{color:'#777E90',}}><MdArrowDropDown style={{fontSize:'2em'}} /></div>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className={styles.dropBox}>
                          {currencyType.map(tokenName=>
                            <DropdownItem className={styles.value}  onClick={()=>changeValueCurrency(tokenName)}>{tokenName.name} </DropdownItem>
                          )}
                        </DropdownMenu>
                    </Dropdown> 
               </div>

                <div className={styles.input}>
                      <input onChange={e => setAmount(e.target.value)} type='text'  placeholder='Amount' className={styles.inputMain}/>
                      <div className={styles.dropDownrow}>
                        <div className={styles.borderLine}/>
                        <div className={styles.inputToken}>USD</div>
                      </div>
                </div>

                <div className={styles.input}>
                      <input onChange={e => setAmount(e.target.value)} type='text'  placeholder='Amount' className={styles.inputMain}/>
                      <div className={styles.dropDownrow}>
                        <div className={styles.borderLine}/>
                        <div className={styles.inputToken}>Currency</div>
                      </div>
                </div>


               <div className={styles.whatAmount}>Select Payment Method</div>
               <div>
                  {paymentType.map(currency=>
                     <div style={{border:currency.id===selectedPay ? '2px solid #666DFF' : ''}} className={styles.selectCoin}  onClick={()=>selectPaymentType(currency)}>
                        <div>{currency.icon}</div>
                        <div>{currency.name}</div>
                     </div>
                  )}
               </div>
               <div className={styles.borderline}/>
               
               <div className={styles.btnDiv}>
               <button onClick={gotoInvoice} disabled={!amount.length || selectToken==='Select Token' || currency==='Select Currency' || !selectedPay}  className={styles.btn} style={{background:!amount.length || selectToken==='Select Token' || currency==='Select Currency' ||!selectedPay ?'#BEC0F9' : ''}}>
                   {loading ? <ReactLoading color="white" width={25} height={25} type="spin" />: 'Continue'} 
               </button>
               </div>
               
           </div>
        </div>
       </div>
     );
}
 
export default PayNow;