import styles from './css/payment.module.scss'
import  Filled from "../../../assets/filled.svg";
import  BackIcon from "../../../assets/arrowcircleleft.svg";
import { useNavigate } from 'react-router-dom';
const Congrats = () => {

  const navigate=useNavigate()
  const goToAwaiting=()=>{
    navigate('/dashboard/get-loan/loans/awaiting')
  }

  const goBack = () =>{
    navigate('/dashboard/get-loan/loans/invoice')
  }
    return ( 
        <div>
            <div className={styles.main}>
                 <div onClick={goBack} className={styles.sure}><img src={BackIcon} className={styles.stepIcon} alt="horse" /></div>
                  <div className={styles.parent}> 
                    <div className={styles.box}>
                   
                       <div className={styles.modalTitle}>Congrats</div>
                       <div className={styles.modalDesc}>You successfully paid a deposit of</div>
                       <div className={styles.btc}>0.25644 BTC</div>
                       <div className={styles.bb}>
                        <button onClick={goToAwaiting}  className={styles.downloadBtn}> 
                           <div>Download Receipt PDF </div>
                           <img src={Filled} className={styles.appleIcon} alt="horse" />
                        </button>
                        </div>

                       <div className={styles.boxBorder}>
                        <div className={styles.payment}>Payment details</div>
                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Payment Method</div>
                          <div className={styles.paymentMethod}>Coinbase Commerce <span className={styles.darkAmount}>(2000 DIVIT)</span></div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Total amount</div>
                          <div className={styles.paymentMethod}>$10,000 (0.75564906 BTC)</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>To Recieve</div>
                          <div className={styles.paymentMethod}>ULIT COIN (TBA)</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Time</div>
                          <div className={styles.paymentMethod}>15 Jan 2022, 21:16 PM</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Paid Invoice confirmation for</div>
                          <div className={styles.paymentMethod}>John Doe</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                        <div>
                        <div className={styles.invoiceRow}>
                          <div className={styles.paymentMethodTwo}>Invoice number</div>
                          <div className={styles.paymentMethod}>#2156</div>
                        </div>
                        <div className={styles.invoiceborderLine}/>
                        </div>

                       
                        <div className={styles.invoiceRowBtn}>
                          <div className={styles.paymentMethodTwo}>Transaction ID</div>
                           <button className={styles.invoiceBtn}>065468748942</button>
                        </div>
                       </div>

                       <div className={styles.bb}>
                        <button onClick={goToAwaiting}  className={styles.proceedBtn}> 
                           <div>Continue </div>
                        </button>
                        </div>
              
                     </div>
                    </div> 
                    </div>        
        </div>
     );
}
 
export default Congrats;