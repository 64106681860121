import { useNavigate } from 'react-router-dom';
import styles from '../payment/css/payment.module.scss';

const PaymentInvoice = () => {
    const navigate=useNavigate()
    const goToPayNow = () =>{
        navigate('/dashboard/get-loan/loans/pay-now')
    }

    const goToDictionary = () =>{
      navigate('/dashboard/get-loan/loans/dictionary')
    }
    return ( 
        <div className={styles.main}>
        <div className={styles.sure}>Payment Invoice</div>
        <div className={styles.parent}>
       <div className={styles.box}>
        <div>
        <div className={styles.modalTitle}>Payment Of Shortfall Invoice</div>
      
        <div className={styles.boxBorder}>
         <div className={styles.payment}>Loan details</div>

      
         <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Currency</div>
           <div className={styles.paymentMethod}>ULIT COIN</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>

         <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Repayment amount</div>
           <div className={styles.paymentMethod}>$1,376,250.00 (10,000,000.00 ULIT)</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>

         <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Current Loan Amount</div>
           <div className={styles.paymentMethod}>$1,076,250.00 (8,500,000.00 ULIT)</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>

         <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Shortfall amount To be Paid</div>
           <div className={styles.paymentMethod}>$300,000.00 (2,500,000.00 ULIT)</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>

         <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Time</div>
           <div className={styles.paymentMethod}>20:34</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>

         <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Paid Invoice confirmation for</div>
           <div className={styles.paymentMethod}>Joe Brown</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>

         <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Invoice number</div>
           <div className={styles.paymentMethod}>IN23405</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>
        </div>

        <div onClick={goToDictionary} className={styles.link}>See ULIT Dictionary</div>
        <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Wallet Address</div>
           <div className={styles.paymentMethod}>x12gggs5258bbHTgH6W</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>

         <div>
         <div className={styles.invoiceRow}>
           <div className={styles.paymentMethodTwo}>Reference Number</div>
           <div className={styles.paymentMethod}>09877123</div>
         </div>
         <div className={styles.invoiceborderLine}/>
         </div>


        <div className={styles.invoiceRowBtn}>
           <div className={styles.paymentMethodTwo}>Transaction ID</div>
            <button className={styles.invoiceBtn}>065468748942</button>
        </div>

        <div className={styles.bb}>
         <button onClick={goToPayNow} className={styles.basicBtn}>Proceed to Payment</button>
        </div>

      </div>
      </div>
      </div>
      </div>
     );
}
 
export default PaymentInvoice;