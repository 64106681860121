import QuickSellD from "../../component/quickSellD/QuickSellD";
import "./quickSellCenter.scss";
import backIcon from "../../assets/back.svg";
import { useState } from "react";
import { ButtonMain } from "../../component/StyledComponent";

const QuickSellCenter = () => {
  const [step, setStep] = useState(1);

  return (
    <div className="quickSellCenter">
      {step === 1 && (
        <div className="quickSellCenter__main">
          <div className="quickSellCenter__main__center">
            <h2>Quick Sell Centre</h2>
            <p>
              Quickly sell ULIT Cryptocurrencies on ULITCOIN now by just a click
              of a button. Enjoy the seamless process as you take control of
              your journey with Ultainfinity.{" "}
            </p>
          </div>

          <h3 className="quickSellCenter__main__subTitle">
            You’ve multiple options to sell your ULIT cryptocurrencies.
          </h3>

          <div className="quickSellCenter__main__option">
            <h4>Option 1</h4>
            <p>
              Sell in public markets such as Decentralised Exchanges [DEXs] and
              Centralised Exchanges [CEXs]
            </p>
            <p>
              Sell in Marketplace Exchanges such as Unisat.io marketplace and
              Ordinals wallet marketplace{" "}
            </p>
          </div>
          <div className="quickSellCenter__main__option active">
            <h4>Option 2</h4>
            <p>
              Sell to DASA TRADER a partner of ULITCOIN.COM. Click here to sell
              and state the quantity you want to sell and the wallet you want
              the proceeds to be credited . You’ll get your proceeds in USDT or
              USDC or LTC or DOGE
            </p>
            <p>
              Sell to ULIT OTC DESK. The minimum amount to sell using ULIT OTC
              DESK is $100,000 but typically over $1M worth of ULIT
              Cryptocurrencies.
            </p>
          </div>

          <div className="quickSellCenter__main__btn">
            <ButtonMain
              $fill
              height="56px"
              width="80%"
              radius="border-radius: 4px;"
              onClick={() => setStep(2)}
            >
              Proceed to Option 2
            </ButtonMain>
          </div>
        </div>
      )}

      {step === 2 && (
        <>
          <div className="quickSellCenter__header">
            <h2>SELL YOUR ULIT CRYPTOCURRENCIES</h2>

            <img src={backIcon} onClick={() => setStep(1)} alt="" />
          </div>
          <QuickSellD />
        </>
      )}
    </div>
  );
};

export default QuickSellCenter;
