// import { Input, Select } from "../../pages/getLoan/GetLoan";
import { Button, ButtonMain, InputPin } from "../StyledComponent";
import "./cashNowForm.scss";
import backIcon from "../../assets/back.svg";
import checkIcon from "../../assets/check.svg";
import { Input, Select } from "../quickBuy/QuickBuy";
import Modal from "react-modal";
import { useState } from "react";
import closeIcon from "../../assets/close.svg";
import SocialLinks from "../socialLinks/SocialLinks";
import { Link } from "react-router-dom";
// import OtpInputComponent from "../loanRepayment/otp";
import drip from "../../assets/drip.svg";
import copy from "../../assets/copy.svg";
import classNames from "classnames";

const CashNowForm = ({ setStep }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [level, setLevel] = useState(1);
  const [levelModal, setLevelModal] = useState(1);
  const [levelModal2, setLevelModal2] = useState(1);
  const [stepMain, setStepMain] = useState(1);
  const [otp, setOtp] = useState("");
  const handleLOanRepaymentSuccess = () => {
    // setStep(6);
    setModalIsOpen2(true);
  };
  const steps = ["1", "2", "3", "4", "5", "6"];

  return (
    <div className="cashNowForm">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel=""
      >
        <div className="getLoan__modal">
          <div
            onClick={() => setModalIsOpen(false)}
            className="getLoan__modal__close"
          >
            <img src={closeIcon} alt="" />
          </div>
          {levelModal === 1 && (
            <>
              <img src={checkIcon} alt="" />
              <h3>Verify It’s You</h3>
              <p>
                Proceed to verify that this is you. We will send you a 5 digit
                code via Messenger your choose.
              </p>
              <span style={{ textDecoration: "underline", color: "#B6CBFF" }}>
                Verify With Biometrics Instead
              </span>

              <button
                onClick={() => setLevelModal(2)}
                style={{ width: "150px" }}
              >
                Continue
              </button>
            </>
          )}
          {levelModal === 2 && (
            <div className="login__otp">
              {/* <h2 className="login__otp__title">Login to Dashboard</h2> */}
              <p className="login__otp__disc">
                Complete the Onestep verification to proceed. it is important
                for account verification
              </p>

              <div className="login__otp__choice">Use OneStep ID to login</div>
              <div className="login__otp__messanger">
                <p>Kindly select a messenger</p>
                <div className="login__otp__socialLinksCon">
                  <div onClick={() => setLevelModal(3)}>
                    <SocialLinks />
                  </div>
                  <Link className="login__otp__socialLinksCon__recovery" to="#">
                    <span>Recovery Centre</span>{" "}
                  </Link>

                  <div className="getHelp__main">
                    <p className="getHelp__disc">
                      Having trouble using OneStep Verification?{" "}
                    </p>
                    <span
                      // onClick={() => setCurrAuth("help")}
                      className="getHelp__btn"
                    >
                      Help Centre
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {levelModal === 3 && (
            <div className="login__verify">
              <h2 className="login__verify__title">OTP Verification</h2>
              <p className="login__verify__subTitle">
                Complete the Onestep verification to proceed. it is important
                for account verification
              </p>

              <p className="login__verify__disc">
                Enter the OTP verification code received in your messenger. 2
                Factor Authentications are essential identification and
                verification measures, that OneStep ID by Ultainfinity uses to
                protect you and your account 24/7.
              </p>

              <InputPin onChange={(e) => setOtp(e.target.value)} type="text" />
              <div className="login__btnCon">
                {levelModal !== 1 && (
                  <Button
                    //   disabled={btnDisable}
                    //   $disable={btnDisable}
                    onClick={() => {
                      setStepMain(2);
                      setModalIsOpen(false);
                    }}
                    width="80%"
                    $fill
                  >
                    {/* {loading ? (
                        <ReactLoading
                          color="white"
                          width={30}
                          height={30}
                          type="spin"
                        />
                      ) : (
                        "Continue"
                      )} */}
                    Continue
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen2}
        onRequestClose={() => setModalIsOpen2(false)}
        contentLabel=""
      >
        <div className="getLoan__modal">
          <div
            onClick={() => setModalIsOpen2(false)}
            className="getLoan__modal__close"
          >
            <img src={closeIcon} alt="" />
          </div>

          {levelModal2 === 1 && (
            <>
              <img src={checkIcon} alt="" />
              <h3>Sell of 1,000 USDT Successful</h3>
              <p>
                Your transaction is currently under review and checks. Once
                verified, you will receive your funds in your selected currency
                in your submitted wallet.
              </p>
              <p>
                This may take few hours and up to 3 days. Kindly wait patiently.
                Once released, you will see the released amount in your wallet.
              </p>

              <span style={{ marginTop: 30 }}>Transaction Hash:</span>
              <span className="cashNowForm__modal__hash">
                1626G44646D55S44D4
                <img src={copy} alt="" />
              </span>

              <div className="cashNowForm__modal__btns">
                <button
                  onClick={() => setStep(3)}
                  style={{
                    width: "200px",
                    background: "transparent",
                    border: "1px solid #9475D4",
                  }}
                >
                  Back
                </button>
                <button
                  onClick={() => setLevelModal2(2)}
                  style={{ width: "200px" }}
                >
                  View Details
                </button>
              </div>
            </>
          )}

          {levelModal2 === 2 && (
            <div className="cashNowForm__modal__details">
              <p>Payment Details</p>

              <div className="cashNowForm__modal__details__item">
                Transaction ID: <span>215044039</span>
              </div>
              <div className="cashNowForm__modal__details__item">
                Amount: <span>4,000 USDT</span>
              </div>
              <div className="cashNowForm__modal__details__item">
                Wallet Address: <span>TNaRAoLUyYEV2u..</span>
              </div>
              <div className="cashNowForm__modal__details__item">
                Network Fee: <span>0.00282 USDT</span>
              </div>
              <div className="cashNowForm__modal__details__item">
                Date: <span>22-11-2022</span>
              </div>
              <div className="cashNowForm__modal__details__item">
                Time: <span>10:00AM</span>
              </div>
              <div className="cashNowForm__modal__details__item">
                Status: <span>Completed</span>
              </div>
            </div>
          )}
        </div>
      </Modal>

      {stepMain === 1 && (
        <div className="purchaseToken__purchase">
          <img src={backIcon} alt="back" onClick={() => setStep(3)} />

          <div className="purchaseToken__purchase__main">
            <h3>Proceed To Input the Amount to be Cashed Out</h3>

            <Select name="Cryptocurrencies" holder="Select Payment Currency">
              {/* <option value="">Select Payment Currency</option> */}
              <option value="BTC">BTC</option>
            </Select>
            <Input
              //   value={Currency}
              //   onChange={(e) => setCurrency(e.target.value)}
              name="Address"
              holder="Enter Wallet Address"
            />
            <Select name="Network" holder="Select Network">
              {/* <option value="">Select Payment Currency</option> */}
              <option value="BRC 20">BRC 20</option>
            </Select>
            <Input
              //   value={Currency}
              //   onChange={(e) => setCurrency(e.target.value)}
              name="Amount"
              holder="Enter Amount"
              type="number"
            />

            <div className="purchaseToken__purchase__main__avail">
              MCAP Available Balance: <span> 50,000 USD</span>{" "}
            </div>

            <div className="getLoan__inputText">
              10,000 <span>BTC</span>{" "}
            </div>

            <ButtonMain
              style={{ marginTop: 50 }}
              $fill
              width="100%"
              height="56px"
              radius="4px"
              onClick={() => setModalIsOpen(true)}
            >
              Next
            </ButtonMain>
          </div>
        </div>
      )}
      {stepMain === 2 && (
        <div className="sureSystem">
          <h2 className="sureSystem__title">SURE SYSTEM</h2>
          <div className="sureSystem__modal">
            <div className="sureSystem__modal__steps">
              {steps.map((item, i) => (
                <>
                  <p
                    className={classNames("", {
                      sureSystem__modal__steps__p:
                        Number(item) === level || Number(item) < level,
                      sureSystem__modal__steps__pp:
                        level && Number(item) > level,
                    })}
                  >
                    {item}
                  </p>

                  {i < 5 && <img src={drip} width={2} height={2} alt="drip" />}
                </>
              ))}
            </div>
            {level === 1 && <LevelOne setLevel={setLevel} />}
            {level === 2 && <LevelTwo setLevel={setLevel} />}
            {level === 2.3 && <LevelThree setLevel={setLevel} />}
            {level === 3 && (
              <LevelFour otp={otp} setOtp={setOtp} setLevel={setLevel} />
            )}
            {level === 4 && <LevelFive setLevel={setLevel} />}
            {level === 5 && <LevelSix setLevel={setLevel} />}
            {level === 5.2 && <LevelSeven setLevel={setLevel} />}
            {level === 6 && (
              <LevelEight
                handleLOanRepaymentSuccess={handleLOanRepaymentSuccess}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CashNowForm;

export const LevelOne = ({ setLevel }) => (
  <div>
    <h1>Request Super Admin Authentication Code</h1>
    <p>
      Click on the Next button to send in a request to the Super Admin for an
      authentication code in order to proceed
    </p>
    <ButtonMain
      onClick={() => setLevel(2)}
      $fill
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelTwo = ({ setLevel }) => (
  <div>
    <h1>Awaiting Approval & Code Release by the Super Admin</h1>
    <p>
      Your request has been sent and is currently under review and checks. Once
      verified, your authentication code will be released.
    </p>
    <p>
      It may take up to 3 days to get your code. This is due to request checks
      and processing by the Super Admin. Kindly wait patiently.
    </p>
    <ButtonMain
      onClick={() => setLevel(2.3)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelThree = ({ setLevel }) => (
  <div>
    <h1>Request Approved! Proceed to Input Your Code</h1>
    <p>
      Your request has been has been approved and your authentication code has
      been released.
    </p>
    <p>Proceed to input the code.</p>
    <ButtonMain
      onClick={() => setLevel(3)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelFour = ({ setLevel, otp, setOtp }) => (
  <div>
    <h1>Input Your Super Admin Authentication Code</h1>
    <p>Enter the 5 digit code sent to you via Messenger in order to proceed.</p>
    <div className="sureSystem__modal__steps__form">
      <div className="sureSystem__modal__steps__form">
        <InputPin />
      </div>
    </div>

    {/* <p>Resend Request</p> */}
    <ButtonMain
      onClick={() => setLevel(4)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800, marginTop: 50 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelFive = ({ setLevel }) => (
  <div>
    <h1>Request DASA Super Admin OTP Code</h1>
    <p>
      Click on the Next button to send in a request to the DASA Super Admin for
      an OTP code in order to proceed
    </p>
    <p>
      It may take up to 3 days to get your code. This is due to request check
      and processing by the Super Admin. Kindly wait patiently.
    </p>
    <ButtonMain
      onClick={() => setLevel(5)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelSix = ({ setLevel }) => (
  <div>
    <h1>Awaiting Approval & Code Release by the Super Admin</h1>
    <p>
      Your request has been sent and is currently under review and checks. Once
      verified, your authentication code will be released.
    </p>
    <p>
      It may take up to 3 days to get your code. This is due to request checks
      and processing by the Super Admin. Kindly wait patiently.
    </p>
    <ButtonMain
      onClick={() => setLevel(5.2)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelSeven = ({ setLevel }) => (
  <div>
    <h1>Request Approved! Proceed to Input Your Code</h1>
    <p>
      Your request has been has been approved and your authentication code has
      been released.
    </p>
    <p>Proceed to input the code.</p>
    <ButtonMain
      onClick={() => setLevel(6)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);

export const LevelEight = ({ handleLOanRepaymentSuccess }) => (
  <div>
    <h1>Input Your DASA Super Admin OTP Code</h1>
    <p>Enter the 5 digit code sent to you via Messenger in order to proceed.</p>
    <div className="sureSystem__modal__steps__form">
      <InputPin />
    </div>

    <p>Resend Request</p>
    <ButtonMain
      onClick={handleLOanRepaymentSuccess}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
