import ulit from "../../assets/ulit.png";
import wallet1 from "../../assets/wallet1.png";
import stack from "../../assets/stack.svg";
import ulit1 from "../../assets/ulit1.png";
import ulit2 from "../../assets/ulit2.png";
import ReactPaginate from "react-paginate";
import "./dashboard.scss";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactLoading from "react-loading";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [loadingHis, setLoadingHis] = useState(false);
  const [wallets, setWallets] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [purchaseHis, setPurchaseHis] = useState(null);

  const user = JSON.parse(sessionStorage.getItem("userUlta") || "{}");

  // const data = {
  //   network: "BNB Smart Chain Mainnet",
  //   address: "0XC610fdksfksldfjos984hrejFa6d",
  // };

  // const tableData = [
  //   {
  //     token: "ULC",
  //     date: "2022/12/09",
  //     id: "U5356288LIT",
  //     method: "Coinbase Commerce",
  //     amount: "2512.046",
  //     status: "Completed",
  //   },
  //   {
  //     token: "ULC",
  //     date: "2022/12/09",
  //     id: "U5356288LIT",
  //     method: "Coinbase Commerce",
  //     amount: "2512.046",
  //     status: "Completed",
  //   },
  //   {
  //     token: "ULC",
  //     date: "2022/12/09",
  //     id: "U5356288LIT",
  //     method: "Coinbase Commerce",
  //     amount: "2512.046",
  //     status: "Completed",
  //   },
  //   {
  //     token: "ULC",
  //     date: "2022/12/09",
  //     id: "U5356288LIT",
  //     method: "Coinbase Commerce",
  //     amount: "2512.046",
  //     status: "Completed",
  //   },
  //   {
  //     token: "ULC",
  //     date: "2022/12/09",
  //     id: "U5356288LIT",
  //     method: "Coinbase Commerce",
  //     amount: "2512.046",
  //     status: "Completed",
  //   },
  // ];

  useEffect(() => {
    const initGetWallet = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/launchpad/app/wallets/user-wallets`,
          {
            headers: {
              Authorization: "Bearer " + user.token,
              "user-id": user?.user?.id,
            },
          }
        );
        setLoading(false);

        if (response.data?.data?.length > 0) {
          setWallets(response.data?.data);

          const latestWallet = response.data?.data?.length - 1;

          setWallet(response.data?.data?.[0]);
        }
      } catch (err) {
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        setLoading(false);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    initGetWallet();

    const initGetTransc = async () => {
      setLoadingHis(true);

      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            `/launchpad/app/dashboard/get-user-dashboard`,
          {
            headers: {
              Authorization: "Bearer " + user.token,
              "user-id": user?.user?.id,
            },
          }
        );
        setLoadingHis(false);

        setPurchaseHis(response.data.data?.Purchasehistory);
      } catch (err) {
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        setLoadingHis(false);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    initGetTransc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <h2>Dashboard</h2>

        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactLoading color="white" width={50} height={50} type="spin" />
          </div>
        ) : (
          <div className="dashboard__header__main">
            <p>
              {" "}
              <img
                style={{ width: 30, marginRight: 10 }}
                src={wallet1}
                alt=""
              />{" "}
              Metamask
            </p>
            <p>{wallet?.network}</p>
            <p>
              <img src={ulit} alt="" />{" "}
              {wallet?.wallet_address.substring(0, 10)}
              ....
            </p>
            <button>Order Now</button>
          </div>
        )}
      </div>

      <div className="dashboard__bal">
        <div className="dashboard__bal__left">
          <div className="dashboard__bal__left__header">
            <img
              src={stack}
              alt=""
              className="dashboard__bal__left__header__stack"
            />

            <div className="dashboard__bal__left__header__right">
              <span>ULC</span>
              <span>EUR</span>
              <span className="active">USD</span>
              <span>USDT</span>
              <span>USDC</span>
              <span>PYUSD</span>
              <span>BTC</span>
            </div>
          </div>

          <div className="dashboard__bal__left__bottom">
            <h4>Account Balance</h4>
            <p>
              250,000.00 <span>USD</span>
            </p>
          </div>
        </div>

        <div className="dashboard__bal__right">
          {loading ? (
            <div style={{ margin: 20 }}>
              <ReactLoading color="white" width={50} height={50} type="spin" />
            </div>
          ) : (
            <>
              <div className="dashboard__bal__right__item">
                Connected Wallet: <img src={wallet1} alt="" /> Metamask
              </div>
              <div className="dashboard__bal__right__item">
                Wallet Address: {wallet?.wallet_address.substring(0, 20)}...
              </div>
            </>
          )}

          <button>Disconnect Wallet</button>
        </div>
      </div>

      <h2 className="dashboard__title">Account Details</h2>

      <div className="dashboard__details">
        <div className="dashboard__details__main">
          <div className="dashboard__details__main__item">
            Name: <span>{user?.user?.display_name}</span>
          </div>
          <div className="dashboard__details__main__item">
            Type of ULIT Account: <span>ULIT Wealth Account</span>
          </div>
          <div className="dashboard__details__main__item">
            Username: <span>{user?.user?.username}</span>
          </div>
          <div className="dashboard__details__main__item">
            ULIT Account Opened On: <span>09-07-2023</span>
          </div>
        </div>

        <div className="dashboard__details__message">
          <h3>Other Information</h3>
          <textarea
            placeholder="message:"
            name=""
            id=""
            cols="30"
            rows="3"
          ></textarea>
        </div>
      </div>

      <h2 className="dashboard__title">Purchase History</h2>

      <div className="dashboard__table">
        {loadingHis ? (
          <div className="loadingCon">
            <ReactLoading color="white" width={50} height={50} type="spin" />
          </div>
        ) : purchaseHis?.length > 0 ? (
          <div className="dashboard__table__body">
            <div className="dashboard__table__row">
              <div
                style={{ width: "8%" }}
                className="dashboard__table__row__head"
              ></div>
              <div
                style={{ width: "12%" }}
                className="dashboard__table__row__head"
              >
                Token
              </div>
              <div
                style={{ width: "12%" }}
                className="dashboard__table__row__head"
              >
                Date
              </div>
              <div
                style={{ width: "15%" }}
                className="dashboard__table__row__head"
              >
                Transaction ID
              </div>
              <div
                style={{ width: "20%" }}
                className="dashboard__table__row__head"
              >
                Payment method
              </div>
              <div
                style={{ width: "20%" }}
                className="dashboard__table__row__head"
              >
                Amount
              </div>
              <div
                style={{ width: "13%" }}
                className="dashboard__table__row__head"
              >
                Status
              </div>
            </div>

            {purchaseHis.map((item, i) => {
              return (
                <div key={i} className="dashboard__table__row">
                  <div
                    style={{ width: "8%" }}
                    className="dashboard__table__row__data"
                  >
                    {/* {itemOffset === 0 ? i + 1 : itemOffset + i + 1} */}
                    {i + 1}
                  </div>
                  <div
                    style={{ width: "12%" }}
                    className="dashboard__table__row__data"
                  >
                    {item?.token}
                  </div>
                  <div
                    style={{ width: "12%" }}
                    className="dashboard__table__row__data"
                  >
                    {item?.date}
                    {/* {item?.date?.substring(0, 10)} */}
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="dashboard__table__row__data"
                  >
                    {item?.id}
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="dashboard__table__row__data"
                  >
                    {item?.method}
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="dashboard__table__row__data"
                  >
                    {item?.amount}
                  </div>
                  <div
                    style={{ width: "13%" }}
                    className="dashboard__table__row__data"
                  >
                    {item.status}
                  </div>
                </div>
              );
            })}

            {/* {pageCount > 1 && ( */}
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={5}
              breakLabel="..."
              //   renderOnZeroPageCount={null}
              //   onPageChange={handlePageClick}
              //   pageRangeDisplayed={itemsPerPage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            {/* )} */}
          </div>
        ) : (
          <div className="noData">No Data</div>
        )}
      </div>

      <div className="dashboard__ulit">
        <div className="dashboard__ulit__item">
          <div className="dashboard__ulit__item__main">
            <h4>Use ULIT 50+ Assets to Get A Loan</h4>
            <button>Get Started Now</button>
          </div>

          <img src={ulit1} alt="" />
        </div>
        <div className="dashboard__ulit__item">
          <div className="dashboard__ulit__item__main">
            <h4>Sell Your ULIT Crypto Assets Quickly</h4>
            <button>Sell ULIT Asset Now</button>
          </div>

          <img src={ulit2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
