import React, { useState } from "react";
import classNames from "classnames";
import "./loanRepayment.scss";
import { ButtonMain } from "../StyledComponent";
import drip from "../../assets/drip.svg";
import OtpInputComponent from "./otp";
import Modal from "react-modal";
import closeIcon from "../../assets/close.svg";

export default function LoanRepayment({ setStep }) {
  const [level, setLevel] = useState(1);
  const steps = ["1", "2", "3", "4", "5", "6"];
  const [otp, setOtp] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleLOanRepaymentSuccess = () => {
    setStep(6);
  };

  return (
    <div className="loan__repay">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel=""
      >
        <div className="getLoan__modal">
          <div
            onClick={() => setModalIsOpen(false)}
            className="getLoan__modal__close"
          >
            <img src={closeIcon} alt="" />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="87"
            height="84"
            viewBox="0 0 87 84"
            fill="none"
          >
            <path
              d="M42.0026 0.335938C19.0276 0.335938 0.335938 19.0276 0.335938 42.0026C0.335938 64.9776 19.0276 83.6693 42.0026 83.6693C64.9776 83.6693 83.6693 64.9776 83.6693 42.0026C83.6693 37.3109 82.8525 32.8118 81.415 28.5993L74.6686 35.3457C75.1061 37.4957 75.3359 39.7234 75.3359 42.0026C75.3359 60.3818 60.3818 75.3359 42.0026 75.3359C23.6234 75.3359 8.66927 60.3818 8.66927 42.0026C8.66927 23.6234 23.6234 8.66927 42.0026 8.66927C48.8068 8.66927 55.1356 10.7271 60.4189 14.2438L66.3841 8.27865C59.5174 3.30365 51.1068 0.335938 42.0026 0.335938ZM80.7233 5.72331L37.8359 48.6107L24.1152 34.89L18.2233 40.7819L37.8359 60.3945L86.6152 11.6152L80.7233 5.72331Z"
              fill="#00C076"
            />
          </svg>

          <h3>Liquidation Successful</h3>
          <p>
            Your assets have been successfully liquidated. You can reinvest or
            repay your outstanding loan.
          </p>

          <div className="cancel-flex">
            <button className="cancel">Back to Dashboard</button>
            <button onClick={() => setStep(3)}>Continue</button>
          </div>
        </div>
      </Modal>
      <h2 className="loan__repay__title">SURE SYSTEM</h2>
      <div className="loan__repay__modal">
        <div className="loan__repay__modal__steps">
          {steps.map((item, i) => (
            <>
              <p
                className={classNames("", {
                  loan__repay__modal__steps__p:
                    Number(item) === level || Number(item) < level,
                  loan__repay__modal__steps__pp: level && Number(item) > level,
                })}
                key={item}
              >
                {item}
              </p>

              {i < 5 && <img src={drip} width={2} height={2} alt="drip" />}
            </>
          ))}
        </div>
        {level === 1 && <LevelOne setLevel={setLevel} />}
        {level === 2 && <LevelTwo setLevel={setLevel} />}
        {level === 2.3 && <LevelThree setLevel={setLevel} />}
        {level === 3 && (
          <LevelFour otp={otp} setOtp={setOtp} setLevel={setLevel} />
        )}
        {level === 4 && <LevelFive setLevel={setLevel} />}
        {level === 5 && <LevelSix setLevel={setLevel} />}
        {level === 5.2 && <LevelSeven setLevel={setLevel} />}
        {level === 6 && (
          <LevelEight handleLOanRepaymentSuccess={handleLOanRepaymentSuccess} />
        )}
      </div>
    </div>
  );
}

export const LevelOne = ({ setLevel }) => (
  <div>
    <h1>Request Super Admin Authentication Code</h1>
    <p>
      Click on the Next button to send in a request to the Super Admin for an
      authentication code in order to proceed
    </p>
    <ButtonMain
      onClick={() => setLevel(2)}
      $fill
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelTwo = ({ setLevel }) => (
  <div>
    <h1>Awaiting Approval & Code Release by the Super Admin</h1>
    <p>
      Your request has been sent and is currently under review and checks. Once
      verified, your authentication code will be released.
    </p>
    <p>
      It may take up to 3 days to get your code. This is due to request checks
      and processing by the Super Admin. Kindly wait patiently.
    </p>
    <ButtonMain
      onClick={() => setLevel(2.3)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelThree = ({ setLevel }) => (
  <div>
    <h1>Request Approved! Proceed to Input Your Code</h1>
    <p>
      Your request has been has been approved and your authentication code has
      been released.
    </p>
    <p>Proceed to input the code.</p>
    <ButtonMain
      onClick={() => setLevel(3)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelFour = ({ setLevel, otp, setOtp }) => (
  <div>
    <h1>Input Your Super Admin Authentication Code</h1>
    <p>Enter the 5 digit code sent to you via Messenger in order to proceed.</p>
    <div className="loan__repay__modal__steps__form">
      <div className="loan__repay__modal__steps__form">
        <OtpInputComponent length={5} />
      </div>
    </div>

    <p>Resend Request</p>
    <ButtonMain
      onClick={() => setLevel(4)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelFive = ({ setLevel }) => (
  <div>
    <h1>Request DASA Super Admin OTP Code</h1>
    <p>
      Click on the Next button to send in a request to the DASA Super Admin for
      an OTP code in order to proceed
    </p>
    <p>
      It may take up to 3 days to get your code. This is due to request check
      and processing by the Super Admin. Kindly wait patiently.
    </p>
    <ButtonMain
      onClick={() => setLevel(5)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelSix = ({ setLevel }) => (
  <div>
    <h1>Awaiting Approval & Code Release by the Super Admin</h1>
    <p>
      Your request has been sent and is currently under review and checks. Once
      verified, your authentication code will be released.
    </p>
    <p>
      It may take up to 3 days to get your code. This is due to request checks
      and processing by the Super Admin. Kindly wait patiently.
    </p>
    <ButtonMain
      onClick={() => setLevel(5.2)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
export const LevelSeven = ({ setLevel }) => (
  <div>
    <h1>Request Approved! Proceed to Input Your Code</h1>
    <p>
      Your request has been has been approved and your authentication code has
      been released.
    </p>
    <p>Proceed to input the code.</p>
    <ButtonMain
      onClick={() => setLevel(6)}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);

export const LevelEight = ({ handleLOanRepaymentSuccess }) => (
  <div>
    <h1>Input Your DASA Super Admin OTP Code</h1>
    <p>Enter the 5 digit code sent to you via Messenger in order to proceed.</p>
    <div className="loan__repay__modal__steps__form">
      <OtpInputComponent length={5} />
    </div>

    <p>Resend Request</p>
    <ButtonMain
      onClick={handleLOanRepaymentSuccess}
      $fill
      // disabled
      radius="100px"
      width="100%"
      height="60px"
      style={{ maxWidth: 800 }}
    >
      Next
    </ButtonMain>
  </div>
);
