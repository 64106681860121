export const ExchangeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
  >
    <path
      d="M26.4974 18.7682V32.0182M26.4974 48.5807C38.694 48.5807 48.5807 38.694 48.5807 26.4974C48.5807 14.3008 38.694 4.41406 26.4974 4.41406C14.3008 4.41406 4.41406 14.3008 4.41406 26.4974C4.41406 38.694 14.3008 48.5807 26.4974 48.5807Z"
      stroke="url(#paint0_linear_3187_57937)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.875 27.6016L26.5 34.2266L33.125 27.6016"
      stroke="url(#paint1_linear_3187_57937)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3187_57937"
        x1="14.9947"
        y1="26.4394"
        x2="50.3787"
        y2="26.4394"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C9B2F9" />
        <stop offset="1" stop-color="#3300FF" stop-opacity="0.99" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3187_57937"
        x1="23.0492"
        y1="30.9054"
        x2="33.6644"
        y2="30.9054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C9B2F9" />
        <stop offset="1" stop-color="#3300FF" stop-opacity="0.99" />
      </linearGradient>
    </defs>
  </svg>
);

export const BackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
  >
    <path
      d="M19.5 3.25C10.5462 3.25 3.25 10.5462 3.25 19.5C3.25 28.4538 10.5462 35.75 19.5 35.75C28.4538 35.75 35.75 28.4538 35.75 19.5C35.75 10.5462 28.4538 3.25 19.5 3.25ZM22.4088 24.375C22.88 24.8463 22.88 25.6262 22.4088 26.0975C22.165 26.3412 21.8563 26.455 21.5475 26.455C21.2388 26.455 20.93 26.3412 20.6862 26.0975L14.95 20.3612C14.7233 20.1319 14.5962 19.8224 14.5962 19.5C14.5962 19.1776 14.7233 18.8681 14.95 18.6388L20.6862 12.9025C21.1575 12.4313 21.9375 12.4313 22.4088 12.9025C22.88 13.3737 22.88 14.1538 22.4088 14.625L17.5337 19.5L22.4088 24.375Z"
      fill="url(#paint0_linear_3187_57908)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3187_57908"
        x1="3.25"
        y1="6.5"
        x2="38.0269"
        y2="9.51871"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5158F6" />
        <stop offset="0.192708" stop-color="#822DF5" />
        <stop offset="1" stop-color="#F3A761" />
      </linearGradient>
    </defs>
  </svg>
);

