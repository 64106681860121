import { useNavigate } from 'react-router-dom';
import styles from './css/dictionary.module.scss';
import  BackIcon from "../../../assets/arrowcircleleft.svg";
const Dictionary = () => {
  const navigate=useNavigate()

  const goBack = () =>{
    navigate('/dashboard/get-loan/loans/payment')
  }
    return ( 
        <div className={styles.parent}>
            <div className={styles.desc}><div onClick={goBack} className={styles.sure}><img src={BackIcon} className={styles.stepIcon} alt="horse" /></div>ULIT Dictionary</div>     
                <div className={styles.main}>
                  <div className={styles.alphabet}>A</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Accumulation</div>
                    <div className={styles.nameBox}>Airdrop</div>
                    <div className={styles.nameBox}>All Time Hight(ATH)</div>
                    <div className={styles.nameBox}>Accumulation</div>
                  </div>
                </div>


                <div className={styles.main}>
                  <div className={styles.alphabet}>B</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Bear Market</div>
                    <div className={styles.nameBox}>Benchmark</div>
                    <div className={styles.nameBox}>Blockchain</div>
                    <div className={styles.nameBox}>Bull Markrt</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>C</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Candlestick</div>
                    <div className={styles.nameBox}>Centralized</div>
                    <div className={styles.nameBox}>Cold wallet</div>
                    <div className={styles.nameBox}>Custody</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>D</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>DCA(Dollar-Cost-Averaging)</div>
                    <div className={styles.nameBox}>Decentralized</div>
                    <div className={styles.nameBox}>Decentralized Finance(DeFi)</div>
                    <div className={styles.nameBox}>Decentralized Applications (Dapps)</div>
                    <div className={styles.nameBox}>Divit Token</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>E</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>ERC - 20</div>
                    <div className={styles.nameBox}>EFT (Exchange traded fund)</div>
                    <div className={styles.nameBox}>Ether</div>
                    <div className={styles.nameBox}>Exchange</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>F</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Fomo (fear of missing out)</div>
                    <div className={styles.nameBox}>Farming</div>
                    <div className={styles.nameBox}>Fiat</div>
                    <div className={styles.nameBox}>Fork</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>G</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Gas</div>
                    <div className={styles.nameBox}>Gas Limit</div>
                    <div className={styles.nameBox}>Gas Price</div>
                    <div className={styles.nameBox}>Governor Token</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>H</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Having</div>
                    <div className={styles.nameBox}>Hard Fork</div>
                    <div className={styles.nameBox}>Harsh</div>
                    <div className={styles.nameBox}>Harsh Fund</div>
                    <div className={styles.nameBox}>Hot Wallet</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>I</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Initial Coin Offerings</div>
                    <div className={styles.nameBox}>Initial Exchange Offering</div>
                    <div className={styles.nameBox}>Initial Public Offering</div>
                    <div className={styles.nameBox}>Initial Game Offering</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>L</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Ledger</div>
                    <div className={styles.nameBox}>Leverage</div>
                    <div className={styles.nameBox}>Limit Order</div>
                    <div className={styles.nameBox}>Line Chart</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>M</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Market Capitalization</div>
                    <div className={styles.nameBox}>Market Order</div>
                    <div className={styles.nameBox}>Mining</div>
                    <div className={styles.nameBox}>Mining Reward</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>N</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Network</div>
                    <div className={styles.nameBox}>Node</div>
                    <div className={styles.nameBox}>Non-Fungible Token (NFT)</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>O</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Open Source</div>
                    <div className={styles.nameBox}>Over The Counter (OTC)</div>
                    <div className={styles.nameBox}>Overbought</div>
                    <div className={styles.nameBox}>Oversold</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>P</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Private Key</div>
                    <div className={styles.nameBox}>Proof-Of-State</div>
                    <div className={styles.nameBox}>Proof-Of-Work</div>
                    <div className={styles.nameBox}>Public key</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>Q</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>QR Code</div>
                    <div className={styles.nameBox}>Quantitative Trading</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>R</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Relative Strength Index</div>
                    <div className={styles.nameBox}>Rolar Token</div>
                    <div className={styles.nameBox}>Referral Code</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>S</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Security Token Offering (STO)</div>
                    <div className={styles.nameBox}>Smart Contract</div>
                    <div className={styles.nameBox}>Stable Coin</div>
                    <div className={styles.nameBox}>Staking</div>
                    <div className={styles.nameBox}>SLAPA token</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>T</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Technical Analysis</div>
                    <div className={styles.nameBox}>Token</div>
                    <div className={styles.nameBox}>Total Supply</div>
                    <div className={styles.nameBox}>Transaction Block</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>U</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Utility Token</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>V</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Volatility</div>
                    <div className={styles.nameBox}>Volume</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>W</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Wallet</div>
                    <div className={styles.nameBox}>Whale</div>
                    <div className={styles.nameBox}>White Paper</div>
                  </div>
                
                </div>

                <div className={styles.main}>
                  <div className={styles.alphabet}>Y</div>
                  <div className={styles.row}>
                    <div className={styles.nameBox}>Year To Date</div>
                  </div>
                
                </div>
            
        </div>
     );
}
  
export default Dictionary;